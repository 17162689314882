import { DownOutlined } from "@ant-design/icons";
import { Button, Checkbox, Modal } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { $t } from "~/i18n.js";
import { api } from "~/lib/api.js";
import { CheckIfGroupIsBase } from "../../groups/components/baseGroups.js";
import { Tag } from "antd";

export const GroupCoreTag = () => <Tag color="green">{$t("Kern")}</Tag>

export const GroupDropdown = ({
  userIDs = [],
  onSave = (d, n) => {},
  addOrDelete = "add",
  SelectedGroupKeys = [],
}) => {
  const { group_name_lookup } = useSelector((state) => state.flights);

  const title =
    addOrDelete == "add" ? $t("Voeg groep toe") : $t("Verwijder van groep");

  const [selectedGroups, setSelectGroups] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => {
    setSelectGroups([]);
    setShowModal(false);
  };

  const [loading, setLoading] = useState(false);
  const updateGroup = async (group_keys) => {
    setLoading(true);
    let newUsers = null;
    for (const index in userIDs) {
      let res = null;
      for (const idx in group_keys) {
        res = await api._call_method(
          addOrDelete == "add" ? "POST" : "DELETE",
          "admin/user_groups.json",
          { user_id: userIDs[index], group_key: group_keys[idx] }
        );
      }
      if (res) newUsers = onSave(res["data"], newUsers);
    }
    setLoading(false);
    closeModal();
  };

  // show core groups on top
  const groupDropdownList = !group_name_lookup
    ? []
    : Object.keys(group_name_lookup)
        .map((group_key) => {
          return {
            value: group_key,
            label: <p>{group_name_lookup[group_key]} {
              CheckIfGroupIsBase(group_key) && <GroupCoreTag/>
            }</p>,
            disabled:
              SelectedGroupKeys.length > 0 &&
              SelectedGroupKeys.indexOf(group_key) > -1,
            is_base: CheckIfGroupIsBase(group_key),
            sorting_label: `${CheckIfGroupIsBase(group_key) ? 0 : 1} ${group_name_lookup[group_key]}`
          };
        })
        .sort((a, b) => a["sorting_label"].localeCompare(b["sorting_label"]));

  return (
    <div>
      <Button
        type="text"
        size="small"
        disabled={userIDs.length === 0}
        onClick={() => setShowModal(true)}
      >
        {title}
        <DownOutlined />
      </Button>

      <Modal
        title={title}
        open={showModal}
        onCancel={!loading ? closeModal : () => {}}
        confirmLoading={loading}
        onOk={() => updateGroup(selectedGroups)}
        okButtonProps={{ disabled: !selectedGroups }}
        okText={$t("Voeg toe")}
        cancelText={$t("Annuleren")}
        width={350}
      >
        <Checkbox.Group
          style={{ display: "flex", flexDirection: "column", gap: 6 }}
          onChange={setSelectGroups}
          value={selectedGroups}
          options={groupDropdownList}
        />
      </Modal>
    </div>
  );
};
