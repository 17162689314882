import { Card, Switch } from "antd";
import React, { useState } from "react";
import { $t } from "~/i18n.js";
import { AanmeldList } from "./AanmeldList.jsx";
import { CardKisten } from "./cardKisten.jsx";
import { CardRollen } from "./cardRollen.jsx";
import { CardRooster } from "./cardRooster.jsx";
import { MessageCard } from "./messageCard.jsx";
import { RecencyWarnings } from "./RecencyWarnings.jsx";
import { SwitchYesNo } from "~/components/form/SwitchYesNo.jsx";

export const AanmeldLijstContent = ({
  days,
  aanmeldingen,
  club,
  flights,
  profile,
  currentAanmelding,
  setOpenPilot,
  editAanmelding,
  afmelden,
}) => {
  const [viewAsInstructor, setViewAsInstructor] = useState(
    profile.is_instructeur
  );
  const isVliegend = days.activeDay.is_vliegend

  return (
    <>
      <div className="dagVerslag">
        {isVliegend && (
          <Card size="small" className="topCard" title={$t("Rollen")}>
            <CardRollen
              aanmeldingen={aanmeldingen}
              groups_aanmeld={club.groups_aanmeld}
              group_name_lookup={flights.group_name_lookup}
            />
          </Card>
        )}

        {isVliegend && (
          <Card size="small" className="topCard" title={$t("Kisten")}>
            <CardKisten aanmeldingen={aanmeldingen} day={days.activeDay} />
          </Card>
        )}

        {aanmeldingen.diensten && aanmeldingen.diensten.length > 0 && (
          <Card size="small" className="topCard" title={$t("Diensten")}>
            <CardRooster
              aanmeldingen={aanmeldingen.aanmeldingen}
              group_name_lookup={flights.group_name_lookup}
              diensten={aanmeldingen.diensten}
              user={profile}
              openAanmelding={() => !currentAanmelding && editAanmelding({})}
            />
          </Card>
        )}

        <MessageCard
          messages={aanmeldingen.messages}
          canEditMessage
          currentUserID={profile.id}
        />
      </div>

      <div style={{ marginTop: 12 }}>
        {isVliegend && profile.is_instructeur && (
          <div className="row" style={{ marginBottom: 16, gap: 12 }}>
            <p>{$t("Bekijk instructie informatie:")}</p>
            <SwitchYesNo
              value={viewAsInstructor}
              onChange={setViewAsInstructor}
            />
          </div>
        )}

        {isVliegend && profile.is_instructeur && (
          <RecencyWarnings setOpenPilot={setOpenPilot} aanmeldingen={aanmeldingen.aanmeldingen} />
        )}

        <AanmeldList
          aanmeldingen={aanmeldingen.aanmeldingen}
          profile={profile}
          afmelden={afmelden}
          notities={aanmeldingen.notities}
          bevoegdheden={aanmeldingen.bevoegdheden}
          viewInstructorInfo={viewAsInstructor}
          setOpenPilot={setOpenPilot}
          isVliegend={isVliegend}
        />
      </div>
    </>
  );
};
