import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { $t } from "~/i18n.js";

import { StopOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, Modal, Popconfirm } from "antd";

import { AnnuleerButton } from "~/components/buttons/AnnuleerButton.jsx";
import { SaveButton } from "~/components/buttons/SaveButton.jsx";
import { aanmeldingenActions } from "~/redux/actions.js";

export const MessageModal = ({ visible, message, onClose }) => {
  if (!message) return null;
  const [form] = Form.useForm();
  const [pendingSave, setPendingSave] = useState("");

  const dispatch = useDispatch();
  const { days } = useSelector((state) => state);

  useEffect(() => {
    if (message) form.resetFields();
  }, [message]);

  const saveForm = async () => {
    try {
      const values = await form.validateFields();
      setPendingSave("save");
      const newData = {
        ...values,
        id: message ? message.id : null,
        dag_id: days?.activeDay?.dag_id,
        datum: days?.activeDay?.datum,
      };
      const res = await dispatch(aanmeldingenActions.saveMessage(newData));
      res && onClose(res);
      setPendingSave("");
    } catch (info) {
      console.log("error saving message: ", info);
      setPendingSave("");
    }
  };

  const triggerDelete = async () => {
    setPendingSave("delete");
    const res = await dispatch(
      aanmeldingenActions.saveMessage({ id: message.id, action: "delete" })
    );
    res && onClose(res);
    setPendingSave("");
  };

  // bug occurs when activeDay is not yet selected, don't show modal
  if (!days?.activeDay?.dag_id) return null;

  return (
    <Modal
      title={message?.id ? $t("Wijzig bericht") : $t("Voeg bericht toe")}
      open={visible}
      confirmLoading={pendingSave}
      okText={$t("Opslaan")}
      cancelText={$t("Annuleren")}
      onCancel={onClose}
      footer={[
        <Popconfirm
          key="remove"
          placement="bottom"
          title={$t("Zeker weten dat je dit bericht wilt verwijderen")}
          onConfirm={() => message && triggerDelete()}
          okText={$t("Ja")}
          cancelText={$t("Nee")}
        >
          <Button
            disabled={!message?.id}
            danger
            icon={<StopOutlined />}
            style={{ marginRight: 24 }}
            loading={pendingSave === "delete"}
          >
            {$t("verwijder")}
          </Button>
        </Popconfirm>,

        <AnnuleerButton key="annuleren" onClick={onClose} />,
        <SaveButton
          key="opslaan"
          onClick={saveForm}
          loading={pendingSave === "save"}
        />,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        name="bericht"
        preserve={false}
        requiredMark={false}
        initialValues={{
          message: message?.message || "",
          as_email: false,
        }}
      >
        <Form.Item
          label={$t("Bericht")}
          name="message"
          rules={[
            {
              required: false,
              max: 900,
              message: $t("Maximaal ${c} characters", { c: 900 }),
            },
          ]}
        >
          <Input.TextArea rows={4} autoFocus />
        </Form.Item>

        <p className="small" style={{ marginBottom: 12 }}>
          {$t(
            "Let op: berichten zijn voor iedereen zichtbaar, laat geen persoonlijke informatie achter."
          )}
        </p>

        {!message?.id && (
          <Form.Item name="as_email" valuePropName="checked">
            <Checkbox className="small">
              {$t("Verstuur bericht als email naar alle aangemelde leden")}
            </Checkbox>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};
