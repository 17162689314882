import {
  EditFilled,
  LockFilled,
  QuestionCircleOutlined,
  StopOutlined,
  SwapOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import {
  Button,
  Descriptions,
  Divider,
  Flex,
  Input,
  Modal,
  Popconfirm,
  Select,
  Switch,
  Tag,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { SettingCard } from "~/components/dataCards/SettingCard.jsx";
import { $t } from "~/i18n.js";
import { selectElementByID } from "../../lib/helpers.js";
import { IGCButton } from "../IGC/IGC.button.jsx";
import { AnnuleerButton } from "../buttons/AnnuleerButton.jsx";
import { SaveButton } from "../buttons/SaveButton.jsx";
import { SelectPilot } from "../profile/selectPilot.jsx";
import { HeightValue } from "./sections/HeighValue.jsx";
import { FlightDetails } from "./sections/flightDetails.jsx";
import { FlightOpenIcon } from "./sections/flightOpenIcon.jsx";
import { SectionChecks } from "./sections/sectionChecks.jsx";
import { SectionKist } from "./sections/sectionKist.jsx";
import { SectionStartmethode } from "./sections/sectionStartmethode.jsx";
import { SectionTijd } from "./sections/sectionTijd.jsx";
import { SignFlightCollapse } from "./sections/signFlight.jsx";

export const FlightEditModal = ({
  visible,
  onClose,
  readOnly,
  currentUser,
  flight,
  sleepFlight,
  updateFlight,
  connectOrCreateSleepFlight,
  kisten,
  sleep_kisten,
  vliegers,
  aanmeldingen,
  setSelectedPilot,
  openConnectedFlight = null,
}) => {
  const [visibleFlight, setVisibleFlight] = useState({});
  const [visibleSleepFlight, setVisibleSleepFlight] = useState({});

  const editInstructorDetails = currentUser.is_instructeur;

  const [changes, setChanges] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (flight) {
      flight && setVisibleFlight(flight);
      sleepFlight && setVisibleSleepFlight(sleepFlight);
      setChanges([]);
      setLoading(false);
    }
  }, [flight, sleepFlight]);

  // check if selectedKist already exists, required for kist and tweede_inzittende registration
  const selectedKist = selectElementByID(
    kisten,
    "registratie",
    visibleFlight && visibleFlight.registratie
  );
  const selectedSleepKist = selectElementByID(
    sleep_kisten,
    "registratie",
    visibleSleepFlight && visibleSleepFlight.registratie
  );

  const gezagVoerder = selectElementByID(
    vliegers,
    "id",
    visibleFlight && visibleFlight.gezagvoerder_id
  );
  const tweedeInzittende = selectElementByID(
    vliegers,
    "id",
    visibleFlight && visibleFlight.tweede_inzittende_id
  );
  const sleepVlieger = selectElementByID(
    vliegers,
    "id",
    visibleSleepFlight && visibleSleepFlight.gezagvoerder_id
  );

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [errors, setErrors] = useState([]);
  const validateFlight = () => {
    const errors = [];
    if (!visibleFlight.gezagvoerder_naam) {
      errors.push($t("Voeg een gezagvoerder toe."));
    }
    if (!visibleFlight.registratie) errors.push($t("Voeg een kist."));
    if (!visibleFlight.start_tijd) errors.push($t("Voeg start tijd toe."));
    if (!visibleFlight.landings_tijd) errors.push($t("Voeg landingstijd toe."));
    if (visibleFlight.vluchtduur < 0) {
      errors.push($t("Je kan geen negatieve vluchtduur hebben."));
    }
    setErrors(errors);
    setShowConfirmModal(true);
  };

  const closeConfirmModal = () => setShowConfirmModal(false);
  const saveFlight = (newData, change) => {
    setVisibleFlight({ ...visibleFlight, ...newData });
    if (change && changes.indexOf(change) === -1) {
      setChanges([change].concat(changes));
    }
  };
  const saveSleepFlight = (newData, change) => {
    // note for the modal, we always save the complete flight element
    if (sleepFlight) {
      setVisibleSleepFlight({ ...visibleSleepFlight, ...newData });
      if (change && changes.indexOf(change) === -1) {
        setChanges([change].concat(changes));
      }
    }
  };

  const saveAndClose = async () => {
    setLoading(true);
    if (sleepFlight) {
      await updateFlight(visibleSleepFlight);
    }
    const data = await updateFlight(visibleFlight);
    setLoading(false);
    if (data) {
      setShowConfirmModal(false);
      onClose();
    }
  };

  const showDeleteModal = () => {
    Modal.confirm({
      title: $t("Weet je zeker dat je de vlucht wilt verwijderen?"),
      icon: <QuestionCircleOutlined />,
      okText: $t("Ja"),
      okType: "danger",
      cancelText: $t("Nee"),
      onOk() {
        return triggerDelete();
      },
    });
  };

  const triggerDelete = async () => {
    setLoading(true);
    const data = await updateFlight({
      uuid: visibleFlight.uuid,
      action: "delete",
    });
    sleepFlight &&
      (await updateFlight({ uuid: sleepFlight.uuid, action: "delete" }));
    setLoading(false);
    if (data) onClose();
  };

  const [saverRecover, setSaveRecover] = useState(false);
  const recoverFlight = async () => {
    setSaveRecover(true);
    const data = await updateFlight({
      uuid: visibleFlight.uuid,
      action: "recover",
    });
    sleepFlight &&
      (await updateFlight({ uuid: sleepFlight.uuid, action: "recover" }));
    setSaveRecover(false);
    if (data) onClose();
  };

  const [forceEdit, setForceEdit] = useState(false);
  readOnly =
    !forceEdit &&
    (readOnly ||
      (visibleFlight && (visibleFlight.is_deleted || visibleFlight.is_locked)));

  return (
    <Modal
      width={600}
      title={
        visibleFlight.volg_nummer ? (
          <span>
            {$t("Vlucht")} #{visibleFlight.volg_nummer}
          </span>
        ) : (
          $t("Voeg vlucht toe")
        )
      }
      open={visible}
      onCancel={onClose}
      footer={[
        flight.is_deleted && (
          <Button
            loading={saverRecover}
            disabled={readOnly}
            onClick={recoverFlight}
          >
            {$t("Herstel")}
          </Button>
        ),
        !flight.is_deleted && (
          <Popconfirm
            placement="bottom"
            title={$t("Zeker weten dat je de vlucht wilt verwijderen")}
            onConfirm={showDeleteModal}
            key="delete"
            disabled={readOnly}
            okText={$t("Ja")}
            cancelText={$t("Nee")}
          >
            <Button
              icon={<StopOutlined />}
              danger
              loading={loading}
              disabled={readOnly}
            >
              {$t("Verwijder")}
            </Button>
          </Popconfirm>
        ),

        <AnnuleerButton
          key="annuleren"
          style={{ marginLeft: 8 }}
          onClick={onClose}
        />,

        <SaveButton
          style={{ marginLeft: 8 }}
          key="opslaan"
          onClick={() => validateFlight()}
          disabled={readOnly && changes.length === 0}
        />,
      ]}
    >
      <Modal
        title={$t("Bevestig wijzigingen")}
        open={showConfirmModal}
        onCancel={closeConfirmModal}
        footer={
          errors.length > 0
            ? [
                <Button key="terug" onClick={closeConfirmModal}>
                  {$t("Terug")}
                </Button>,
              ]
            : [
                <AnnuleerButton key="annuleren" onClick={closeConfirmModal} />,
                <Button
                  key="opslaan"
                  onClick={saveAndClose}
                  type="primary"
                  loading={loading}
                >
                  {$t("Bevestig")}
                </Button>,
              ]
        }
      >
        {errors.length > 0 ? (
          <div style={{ marginLeft: 12 }}>
            <p>{$t("Corrigeer de volgende fouten")}:</p>
            <ul>
              {errors.map((value, key) => (
                <li key={key}>{value}</li>
              ))}
            </ul>
          </div>
        ) : (
          <div style={{ marginLeft: 12 }}>
            {visibleFlight.id ? (
              <>
                <p className="spacer">
                  {$t("Klopt het dat je het volgende wilt wijzigen")}?
                </p>
                <ul>
                  {changes.map((change, key) => (
                    <li key={key}>{change}</li>
                  ))}
                </ul>
                <p className="small" style={{ margin: "12px 0" }}>
                  {$t(
                    "Alle correcties zullen worden bijgehouden en doorgestuurd naar de admin, DDI en piloot in kwestie ter controle"
                  )}
                  .
                </p>
              </>
            ) : (
              <p>{$t("Klopt het dat je een vlucht wilt toevoegen")}?</p>
            )}
          </div>
        )}
      </Modal>

      <Flex gap={12} vertical>
        <SectionStartmethode
          selectedKist={selectedKist}
          connectOrCreateSleepFlight={connectOrCreateSleepFlight}
          flight={flight}
          // because the start method updates the selected flight, we always save the flight directly
          saveFlight={async (data) =>
            await updateFlight({ uuid: flight.uuid, ...visibleFlight, ...data })
          }
          readOnly={readOnly}
          confirmSelection
        />
        <SettingCard>
          <b>{$t("Kist")}</b>

          <SectionKist
            selectedKist={selectedKist}
            kisten={kisten}
            flight={visibleFlight}
            saveFlight={async (d) => await saveFlight(d, "Kist")}
            readOnly={readOnly}
          />

          {openConnectedFlight && flight.sleep_uuid && (
            <div style={{ marginTop: 12 }}>
              <p>
                {$t("Bekijk verbonden vlucht")}:{" "}
                <FlightOpenIcon flight_uuid={flight.sleep_uuid} />
              </p>
            </div>
          )}

          {sleepFlight && (
            <>
              <Divider orientation="left" plain>
                {$t("Sleepkist")}:
              </Divider>

              <SectionKist
                selectedKist={selectedSleepKist}
                kisten={sleep_kisten}
                flight={visibleSleepFlight}
                saveFlight={saveSleepFlight}
                readOnly={readOnly}
              />
            </>
          )}
        </SettingCard>
        <SettingCard>
          <b>{$t("Vliegers")}</b>

          <SelectPilot
            readOnly={readOnly || !visibleFlight.registratie}
            field_id="gezagvoerder_id"
            field_name="gezagvoerder_naam"
            title={
              selectedKist && selectedKist.pilots === 2
                ? $t("PIC/instructeur")
                : $t("PIC")
            }
            pilot={gezagVoerder}
            otherPilot={tweedeInzittende}
            setBetalendLid={!visibleFlight?.start_methode?.endsWith("-a")}
            vliegers={aanmeldingen}
            alle_vliegers={vliegers}
            flight={visibleFlight}
            saveFlight={(d) => saveFlight(d, $t("Gezagvoerder"))}
            openPilot={
              setSelectedPilot ? () => setSelectedPilot(gezagVoerder) : null
            }
          />

          {(!selectedKist ||
            selectedKist.pilots === 2 ||
            visibleFlight?.tweede_inzittende_naam) && (
            <Button
              disabled={
                readOnly ||
                !visibleFlight?.tweede_inzittende_id ||
                !visibleFlight?.gezagvoerder_id
              }
              shape="circle"
              icon={<SwapOutlined fill="red" />}
              size="small"
              type="text"
              style={{ marginLeft: 25 }}
              onClick={() =>
                saveFlight(
                  {
                    betalend_lid_id: flight.gezagvoerder_id,
                    gezagvoerder_id: visibleFlight.tweede_inzittende_id,
                    gezagvoerder_naam: visibleFlight.tweede_inzittende_naam,
                    tweede_inzittende_id: visibleFlight.gezagvoerder_id,
                    tweede_inzittende_naam: visibleFlight.gezagvoerder_naam,
                    is_fis: tweedeInzittende && tweedeInzittende.is_instructeur,
                    is_training: false,
                    is_examen: false,
                    is_profcheck: false,
                  },
                  $t("Vliegers gewisseld")
                )
              }
            />
          )}

          {(!selectedKist ||
            selectedKist.pilots === 2 ||
            visibleFlight.tweede_inzittende_naam) && (
            <SelectPilot
              readOnly={readOnly || !visibleFlight.registratie}
              field_id="tweede_inzittende_id"
              field_name="tweede_inzittende_naam"
              title={$t("Pax/DBO")}
              pilot={tweedeInzittende}
              otherPilot={gezagVoerder}
              gezagVoerder={gezagVoerder}
              setBetalendLid={!visibleFlight?.start_methode?.endsWith("-a")}
              vliegers={aanmeldingen}
              alle_vliegers={vliegers}
              flight={visibleFlight}
              saveFlight={(d) => saveFlight(d, $t("Tweede inzittende"))}
              openPilot={
                setSelectedPilot
                  ? () => setSelectedPilot(tweedeInzittende)
                  : null
              }
            />
          )}

          {sleepFlight && (
            <div className="row" style={{ marginTop: 10 }}>
              <p style={{ marginRight: 6 }} className="capitalize">
                {$t("sleepvlieger")}:
              </p>
              <SelectPilot
                hideAvatar
                readOnly={readOnly}
                field_id="gezagvoerder_id"
                field_name="gezagvoerder_naam"
                title={$t("sleepvlieger")}
                pilot={sleepVlieger}
                otherPilot={null}
                // vliegers gezagvoerder only non-pax
                vliegers={aanmeldingen.filter(
                  (aanmelding) =>
                    aanmelding.id > 0 && aanmelding.as_sleepvlieger
                )}
                alle_vliegers={vliegers}
                flight={visibleSleepFlight}
                saveFlight={(d) => saveSleepFlight(d, $t("sleepvlieger"))}
                openPilot={
                  setSelectedPilot ? () => setSelectedPilot(sleepVlieger) : null
                }
              />
            </div>
          )}
          <div className="row" style={{ marginTop: 10 }}>
            <p style={{ marginRight: 6 }}>{$t("Betalend lid")}:</p>
            {visibleFlight.betalend_lid_id === -99 ? (
              <p style={{ marginLeft: 12 }}>{$t("Afgeschermd")}</p>
            ) : (
              <Select
                showSearch
                disabled={readOnly}
                value={visibleFlight.betalend_lid_id}
                onChange={(v) => {
                  saveFlight({ betalend_lid_id: v });
                  saveSleepFlight({ betalend_lid_id: v });
                }}
                style={{ width: 173, marginLeft: 10 }}
                filterOption={(input, option) =>
                  option &&
                  option.children &&
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                }
              >
                {vliegers &&
                  vliegers.map((user, key) => (
                    <Select.Option key={key} value={user.id}>
                      {user.name}
                    </Select.Option>
                  ))}
              </Select>
            )}
          </div>
        </SettingCard>
        <SettingCard>
          <b>{$t("Tijden")}</b>

          <SectionTijd
            disableNumberOfStarts
            readOnly={readOnly || !visibleFlight.registratie}
            flight={visibleFlight}
            saveFlight={(d) => {
              // To connect with sleep, save start tijd
              if ("start_tijd" in d) {
                const sleepVluchtduur = parseInt(
                  (dayjs(visibleSleepFlight.landings_tijd, "HH:mm") -
                    dayjs(d.start_tijd, "HH:mm")) /
                    60000
                );
                saveSleepFlight({
                  start_tijd: d.start_tijd,
                  landings_tijd: visibleSleepFlight.landings_tijd,
                  vluchtduur: sleepVluchtduur,
                });
              }

              saveFlight(d, $t("Vlucht tijden"));
            }}
          />

          {sleepFlight && (
            <>
              <Divider orientation="left" plain>
                {$t("Sleep tijden")}:
              </Divider>

              <SectionTijd
                disableNumberOfStarts
                readOnly={readOnly || !sleepFlight.registratie}
                flight={visibleSleepFlight}
                saveFlight={(d) => {
                  // update possible flightDuur, don't connect to sleep
                  saveSleepFlight(d, $t("Vlucht tijden"));
                }}
              />
            </>
          )}
        </SettingCard>
        <SettingCard>
          <b>{$t("Details")}</b>

          <div style={{ width: 200 }}>
            <SectionChecks
              readOnly={readOnly}
              editInstructorDetails={editInstructorDetails}
              flight={visibleFlight}
              saveFlight={(d) => saveFlight(d, $t("Details"))}
              sleepFlight={visibleSleepFlight}
              saveSleepFlight={(d) => saveSleepFlight(d, $t("Details"))}
              gezagvoerder={gezagVoerder}
              tweedeInzittende={tweedeInzittende}
            />
          </div>

          {visibleFlight.is_overland && (
            <div style={{ width: 200, marginBottom: 6 }}>
              <p>{$t("Afstand")} (km):</p>
              <Input
                disabled={readOnly}
                type="number"
                value={visibleFlight.afstand}
                onChange={(elm) =>
                  saveFlight({ afstand: elm.target.value }, $t("Afstand"))
                }
              />
            </div>
          )}

          <div style={{ width: 200, marginBottom: 6 }}>
            <p>{$t("Vertrek")}:</p>
            <Input
              value={visibleFlight.vertrek_vliegveld}
              onChange={(elm) => {
                saveFlight(
                  { vertrek_vliegveld: elm.target.value },
                  $t("Vertrek")
                );
                // For sleep: if vertrek changed, also update aankomst, sleep always lands on same spot
                sleepFlight &&
                  saveSleepFlight({
                    vertrek_vliegveld: elm.target.value,
                    aankomst_vliegveld: elm.target.value,
                  });
              }}
              prefix={$t("van")}
              disabled={readOnly}
            />
          </div>

          <div style={{ width: 200, marginBottom: 6 }}>
            <p>{$t("Aankomst")}:</p>
            <Input
              value={visibleFlight.aankomst_vliegveld}
              onChange={(elm) =>
                saveFlight(
                  { aankomst_vliegveld: elm.target.value },
                  $t("Aankomst")
                )
              }
              prefix={$t("naar")}
              disabled={readOnly}
            />
          </div>

          {sleepFlight && (
            <div style={{ width: 200, marginBottom: 6 }}>
              <p>{$t("Aankomst sleepkist")}:</p>
              <Input
                value={visibleSleepFlight.aankomst_vliegveld}
                onChange={(elm) =>
                  saveSleepFlight(
                    { aankomst_vliegveld: elm.target.value },
                    $t("Aankomst")
                  )
                }
                prefix={$t("naar")}
                disabled={readOnly}
              />
            </div>
          )}

          <Descriptions size="small" column={1} layout="horizontal">
            <Descriptions.Item label={$t("Hoogte")}>
              {flight.height ? <HeightValue value={flight.height} /> : "-"}
            </Descriptions.Item>
            <Descriptions.Item label="IGC">
              <IGCButton flight={flight} title={$t("Open kaart")} />
            </Descriptions.Item>

            <Descriptions.Item>
              {!flight.is_prive && flight.is_locked && (
                <Tag icon={<LockFilled style={{ color: "black" }} />}>
                  {$t("gesloten")}
                </Tag>
              )}
              {!flight.is_prive && !flight.is_locked && (
                <Tag icon={<UnlockOutlined style={{ color: "black" }} />}>
                  {$t("open")}
                </Tag>
              )}

              {flight.is_prive && (
                <Tag icon={<EditFilled style={{ color: "black" }} />}>
                  {$t("buiten clubverband")}
                </Tag>
              )}
            </Descriptions.Item>
          </Descriptions>

          <div style={{ maxWidth: 350, marginBottom: 6 }}>
            <p>{$t("Bijzonderheden")}:</p>

            <Input.TextArea
              rows={4}
              disabled={readOnly && !editInstructorDetails}
              value={visibleFlight.bijzonderheden}
              onChange={(element) => {
                const value = element.target.value.substr(0, 500);
                saveFlight({ bijzonderheden: value }, $t("Bijzonderheden"));
                sleepFlight && saveSleepFlight({ bijzonderheden: value });
              }}
            />
          </div>
        </SettingCard>
        <SettingCard>
          <b>{$t("Notities")}</b>

          <FlightDetails flight={visibleFlight} />

          {sleepFlight && (
            <FlightDetails
              flight={visibleSleepFlight}
              title={$t("Sleep wijzigingen")}
              hideNotes
            />
          )}

          {currentUser?.wijzig_vluchten && visibleFlight.is_locked && (
            <div className="row" style={{ marginTop: 12 }}>
              <span>{$t("Wijzig vlucht als admin")}:</span>
              <Switch
                style={{ marginLeft: 6 }}
                checked={forceEdit}
                onChange={setForceEdit}
              />
            </div>
          )}
        </SettingCard>

        {flight.gezagvoerder_id && (
          <SignFlightCollapse
            flight={visibleFlight}
            updateFlight={async ({ flight }) => {
              setVisibleFlight(flight);
              onClose();
            }}
          />
        )}
      </Flex>
    </Modal>
  );
};
