import { Form } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { $t } from "~/i18n.js";
import { BreadcrumbHeader } from "../../components/layout/breadcrumbHeader.jsx";
import { MainLayout } from "../../components/layout/layout.jsx";
import { persistActions } from "../../redux/actions.js";
import { CardBestanden } from "./cards/card.bestanden.jsx";
import { CardBrevet } from "./cards/card.brevet.jsx";
import { CardContact } from "./cards/card.contact.jsx";
import { CardEmergency } from "./cards/card.emergency.jsx";
import { CardLidmaatschap } from "./cards/card.lidmaatschap.jsx";
import { CardMedical } from "./cards/card.medical.jsx";
import { CardPersonalia } from "./cards/card.personalia.jsx";
import { CardPrivacy } from "./cards/card.privacy.jsx";
import { CardSecurity } from "./cards/card.security.jsx";

export const MyDataPagina = ({ history }) => {
  const persist = useSelector((state) => state.persist);
  const dispatch = useDispatch();

  function profileAction(d) {
    dispatch(persistActions.profileAction(d));
  }

  const isGastvlieger =
    persist?.profile?.group_keys?.indexOf("gastvlieger") > -1;

  return (
    <MainLayout
      history={history}
      isLoading={persist.isPending || !persist.lastUpdated}
    >
      <BreadcrumbHeader breadcrumbs={[$t("Mijn gegevens")]} />

      <div className="MyData">
        <CardPersonalia
          profile={persist.profile}
          gegevens={persist.gegevens}
          saveData={profileAction}
        />
        {!isGastvlieger && (
          <CardBrevet
            profile={persist.profile}
            gegevens={persist.gegevens}
            saveData={profileAction}
            club={persist.club}
          />
        )}
        {!isGastvlieger && (
          <CardMedical
            club={persist.club}
            profile={persist.profile}
            gegevens={persist.gegevens}
            saveData={profileAction}
          />
        )}
        <CardBestanden
          profile={persist.profile}
          bestanden={persist.bestanden}
        />
        <CardContact
          profile={persist.profile}
          gegevens={persist.gegevens}
          saveData={profileAction}
        />
        <CardEmergency gegevens={persist.gegevens} saveData={profileAction} />
        {!persist.profile.group_keys?.includes("gastvlieger") && (
          <CardPrivacy
            profile={persist.profile}
            club={persist.club}
            saveData={profileAction}
          />
        )}
        <CardLidmaatschap
          isGastvlieger={isGastvlieger}
          profile={persist.profile}
          club={persist.club}
          saveData={profileAction}
        />

        <CardSecurity profile={persist.profile} />
      </div>
    </MainLayout>
  );
};
