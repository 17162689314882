import { EyeOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import React, { useEffect, useState } from "react";
import { HelpBubble } from "~/components/help/help.bubble.jsx";
import { _parseTime } from "~/lib/localize.js";
import { DescriptionRow } from "~/components/descriptionRow/descriptionRow.jsx";
import { $t } from "~/i18n.js";
import { api } from "~/lib/api.js";

const timeIfToday = (reservation, day_date) => {
  // if date is the same, return the time, otherwise leave empty because it's the full day of reservation
  let today = new Date(day_date).toDateString();
  let from = new Date(reservation.date_from).toDateString();
  let to = new Date(reservation.date_to).toDateString();
  if (from === today && to === today)
    return `${_parseTime(reservation.date_from)}-${_parseTime(
      reservation.date_to
    )}`;
  if (from === today) return `${_parseTime(reservation.date_from)}+`;
  if (to === today) return `-${_parseTime(reservation.date_to)}`;
};

const reservationColor = {
  open: "blue",
  tentative: "#34CC5C",
  accepted: "#F6A622",
  rejected: "#E64A4A",
};

const ReservationColorBubble = ({ r }) => (
  <div
    style={{
      height: 12,
      width: 12,
      borderRadius: "50%",
      backgroundColor: reservationColor[r.status],
      marginRight: 2,
    }}
  />
);

export const CardKisten = ({ aanmeldingen, day }) => {
  const [reservationLookup, setReservationLookup] = useState({});

  useEffect(() => {
    const load = async () => {
      const res = await api.get(`reservation/${day.datum}/view.json`);
      if (!res || res?.length < 1) return;

      const resLookup = {};
      res.forEach((r) => {
        if (!r.material) return;
        if (!resLookup[r.material.id])
          resLookup[r.material.id] = { material: r.material, reservations: [] };
        resLookup[r.material.id].reservations.push(r);
      });

      setReservationLookup(resLookup);
    };
    load();
  }, [day]);

  const materialIDs = Object.keys(reservationLookup);

  const reservationLabel = {
    open: $t("open"),
    tentative: $t("tentative"),
    accepted: $t("accepted"),
    rejected: $t("rejected"),
  };

  return (
    <div>
      <DescriptionRow title={$t("Startmethode")}>
        {$t(day.start_methode)}
      </DescriptionRow>
      <DescriptionRow title={$t("Trainingsvluchten")}>
        {aanmeldingen.rollen.checkstart}
      </DescriptionRow>
      <DescriptionRow title={$t("Overland")}>
        {aanmeldingen.rollen.overland}
      </DescriptionRow>

      {aanmeldingen.kisten && aanmeldingen.kisten?.types?.length > 0 && (
        <div>
          <Divider style={{ margin: "12px 0" }} />
          {aanmeldingen.kisten.types.map((kist, key) => (
            <DescriptionRow key={key} title={kist}>
              {aanmeldingen.kisten[kist]}
            </DescriptionRow>
          ))}
        </div>
      )}

      {materialIDs?.length > 0 && (
        <div>
          <Divider style={{ margin: "12px 0" }} />

          <p className="bold">{$t("Reserveringen")}</p>

          {materialIDs.map((mid, key) => (
            <div key={key}>
              <p>{reservationLookup[mid].material.name} </p>
              <div
                className="column"
                style={{ marginLeft: 2, alignItems: "flex-start" }}
              >
                {reservationLookup[mid].reservations.map((r, idx) => (
                  <div
                    className="row"
                    style={{ justifyContent: "space-between", width: "100%" }}
                    key={idx}
                  >
                    <div className="row">
                      <ReservationColorBubble r={r} />
                      <p className="gray small">
                        {timeIfToday(r, day.datum)}{" "}
                        {r.user.name || $t("Onbekend")}
                      </p>
                    </div>

                    <HelpBubble
                      title={$t("Reservering") + " " + r.user.name}
                      content={
                        <div
                          className="column"
                          style={{ gap: 12, alignItems: "flex-start" }}
                        >
                          <p>{r.user_remark}</p>

                          <div className="row">
                            <ReservationColorBubble r={r} />
                            <p>
                              {$t("Status")}: {reservationLabel[r.status]}
                            </p>
                          </div>

                          <Button
                            href={`/reservations/#/${r.id}`}
                            size="small"
                            icon={<EyeOutlined />}
                            style={{ marginTop: 4 }}
                          >
                            {" "}
                            {$t("Bekijk")}{" "}
                          </Button>
                        </div>
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
