import { Button, Select, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { $t } from '~/i18n.js'

import { PlusOutlined } from '@ant-design/icons'
import { CopyIcon } from '~/components/icon/copy.jsx'
import { BreadcrumbHeader } from '~/components/layout/breadcrumbHeader.jsx'
import { MainLayout } from '~/components/layout/layout.jsx'
import { api } from '~/lib/api.js'
import { updateIfNeeded } from '~/lib/helpers.js'
import { daysActions } from '~/redux/actions.js'
import { normalizeRooster } from '~/redux/days/days.normalizers.js'
import { AddRoosterModal } from './addRooster/addRooster.modal.jsx'
import { Diensten } from './diensten.jsx'
import { RoostersActions } from './roostersActions.jsx'

const RoostersComponent = ({ history, match, profile, flights, days, getRoosters, saveRooster }) => {
  const [rooster, setRooster] = useState(null)

  const [editRoosterData, setEditRoosterData] = useState(null)
  const [showRoosterModal, setShowRoosterModal] = useState(false)
  const openEditRooster = (data) => {
    setEditRoosterData(data)
    setShowRoosterModal(true)
  }

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    updateIfNeeded(days.lastUpdatedRoosters, getRoosters, openFirstRooster)
  }, [days.lastUpdatedRoosters, getRoosters, setIsLoading])

  const openFirstRooster = () => {
    if (match.params.id) return
    if (days?.roosters[0]?.id) {
      history.push('/rosters/' + days.roosters[0].id)
    } else {
      setIsLoading(false)
    }
  }

  const getCurrentRooster = async () => {
    if (!match.params.id) return setIsLoading(false)
    setIsLoading(true)

    const res = await api.get(`rooster/${match.params.id}/get_rooster.json`)
    // normalize rooster and setup
    if (res?.rooster) {
      setRooster(normalizeRooster(res.rooster, days.users, profile.id))
      setIsLoading(false)
    }
  }

  const triggerSaveRooster = async (data) => {
    const newRooster = await saveRooster(data)
    if (newRooster) setRooster(normalizeRooster(newRooster, days.users, profile.id))
    return newRooster
  }

  useEffect(() => {
    if (!days.lastUpdatedRoosters) return
    getCurrentRooster()
  }, [match.params.id])

  // if rooster is open for registration, but current user is not part of any of the member, skip
  const [bekijkAlsRoostermaker, setBekijkAlsRoostermaker] = useState(false)
  const canSaveAvailability = !bekijkAlsRoostermaker && rooster?.status === 'open_registration'

  let showDiensten = bekijkAlsRoostermaker || rooster?.status === 'active'
  if (!showDiensten && rooster?.status === 'open_registration') {
    rooster?.roosters.forEach(r => {
      if (r.autofill === 'social') {
        if (profile.group_keys.indexOf(r.group_key) > -1) showDiensten = true
      }
    })
  }

  return (
    <MainLayout history={history} isLoading={isLoading}>
      <BreadcrumbHeader
        breadcrumbs={[
          <span key="1" className="title">
            {$t("Roosters")}
          </span>,
          days.roosters.length > 0 && (
            <div key="2" className="row">
              <Select
                value={rooster?.id}
                style={{ minWidth: 250 }}
                onChange={(id) => history.push("/rosters/" + id)}
              >
                {days.roosters.map((rooster, id) => (
                  <Select.Option key={id} value={rooster.id}>
                    {rooster.description}{" "}
                    <span className="gray">
                      ({rooster.date_from} tot {rooster.date_to})
                    </span>{" "}
                  </Select.Option>
                ))}
              </Select>
              <CopyIcon text={window.location.href} />
            </div>
          ),
        ].filter((r) => r)}
        buttons={[
          profile.wijzig_rooster && (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => openEditRooster({})}
            >
              {$t("Nieuw rooster")}
            </Button>
          ),
        ]}
      />

      <AddRoosterModal
        users={days.users}
        editRoosterData={editRoosterData}
        modalVisible={showRoosterModal}
        closeModal={() => {
          setShowRoosterModal(false);
          setEditRoosterData({});
        }}
        saveRooster={triggerSaveRooster}
      />

      {days.roosters.length == 0 && (
        <p style={{ marginTop: 24 }}>{$t("Er geen actieve roosters")}.</p>
      )}

      {rooster && (
        <>
          {profile.wijzig_rooster && (
            <p className="spacer">
              {$t("Bekijk als roostermaker:")}
              <Switch
                size="small"
                style={{ marginLeft: 4 }}
                onChange={(checked) => setBekijkAlsRoostermaker(checked)}
                checked={bekijkAlsRoostermaker}
              />
            </p>
          )}
          <RoostersActions
            canSaveAvailability={
              !bekijkAlsRoostermaker && showDiensten && canSaveAvailability
            }
            bekijkAlsRoostermaker={bekijkAlsRoostermaker}
            rooster={rooster}
            saveRooster={triggerSaveRooster}
            setRooster={setRooster}
            openRooster={openEditRooster}
          />

          {showDiensten ? (
            <Diensten
              profile={profile}
              data={rooster}
              saveAvailability={!bekijkAlsRoostermaker && canSaveAvailability}
              setRooster={setRooster}
              group_name_lookup={flights.group_name_lookup}
              editRooster={bekijkAlsRoostermaker}
            />
          ) : (
            <p style={{ marginTop: 24 }}>
              {$t("Rooster is nog in concept fase en niet zichtbaar.")}
            </p>
          )}
        </>
      )}
    </MainLayout>
  );
}

const mapStateToProps = (state) => ({
  days: state.days,
  profile: state.persist.profile,
  flights: state.flights
})

const mapDispatchToProps = (dispatch) => {
  return {
    getRoosters: () => dispatch(daysActions.getRoosters()),
    saveRooster: (d) => dispatch(daysActions.saveRooster(d))
  }
}

export const RoostersPagina = connect(mapStateToProps, mapDispatchToProps)(RoostersComponent)
