import { Input, Rate } from "antd";
import dayjs from "dayjs";
import React from "react";
import { $t } from "~/i18n.js";

import { DatePicker, Flex, Radio } from "antd";
import { _parseDate } from "~/lib/localize.js";
import { ClockCircleFilled } from "@ant-design/icons";
import { colors } from "~/theme/colors.js";

export const ScoreForm = ({
  bevoegdheid,
  score,
  remark,
  setScore,
  setRemark,
  date_assigned,
  setDateAssigned,
  date_geldig,
  setDateGeldig,
}) => {
  return (
    <Flex vertical={true} gap={24}>
      <p>{bevoegdheid.description}</p>

      {bevoegdheid.scoring == "bd_rank" && (
        <Flex align="center" gap={24}>
          <span>{$t("Stage")}:</span>
          <Radio.Group
            size="medium"
            buttonStyle="solid"
            value={score}
            onChange={(event) => setScore(event.target.value)}
          >
            <Radio.Button value="briefing">{$t("briefing")}</Radio.Button>
            <Radio.Button value="demo">{$t("demo")}</Radio.Button>
          </Radio.Group>
        </Flex>
      )}
      {(bevoegdheid.scoring === "rank" || bevoegdheid.scoring == "bd_rank") && (
        <Flex align="center" gap={24}>
          <span>{$t("Score")}:</span>
          <Rate onChange={setScore} value={score} style={{ fontSize: 32 }} />
        </Flex>
      )}

      {bevoegdheid.has_remark && (
        <div>
          <p>{$t("Opmerking")}:</p>
          <Input
            type="string"
            value={remark}
            onChange={(elm) => setRemark(elm.target.value.substring(0, 90))}
          />
        </div>
      )}

      <Flex align="center">
        <p>{$t("Datum behaald")}:</p>
        <DatePicker
          value={date_assigned}
          style={{ marginLeft: 12 }}
          format={localStorage.localeFormat}
          allowClear={false}
          onChange={setDateAssigned}
          disabled={!score || score == 0 || score == "revoked"}
          disabledDate={(current) => current && current > dayjs().endOf("day")}
        />
      </Flex>

      {date_geldig && (
        <Flex align="center">
          <p>{$t("Geldig tot")}:</p>
          <DatePicker
            value={date_geldig}
            style={{ marginLeft: 12 }}
            format={localStorage.localeFormat}
            allowClear={false}
            onChange={setDateGeldig}
            disabled={!score || score == 0 || score == "revoked"}
            disabledDate={(current) =>
              current && current < dayjs().endOf("day")
            }
          />

          {bevoegdheid.behaald.days_left && (
            <span className="gray" style={{ marginLeft: 12 }}>
              <ClockCircleFilled
                style={{
                  color: bevoegdheid.behaald.nearly_invalid
                    ? colors.orange
                    : colors.groen_dark,
                }}
              />
              {" " + bevoegdheid.behaald.days_left} {$t("dagen")}
            </span>
          )}
        </Flex>
      )}
    </Flex>
  );
};
