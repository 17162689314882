import { Form, Input } from "antd";
import React from "react";
import { SettingCard } from "~/components/dataCards/SettingCard.jsx";
import { ListSelectToString } from "~/components/form/ListSelectToString.jsx";
import { $t } from "~/i18n.js";
import { LengthValidator, ValidateEmailListValidator } from '~/components/form/formHelpers.js'
import { HelpBubble } from "~/components/help/help.bubble";

export const VerslagList = () => {
  return (
    <SettingCard
      title={
        <p>
          {$t("Dagverslag")}{" "}
          <HelpBubble
            content={$t(
              "Specifieke clubs, zoals op militaire basis, kunnen dagelijks dagverslagen mailen"
            )}
          />
        </p>
      }
    >
      <Form.Item
        label={$t("Email")}
        extra={$t("Adressen waar het dagverslag heen kan")}
        name="verslag_email"
        rules={[{ required: false }]}
      >
        <ListSelectToString allowAddNew={true} />
      </Form.Item>

      <Form.Item
        label={$t("Reply to email")}
        name="verslag_reply_to"
        rules={[LengthValidator(100, false)]}
      >
        <Input type="email" />
      </Form.Item>

      <Form.Item
        label={$t("Email onderwerp")}
        name="verslag_subject"
        rules={[LengthValidator(100, false)]}
      >
        <Input type="text" />
      </Form.Item>

      <Form.Item
        label={$t("Template")}
        extra={
          "Template, can add the following tags: {{ date }} {{ daily_notes }} {{ created_by }} {{ number_flights }} {{ flights_per_method }} {{ flights_per_category }} {{ flights_per_method_category }}, --- for a horizontal line"
        }
        name="verslag_template"
        rules={[LengthValidator(1000, false)]}
      >
        <Input.TextArea rows={4} />
      </Form.Item>

      <Form.Item
        label={$t("Help")}
        extra={$t(
          "Uitleg dat als hulp wordt laten zien bij aanmaken van het dagverslag"
        )}
        name="verslag_help"
        rules={[LengthValidator(500, false)]}
      >
        <Input.TextArea rows={4} />
      </Form.Item>
    </SettingCard>
  );
};
