import { CheckCircleFilled, IssuesCloseOutlined } from "@ant-design/icons";
import { Button, Checkbox, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { config } from "~/config.js";
import { $t } from "~/i18n.js";
import { api } from "~/lib/api.js";
import { _parseDate } from "~/lib/localize.js";
import { normalizeVliegerData } from "~/redux/persist/persist.normalizers.js";

import { colors } from "~/theme/colors.js";
import { SluitButton } from "../buttons/SluitButton.jsx";
import { SaveButton } from "../buttons/SaveButton.jsx";
import { HelpBubble } from "../help/help.bubble.jsx";

export const MedicalCheckModal = ({ onUpdateVliegerdata, user, currentProfile }) => {
  
  const dispatch = useDispatch();
  
  if (!user) return null;
  if (!currentProfile || !currentProfile.ziet_dto) return null;

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);

  const closeModal = () => setVisible(false);
  useEffect(closeModal, [user]);
  useEffect(() => {
    setLoading(false);
    setCheck(false);
  }, [visible]);

  const triggerSave = async () => {
    setLoading(true);

    const res = await api.post("gegevens/verify_medical.json", { id: user.id });

    if (res) {
      const data = normalizeVliegerData(res);
      dispatch({
        type: "UPDATE_VLIEGER_IN_LIST",
        id: user.id,
        vlieger_data: data,
      });
      onUpdateVliegerdata(data);
    }

    setLoading(false);
    setVisible(false);
  };

  // only for dto users, with a medical uploaded
  const gegevens = user?.gegevens;
  if (!user?.check_med || !gegevens) return null;

  return (
    <div>
      <div className="clickable" onClick={() => setVisible(true)}>
        {gegevens.medical_checked_by && (
          <CheckCircleFilled style={{ color: colors.groen_dark }} />
        )}

        {!gegevens.medical_checked_by && (
          <Button danger size="small" className="bounce">
            <IssuesCloseOutlined /> {$t("Controleer")}
          </Button>
        )}
      </div>

      <Modal
        title={$t("Controleer medical")}
        open={visible}
        onCancel={closeModal}
        footer={[
          <SluitButton key="sluit" onClick={closeModal} />,
          <SaveButton
            key="opslaan"
            loading={loading}
            disabled={!check}
            onClick={triggerSave}
          />,
        ]}
      >
        <HelpBubble
          content={$t(
            "De verantwoordelijkheid voor een correcte medical ligt bij de DTO, elke instructeur kan je medical controleren."
          )}
        >
          {$t("Medical geldig van ${from} tot ${to}", {
            from: _parseDate(gegevens.medical_valid_from, {
              year: "numeric",
              month: "short",
              day: "numeric",
            }),
            to: _parseDate(gegevens.medical_valid_to, {
              year: "numeric",
              month: "short",
              day: "numeric",
            }),
          })}
        </HelpBubble>

        <div style={{ marginTop: 24 }}>
          {gegevens.medical ? (
            <p>
              {$t("Bestand")}:{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                title="file"
                href={config.media_prefix + gegevens.medical.file}
              >
                {gegevens.medical.name}
              </a>
            </p>
          ) : (
            <p>{$t("Geen medical file toegevoegd")}</p>
          )}
        </div>

        {gegevens.medical_checked_by && (
          <p className="small" style={{ color: "grey" }}>
            {$t(
              `Gecontrolleerd door ${
                gegevens.medical_checked_by
              } op ${_parseDate(gegevens.medical_checked_at)}`
            )}
          </p>
        )}

        {!gegevens.medical_checked_by && (
          <Checkbox
            checked={check}
            style={{ marginTop: 24 }}
            onChange={(e) => setCheck(e.target.checked)}
          >
            {$t(
              "Hierbij verklaar ik, ${checked_by}, dat de medical van ${medical_from} gecontroleerd is.",
              { checked_by: currentProfile?.name, medical_from: user?.name }
            )}
          </Checkbox>
        )}
      </Modal>
    </div>
  );
};
