import { DownOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Dropdown, Modal, Radio, Tag, message } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { $t } from "~/i18n.js";
import { all_start_methods } from "~/redux/flights/flights.state.js";

export const SectionStartmethode = ({
  readOnly,
  flight,
  saveFlight,
  connectOrCreateSleepFlight,
  size = "medium",
  confirmSelection = false,
  extra_methods = [],
  maxWidth = 500,
}) => {
  // createNewSleepFlight: only for club tijdschrijven, not for private admin, if sleep selected it will generate a second new flight for the sleepvliegtuig
  const [start_methode, setStartMethod] = useState(flight.start_methode);
  const [loading, setLoading] = useState(false);
  const [alreadyFired, setAlreadyFired] = useState(false);

  const { club } = useSelector((state) => state.persist);
  const club_methods = club.start_methods?.split(",") || ["lier", "sleep"];
  const other_methods = all_start_methods
    .concat(extra_methods)
    .filter((m) => !club_methods.includes(m));
  const other_method_items = other_methods.map((method) => ({
    key: method,
    label: $t(method),
    disabled: readOnly || loading,
  }));
  const isOtherMethod = !club_methods.includes(start_methode);

  useEffect(() => {
    setStartMethod(flight.start_methode);
    setAlreadyFired(false);
    setLoading(false);
  }, [flight]);

  const setStartMethode = async (method) => {
    // debounce function
    if (alreadyFired) {
      message.error($t("Methode word nog gewijzigt, een moment geduld"));
      return null;
    }
    setAlreadyFired(true);

    // debounce only if sleepFlight should be connected
    setTimeout(
      () => setAlreadyFired(false),
      connectOrCreateSleepFlight ? 2000 : 100
    );

    setLoading(true);
    const new_method = method;
    setStartMethod(new_method);

    let sleep_uuid = null;
    if (connectOrCreateSleepFlight) {
      sleep_uuid = await connectOrCreateSleepFlight({ flight, new_method });
    }

    await saveFlight({ start_methode: new_method, sleep_uuid });
    setLoading(false);
  };

  const triggerStartMethode = (method) => {
    if (confirmSelection) {
      Modal.confirm({
        icon: <ExclamationCircleOutlined />,
        content: (
          <p>
            {$t("Zeker weten?")} <br />
            {$t(
              "Deze aanpassing in startmethode zal direct zichtbaar worden in de startadministratie."
            )}
          </p>
        ),
        onOk() {
          setStartMethode(method);
        },
        onCancel() {},
      });
    } else {
      setStartMethode(method);
    }
  };

  return (
    <div className="column" style={{ alignItems: "flex-start", marginTop: 12 }}>
      {start_methode?.endsWith("-a") && !flight.sleep_uuid && (
        <p style={{ marginBottom: 12 }}>
          <Tag color="red">{$t("Let op")}</Tag>{" "}
          {$t('Vlucht is een "aerotow" zonder gekoppelde zweefvlucht')}
        </p>
      )}

      <div
        className="row spacer"
        style={{ flexWrap: "wrap", maxWidth: maxWidth }}
      >
        <Radio.Group
          size={size}
          buttonStyle="solid"
          disabled={readOnly || loading}
          value={start_methode}
          onChange={(e) => {
            !loading && triggerStartMethode(event.target.value);
          }}
        >
          {club_methods.map((method, index) => (
            <Radio.Button key={index} value={method}>
              {$t(method)}
            </Radio.Button>
          ))}

          <Dropdown
            key="other"
            menu={{
              items: other_method_items,
              onClick: (e) => {
                !loading && triggerStartMethode(e.key);
              },
            }}
            placement="bottom"
          >
            <Button
              key="otherKey"
              size={size}
              disabled={readOnly || loading}
              type={isOtherMethod ? "primary" : "default"}
              style={{
                borderBottomLeftRadius: 0,
                borderTopLeftRadius: 0,
                padding: "4px 10px",
              }}
            >
              {isOtherMethod ? $t(start_methode) : $t("anders")}{" "}
              <DownOutlined />
            </Button>
          </Dropdown>
        </Radio.Group>
      </div>
    </div>
  );
};
