import { DatePicker, Form, Input, Modal, Switch } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SaveButton } from "~/components/buttons/SaveButton.jsx";
import { SluitButton } from "~/components/buttons/SluitButton.jsx";
import { VerwijderButtonConfirm } from "~/components/buttons/VerwijderButtonConfirm.jsx";
import { $t } from "~/i18n.js";
import { api } from "~/lib/api.js";
import { toLocaleDate } from "~/lib/localize.js";
import { daysActions } from "~/redux/actions.js";
import { FlightLocationFields } from "./FlightLocationFields.jsx";
import { SelectTypeDag } from "./SelectTypeDag.jsx";

export function AddDayModal({
  activeDay,
  club,
  visible,
  onCancel,
  onOk,
  confirmLoading,
  link_to,
  history,
  profile,
}) {
  if (!visible) return null;
  const [form] = Form.useForm();

  const [name, setName] = useState("");
  const [items, setItem] = useState(club && club.dag_types?.split(","));
  const [atThuisveld, setAtThuisVeld] = useState(true);
  const [vliegend, setVliegend] = useState(true);
  const dispatch = useDispatch();

  const [loadingVerwijder, setLoadingVerwijder] = useState(false);
  const verwijder = async () => {
    setLoadingVerwijder(true);
    const { data } = await api.delete("days.json", {
      dag_id: activeDay.dag_id,
    });
    if (data) {
      await dispatch(daysActions.getAllDays());
      history && history.push("/");
    }
    setLoadingVerwijder(false);
  };

  const vliegveld = !club
    ? ""
    : club.vliegveld_icao
    ? club.vliegveld_icao
    : club.vliegveld;

  useEffect(() => {
    setAtThuisVeld(
      !activeDay || !club || activeDay.vertrek_vliegveld === vliegveld
    );
    setVliegend(!activeDay ? true : activeDay?.is_vliegend);
  }, [activeDay, visible]);

  return (
    <Modal
      title={
        activeDay
          ? toLocaleDate(new Date(activeDay.datum))
          : $t("Voeg nieuwe dag toe")
      }
      open={visible}
      onCancel={onCancel}
      footer={[
        ((activeDay && profile?.ziet_staff) || profile?.wijzig_rooster) && (
          <VerwijderButtonConfirm
            key="verwijder"
            loading={loadingVerwijder}
            onConfirm={verwijder}
          />
        ),

        <SaveButton
          key="opslaan"
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();

                const vertrek_vliegveld =
                  atThuisveld || !values.locatie ? vliegveld : values.locatie;
                onOk({
                  ...activeDay,
                  ...values,
                  datum: activeDay
                    ? activeDay.datum
                    : values.datum.format("YYYY-MM-DD"),
                  briefing_tijd: values.briefing_tijd,
                  aankomst_vliegveld: vertrek_vliegveld || "-",
                  vertrek_vliegveld: vertrek_vliegveld || "-",
                  notify_users: !!activeDay,
                });
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
          loading={confirmLoading}
        />,
        <SluitButton key="sluit" onClick={onCancel} />,
      ]}
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        name="addDay"
        preserve={false}
        requiredMark={false}
        initialValues={{
          type_dag:
            club && club.dag_types?.length > 0
              ? club.dag_types?.split(",")[0].trim()
              : "",
          start_methode: "lier",
          ...activeDay,
          datum: activeDay ? dayjs(activeDay.datum) : dayjs(),
          briefing_tijd: activeDay ? activeDay.briefing_tijd : "09:00",
          locatie: activeDay ? activeDay.vertrek_vliegveld : vliegveld,
        }}
      >
        {link_to && (
          <Form.Item
            label={$t("Datum")}
            name="datum"
            rules={[{ required: true, message: $t("Voeg datum toe!") }]}
          >
            <DatePicker
              format={localStorage.localeFormat}
              disabledDate={(current) => {
                if (link_to === "start")
                  return current && current > dayjs().startOf("day");
                return current && current < dayjs().startOf("day");
              }}
            />
          </Form.Item>
        )}

        <SelectTypeDag
          setItem={setItem}
          setName={setName}
          name={name}
          items={items}
        />

        <Form.Item
          label={$t("Beschrijving")}
          name="titel"
          rules={[
            {
              required: false,
              message: $t("Maximaal ${c} characters", { c: 100 }),
              max: 110,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label={$t("Vliegend")} name="is_vliegend">
          <Switch
            onChange={(checked) => setVliegend(checked)}
            checked={vliegend}
            checkedChildren={$t("Ja")}
            unCheckedChildren={$t("Nee")}
          />
        </Form.Item>

        <FlightLocationFields
          vliegend={vliegend}
          atThuisveld={atThuisveld}
          setAtThuisVeld={setAtThuisVeld}
          vliegveld={vliegveld}
        />

        <Form.Item
          label={$t("Briefing tijd")}
          name="briefing_tijd"
          rules={[{ required: true, message: $t("Voeg tijd toe!") }]}
        >
          <Input type="time" style={{ width: 120 }} placeholder="hh:mm" />
        </Form.Item>

        {activeDay && (
          <Form.Item
            label={$t("Gaat door")}
            name="gaat_door"
            valuePropName="checked"
          >
            <Switch checkedChildren={$t("Ja")} unCheckedChildren={$t("Nee")} />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
}
