import { Divider } from "antd";
import React from "react";
import { DescriptionRow } from "~/components/descriptionRow/descriptionRow.jsx";
import { $t } from "~/i18n.js";

export const CardRollen = ({
  aanmeldingen,
  groups_aanmeld,
  group_name_lookup,
}) => {
  return (
    <div>
      <DescriptionRow title={$t("Vliegers")}>
        {aanmeldingen.rollen.vliegers}
      </DescriptionRow>

      {aanmeldingen.rollen.nieuw > 0 && (
        <DescriptionRow title={$t("Nieuwe leden")}>
          {aanmeldingen.rollen.nieuw}
        </DescriptionRow>
      )}

      <Divider style={{ margin: "12px 0" }} />

      <DescriptionRow title={$t("instructeur")}>
        {aanmeldingen.rollen.as_instructeur}
      </DescriptionRow>
      <DescriptionRow title={$t("dbo")}>
        {aanmeldingen.rollen.dbo || 0}
      </DescriptionRow>
      <DescriptionRow title={$t("solist")}>
        {aanmeldingen.rollen.solist || 0}
      </DescriptionRow>
      <DescriptionRow title={$t("brevet")}>
        {aanmeldingen.rollen.brevet || 0}
      </DescriptionRow>
      <DescriptionRow title={$t("sleepvlieger")}>
        {aanmeldingen.rollen.as_sleepvlieger}
      </DescriptionRow>

      <Divider style={{ margin: "12px 0" }} />

      {groups_aanmeld &&
        groups_aanmeld.length > 1 &&
        groups_aanmeld.split(",").map((g, idx) => (
          <DescriptionRow key={idx} title={group_name_lookup[g] || g}>
            {aanmeldingen.rollen[g] || 0}
          </DescriptionRow>
        ))}
    </div>
  );
};
