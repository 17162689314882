import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, Modal, Select, Table, Tag, Upload, message } from "antd";
import React, { useEffect, useState } from "react";
import { $t } from "../../i18n.js";

import { useDispatch } from "react-redux";
import { AnnuleerButton } from "~/components/buttons/AnnuleerButton.jsx";
import { FlightViewModal } from "~/components/flight/FlightViewModal.jsx";
import { parseCsv } from "~/lib/parsers.js";
import { HelpBubble } from "../../components/help/help.bubble.jsx";
import { defaultTableConfig } from "../../components/layout/table.jsx";
import { FlightTags } from "~/components/flight/sections/FlightTags.jsx";
import { api } from "../../lib/api.js";
import { generate_uuid } from "../../lib/helpers.js";
import { _parseDate, parseMinutes } from "../../lib/localize.js";
import { getFlightStats } from "../../redux/flights/flights.actions.js";
import { Flight } from "./Flight.js";

function parseFlight(obj, { profile }) {
  const f = new Flight(obj);
  // console.log(profile)
  // Rewrite uuid to be more unique for a user
  f.uuid = `${obj.uuid || generate_uuid()}::${profile.id}`;
  return f;
}

function replaceUser(fromName = "", toId = "", toName = "", flights) {
  return flights.map((flight) => {
    return {
      ...flight,

      is_deleted: false,

      // Remap userid, both on name as ID
      gezagvoerder_id: flight.gezagvoerder_naam === fromName ? toId : null,
      gezagvoerder_naam:
        flight.gezagvoerder_naam === fromName
          ? toName
          : flight.gezagvoerder_naam,

      tweede_inzittende_id:
        flight.tweede_inzittende_naam === fromName ? toId : null,
      tweede_inzittende_naam:
        flight.tweede_inzittende_naam === fromName
          ? toName
          : flight.tweede_inzittende_naam,
    };
  });
}

export const BulkImport = ({ profile, showModal, closeModal }) => {
  const dispatch = useDispatch();

  const [flight, openFlight] = useState(null);
  const [fileList, setFileList] = useState([]);

  const [originalFlights, setOriginalFlights] = useState([]);
  const [flights, setFlights] = useState([]);

  const [possibleUsers, setPossibleUsers] = useState([]);
  const [user, setUser] = useState(null);

  const [loading, setLoading] = useState(false);

  const [status, setStatus] = useState({});
  const updateStatus = (flight, _new) => {
    const _s = status;
    _s[flight.uuid] = _new;
    setStatus(_s);
    setFlights([...flights]);
  };

  const resetState = () => {
    setOriginalFlights([]);
    setFlights([]);
    setPossibleUsers([]);
    setUser(null);
    setStatus({});
  };
  useEffect(resetState, [showModal]);

  if (!showModal) return null;

  const selectUser = (user, _flights) => {
    setUser(user);
    setFlights(
      replaceUser(user, profile.id, profile.name, _flights || originalFlights)
    );
  };

  const parseNewFlightList = (text) => {
    let data = null;
    try {
      data = parseCsv(text);
    } catch (e) {
      message.error(`Fout bestand: ${e}`);
      console.log("ERROR: ", e);
      return;
    }

    const _flights = data.map((f) => parseFlight(f, { profile }));
    setOriginalFlights(_flights);

    // create lookup for pilot
    const lookupTable = {};
    _flights.forEach((f) => {
      const keys = ["gezagvoerder_naam", "tweede_inzittende_naam"];
      keys.forEach((key) => {
        if (f[key]) {
          const name = f[key];
          if (!lookupTable[name]) lookupTable[name] = 0;
          lookupTable[name] += 1;
        }
      });
    });

    const lookup = Object.keys(lookupTable)
      .map((key) => ({ value: key, label: key, count: lookupTable[key] }))
      .sort((a, b) => b.count - a.count);
    setPossibleUsers(lookup);

    // select first user
    if (lookup.length > 0) {
      selectUser(lookup[0].value, _flights);
    } else {
      setFlights(_flights);
    }
  };

  const parseNewFile = (file) => {
    if (!file || file.type !== "text/csv") {
      message.error("Bestand is fout, moet type CSV zijn");
      return;
    }

    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => parseNewFlightList(reader.result),
      false
    );
    reader.readAsText(file);

    setFileList([file]);
  };

  const startImport = async () => {
    let success = 0;
    let fail = 0;
    setLoading(true);

    for (const index in flights) {
      const flight = flights[index];

      if (flight._valid) {
        updateStatus(flight, "loading");

        const flightData = await api.post(
          "flights/save_flight.json",
          flight,
          true
        );
        if (flightData && !flightData.error) {
          success += 1;
          updateStatus(flight, "success");
        } else {
          fail += 1;
          updateStatus(flight, `fail: ${flightData?.detail || "server fout"}`);
        }
      }
    }

    if (fail > 0) {
      Modal.error({
        title: $t("Foutmeldingen"),
        content: $t("Niet alle vluchten konden worden geimporteerd"),
      });
    } else if (success == 0) {
      Modal.error({
        title: $t("Foutmeldingen"),
        content: $t("Bestand bevat geen valide vluchten"),
      });
    } else {
      await dispatch(getFlightStats({}));
      message.success(`${success} ${$t("vluchten geimporteerd")}`);
      closeModal();
    }
    setLoading(false);
  };

  return (
    <Modal
      title={$t("Importeer vluchten")}
      open={showModal}
      width="90%"
      confirmLoading={loading}
      onCancel={!loading && closeModal}
      onOk={startImport}
      okText={$t("Importeer")}
      cancelText={$t("Annuleren")}
      footer={[
        <AnnuleerButton key="annuleren" onClick={!loading && closeModal} />,
        <Button
          key="opslaan"
          onClick={startImport}
          disabled={!flights || !user}
          type="primary"
          loading={loading}
        >
          {$t("Importeer")}
        </Button>,
      ]}
    >
      <p className="bold" style={{ marginBottom: 6 }}>
        1. {$t("Selecteer bestand")}:
        <HelpBubble
          content={
            <div>
              <p>
                {$t(
                  "Bestand moet comma-seperated geformateerd zijn, zoals de export vanuit de ZweefApp."
                )}
              </p>
              <p>
                {$t(
                  "Kom je vanuit een club zonder de ZweefApp, gebruik dan de volgende template:"
                )}
              </p>
              <p>
                <a href="https://zweefapp-ui.s3.eu-central-1.amazonaws.com/template_import_flights.csv">
                  {$t("Link naar template")}
                </a>
              </p>
            </div>
          }
        />
      </p>
      <div style={{ height: 80 }}>
        <Upload
          name="file"
          listType="text"
          multiple={false}
          fileList={fileList}
          onChange={(elm) => parseNewFile(elm?.file)}
          beforeUpload={(f) => false}
          showUploadList={{ showPreviewIcon: false, showRemoveIcon: false }}
        >
          <Button
            icon={<UploadOutlined />}
            type={fileList.length > 0 ? "default" : "primary"}
            disabled={loading}
          >
            {$t("Laad bestand")}
          </Button>
        </Upload>
      </div>

      <p className="bold" style={{ marginBottom: 6 }}>
        2. {$t("Selecteer jezelf")}:
        <HelpBubble
          content={$t(
            "Vluchtend worden als jij gezagvoerder of tweede inzittende bent geimporteerd naar je eigen profiel."
          )}
        />
      </p>

      <Select
        style={{ width: 250 }}
        value={user}
        onChange={(user) => selectUser(user)}
        disabled={!possibleUsers.length || loading}
        options={possibleUsers}
      />

      <p className="bold" style={{ marginBottom: 6, marginTop: 24 }}>
        3. {$t("Bekijk de vluchten")}
      </p>

      <FlightViewModal
        onClose={() => openFlight(null)}
        flight={flight}
        currentUser={profile}
        updateFlight={null}
        onUpdateFlight={null}
        showPilotLink={true}
        hideSigning={true}
      />

      <Table
        style={{
          marginTop: 12,
          width: "97vw",
          overflowX: "auto",
          cursor: "pointer",
        }}
        pagination={{ ...defaultTableConfig, defaultPageSize: 10 }}
        size="small"
        rowKey="uuid"
        dataSource={flights}
        columns={[
          {
            title: $t("Datum"),
            dataIndex: "datum",
            sorter: (a, b) => new Date(a.datum) - new Date(b.datum),
            render: (text) =>
              _parseDate(text),
          },
          { title: $t("Vertrek"), dataIndex: "vertrek_vliegveld" },
          { title: $t("Callsign"), dataIndex: "callsign", responsive: ["md"] },
          { title: $t("Registratie"), dataIndex: "registratie" },
          { title: $t("Type"), dataIndex: "type", responsive: ["md"] },
          {
            title: $t("Methode"),
            dataIndex: "start_methode",
            render: (t) => $t(t),
          },
          { title: $t("Starts"), dataIndex: "starts" },
          {
            title: $t("Duur"),
            dataIndex: "vluchtduur",
            render: (text) => parseMinutes(text),
          },
          {
            render: (_, flight) => (
              <div className="row">
                {!flight._valid &&
                  flight._errors.map((e, key) => (
                    <Tag key={key} icon={<CloseCircleOutlined />} color="error">
                      {e}
                    </Tag>
                  ))}

                <FlightTags
                  flight={flight}
                  pilot={profile}
                  showNotitie
                  hidePrive
                />
              </div>
            ),
          },
          {
            render: (_, flight) => {
              return (
                <div>
                  <Button
                    type="primary"
                    size="small"
                    style={{ marginRight: 12 }}
                  >
                    {$t("Open")}
                  </Button>

                  {status[flight.uuid] === "loading" && (
                    <Tag icon={<SyncOutlined spin />} color="processing" />
                  )}

                  {status[flight.uuid] === "success" && (
                    <Tag icon={<CheckCircleOutlined />} color="success" />
                  )}

                  {status[flight.uuid]?.startsWith("fail") && (
                    <Tag icon={<ExclamationCircleOutlined />} color="warning">
                      {status[flight.uuid]}
                    </Tag>
                  )}
                </div>
              );
            },
          },
        ]}
        onRow={(flight) => {
          return {
            onClick: () => openFlight(flight),
          };
        }}
      />
    </Modal>
  );
};
