import { PlusOutlined } from "@ant-design/icons";
import { Button, Collapse, Flex } from "antd";
import React from "react";
import { $t } from "~/i18n.js";
import { generate_uuid } from "~/lib/helpers.js";
import { CategoryCollapse } from "./CategoryCollapse.jsx";
import { DifCheckTag } from "./DifCheckTag.jsx";
import {
  getNextOrderInList,
  OrderDeleteButtons,
} from "./OrderDeleteButtons.jsx";
import { useSelector } from "react-redux";

export const ClusterChecklist = ({
  categories = [],
  saveCategories = (c) => {},
}) => {

  const { group_name_lookup } = useSelector((state) => state.flights);

  const saveCategory = (newCategory) => {
    let newCategories = categories.map((c) => {
      if (c.id === newCategory.id) return { ...c, ...newCategory };
      return c;
    });
    saveCategories(newCategories);
  };

  const copyCategory = (task) => {
    let newCategory = {
      ...task,
      label: task.label || task.name,
      name: "",
      id: generate_uuid(),
      action: "add",
      order: getNextOrderInList(categories),
      bevoegdheid: task.bevoegdheid.map((b) => ({
        ...b,
        id: generate_uuid(),
        action: "add",
      })),
    };
    let newCategories = categories.concat([newCategory]);
    saveCategories(newCategories);
  };
  const addNewCategory = () => {
    let newCategories = categories.concat([
      {
        order: getNextOrderInList(categories),
        id: generate_uuid(),
        action: "add",
        name: "",
        label: "",
        description: "",
        bevoegdheid: [],
      },
    ]);
    saveCategories(newCategories);
  };

  let sortedCategories = categories.sort((a, b) => a.order - b.order);
  const items = sortedCategories.map((category, index) => {
    let updated_bevoegdheden = category.bevoegdheid.filter(
      (b) => !!b.action
    ).length;

    return {
      category: category,
      key: index,
      label: (
        <p>
          {category.label || category.name}{" "}
          <DifCheckTag action={category.action} count={updated_bevoegdheden} />{" "}
        </p>
      ),
      extra: (
        <OrderDeleteButtons
          task={category}
          task_index={index}
          tasks={sortedCategories}
          saveTasks={saveCategories}
          copyTask={copyCategory}
        />
      ),
      children: (
        <CategoryCollapse category={category} saveCategory={saveCategory} group_name_lookup={group_name_lookup} />
      ),
    };
  });

  return (
    <Flex vertical={true} gap={12}>
      <Collapse items={items} />
      <Button
        style={{ width: 120 }}
        onClick={addNewCategory}
        size="small"
        icon={<PlusOutlined />}
      >
        {$t("Voeg toe")}{" "}
      </Button>
    </Flex>
  );
};
