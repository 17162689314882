import React, { useEffect } from "react";
import { $t } from "~/i18n.js";

import { StopOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, Modal, Popconfirm, Upload } from "antd";

import { AnnuleerButton } from "~/components/buttons/AnnuleerButton.jsx";
import { SaveButton } from "~/components/buttons/SaveButton.jsx";
import { config } from "../../../config.js";
import { normFileDuringFormUpload } from "../../../lib/helpers.js";

export const BestandenAddModal = ({
  openFile,
  onCancel,
  onSave,
  pendingSave,
  currentUser,
}) => {
  if (!openFile) return null;

  const [form] = Form.useForm();
  useEffect(() => {
    if (openFile) form.resetFields();
  }, [openFile, form]);

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      const data = {
        name: values.name,
        read_only: !currentUser?.is_instructeur ? false : !!values.read_only,
        is_visible: !currentUser?.is_instructeur ? true : !!values.is_visible,
      };

      // add in id if values found, otherwise files
      if (openFile.id !== undefined) {
        data.id = openFile.id;
      } else {
        data.file = values.files[0].originFileObj;
      }
      await onSave(data);
    } catch (info) {
      console.log("Validation failed: ", info);
    }
  };

  return (
    <Modal
      title={openFile.id ? $t("Wijzig bestand") : $t("Voeg bestand toe")}
      open
      confirmLoading={pendingSave}
      onCancel={onCancel}
      footer={[
        <Popconfirm
          key="remove"
          placement="bottom"
          title={$t("Zeker weten dat je dit bestand wilt verwijderen")}
          onConfirm={() => onSave({ id: openFile.id, delete: true })}
          okText={$t("Ja")}
          cancelText={$t("Nee")}
        >
          <Button
            danger
            icon={<StopOutlined />}
            style={{ marginRight: 24 }}
            loading={pendingSave}
          >
            {$t("Verwijder")}
          </Button>
        </Popconfirm>,
        <AnnuleerButton key="annuleren" onClick={onCancel} />,
        <SaveButton key="opslaan" onClick={handleSave} loading={pendingSave} />,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        name="bericht"
        preserve={false}
        requiredMark={false}
        initialValues={{
          name: openFile.name,
          read_only: openFile.read_only,
          is_visible: openFile.is_visible,
        }}
      >
        <Form.Item
          label={$t("Naam")}
          name="name"
          rules={[
            {
              required: true,
              max: 50,
              message: $t("Voeg een naam toe van maximaal 50 characters"),
            },
          ]}
        >
          <Input autoFocus={!openFile.id} />
        </Form.Item>

        {currentUser?.is_instructeur && (
          <Form.Item name="is_visible" valuePropName="checked">
            <Checkbox>{$t("Zichtbaar voor lid")}</Checkbox>
          </Form.Item>
        )}

        {currentUser?.is_instructeur && (
          <Form.Item name="read_only" valuePropName="checked">
            <Checkbox>
              {$t("Alleen lezen voor lid, bewerkbaar voor instructie.")}
            </Checkbox>
          </Form.Item>
        )}

        {openFile.id ? (
          <p>
            {$t("Bestand")}:
            <a
              target="_blank"
              rel="noopener noreferrer"
              title="file"
              href={config.media_prefix + openFile.file}
            >
              Open link
            </a>
          </p>
        ) : (
          <Form.Item
            name="files"
            label={$t("Bestand")}
            valuePropName="fileList"
            getValueFromEvent={normFileDuringFormUpload}
            rules={[
              { required: true, message: $t("Selecteer bestand of annuleer") },
            ]}
          >
            <Upload
              name="file"
              listType="picture"
              multiple={false}
              beforeUpload={(file) => false}
              showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
            >
              <Button icon={<UploadOutlined />}>
                {$t("Selecteer 1 bestand")}
              </Button>
            </Upload>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};
