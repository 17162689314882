/*

Localization and update ant with day.js

*/
import dayjs from 'dayjs'
import { $t } from '~/i18n.js'

/*
Convenience functions
*/

export const toLocale = (number, _default) => {
  // convenient function as if number is undefined it will give an error
  if (number) return number.toLocaleString()
  // otherwise return default
  return _default || '0'
}

export const parseMinutes = (value) => {
  // return minutes to hh:mm, e.g. 180 becomes 3:00 and 514 becomes 6:55
  if (!value) return ''
  let minutes = value % 60
  if (minutes < 10) minutes = '0' + minutes
  let hours = Math.floor(value / 60)
  if (hours < 10) hours = '0' + hours
  return hours + ':' + minutes
}

export const parseMinutesLong = (value) => {
  // return data with uur/minutes
  if (!value) return ''
  if (value >= 60) return Math.floor(value / 60).toLocaleString('nl', { maximumFractionDigits: 0 }) + ' ' + $t('uur') + ' ' + value % 60 + ' ' + $t('min')
  return value + ' ' + $t('min')
}

export const parseHoursLong = (value, roundTo) => {
  // return parsed hours, formatted in local style, without minutes behind it
  if (!value) return ''
  if (value >= 60) return (value / 60).toLocaleString('nl', { maximumFractionDigits: roundTo || 0 }) + ' ' + $t('uur')
  return value + ' ' + $t('min')
}

export const testDateIsToday = (dateString) => {
  if (!dateString) return false
  const compare = (new Date(dateString)).setHours(0, 0, 0, 0)
  const today = (new Date()).setHours(0, 0, 0, 0)
  return compare === today
}

export const toLocaleDate = (date = new Date(), options = { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit' }, _default) => {
  const _options = {
    timeZone: localStorage.timezone || 'Europe/Amsterdam',
    ...options
  }

  if (date) return date.toLocaleDateString(localStorage.locale || 'nl-NL', _options)

  // otherwise return default
  return _default
}

const defaultDateOptions = { year: "2-digit", month: "short", day: "numeric", }
/*
{
  weekday: "long",
  year: "numeric",
  month: "short",
  day: "numeric",
}
*/

export const _parseDate = (string, options = defaultDateOptions) => {
  if (!string) return ''

  // convenience function to localize date string into short format directly
  return toLocaleDate(new Date(string), options)
}

export const getAge = (dateString) => {
  let date = dayjs(dateString)

  // get the age of the person in years and days
  let diff = dayjs().diff(date, 'year', true)
  let years = Math.floor(diff)
  let days = Math.ceil((diff - years) * 365)
  return `${years} ${$t('jaar')}, ${days} ${$t('dagen')}`
}

export const _parseDateTime = (string, options = defaultDateOptions) => {
  // assume ISO format 2022-09-15T09:09:05.977159
  if (!string) return ''
  const date = string.split('T')
  return _parseDate(date[0], options) + ' ' + date[1]?.substr(0, 8)
}

export const _parseTime = (string, options = defaultDateOptions) => {
  // assume ISO format 2022-09-15T09:09:05.977159
  if (!string) return ''
  const time = string.split('T')
  return time[1].substr(0, 5)
}

export const parseDate_YYYYMMDD = (date) => {
  // transform from javascript date to YYYY-MM-DD format in string
  // if empty get date of today
  return (date || new Date()).toJSON().slice(0, 10)
}

export const getToday = () => parseDate_YYYYMMDD()

export const serializePhone = (value) => {
  value = value.toString()

  // always replace all
  return value.replace(/\s/g, '')
}

export const deserializePhone = (value) => {
  if (!value || value === -1) return ''

  return value
}

export const parse_int_to_time = (int) => {
  int = parseInt(int)
  if (isNaN(int)) return ''

  // parse minutes
  let minutes = parseInt(int % 100)
  if (minutes < 10) minutes = `0${minutes}`

  // parse hours
  let hours = parseInt(int / 100)
  if (hours < 10) hours = `0${hours}`

  return `${hours}:${minutes}`
}

export const parse_time_to_int = (time) => {
  if (!time) return null
  const int = parseInt(time.replace(/\D/g, ''))
  return isNaN(int) ? null : int
}

export const convert_m_to_ft = (value) => {
    if (!value) return null
    return (parseFloat(value) * 3.28084).toFixed(3)
}

export const convert_ft_to_m = (value) => {
  if (!value) return null
  return (parseFloat(value) / 3.28084).toFixed(3)
}

/*

parser test for integers. Easier to add in integers accross mobile devices

console.log(' parse_time_to_int:  ==> ');
let values = ['12:02', '08:20', '00:01', null, 'absd'];
values.forEach((value => console.log('   - ', value, parse_time_to_int(value))))

console.log(' parse_int_to_time:  ==> ');
values = [null, 'absd', '', 1, 12, 824, 1202, 1224, 1000];
values.forEach((value => console.log('   - ', value, parse_int_to_time(value))))

 */

export const phValidator = () => ({
  validator (_, name) {
    // if name, check if hyphen inside
    if (!name || name.indexOf('-') < 0 || name?.length > 10) {
      return Promise.reject('Registratie moet format <land>-<nummer> zijn, bijvoorbeeld PH-123')
    }
    return Promise.resolve()
  }
})
