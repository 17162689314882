import { Form, Modal, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { AnnuleerButton } from "~/components/buttons/AnnuleerButton.jsx";
import { SaveButton } from "~/components/buttons/SaveButton.jsx";
import { VerwijderButtonConfirm } from "~/components/buttons/VerwijderButtonConfirm.jsx";
import { $t } from "~/i18n.js";
import { api } from "~/lib/api.js";
import { ClusterChecklist } from "./ClusterChecklist.jsx";
import { ClusterForm } from "./ClusterForm.jsx";

export const ClusterEditModal = ({
  history,
  refreshData = () => {},
  cluster = {},
}) => {
  const [form] = Form.useForm();
  
  const [openModal, setOpenModal] = useState(true);
  const closeModal = () => {
    setOpenModal(false);
    history.replace("/admin/dto_settings");
  };

  const [selectedCluster, setSelectedCluster] = useState({});
  const saveCategories = (newCategories) => {
    setSelectedCluster({ ...selectedCluster, category: newCategories });
  };

  useEffect(() => {
    setOpenModal(true);
    setSelectedCluster(cluster);
    form.setFieldsValue(cluster);
  }, [cluster, form]);

  const [loading, setLoading] = useState(false);
  const saveForm = async () => {
    const values = await form.validateFields();
    setLoading(true);

    const payload = {
      id: cluster.id,
      ...values,
      category: selectedCluster.category,
    };
    const { response, data } = await api._call_method(
      cluster.isNew ? "POST" : "PATCH",
      "admin/dto.json",
      payload
    );

    if (response.ok && data) {
      closeModal();
      refreshData();
    }
    setLoading(false);
  };

  const [pendingDelete, setPendingDelete] = useState(false);
  const deleteCluster = async () => {
    setPendingDelete(true);
    const { response, data } = await api._call_method(
      "DELETE",
      "admin/dto.json",
      { id: cluster.id }
    );

    if (response.ok && data) {
      closeModal();
      refreshData();
    }
    setPendingDelete(false);
  };

  const tabItems = [
    {
      key: "1",
      label: $t("Algemeen"),
      children: (
        <ClusterForm
          form={form}
          clusterData={cluster}
          updateCluster={(c) => {
            setSelectedCluster(c);
            form.setFieldsValue(c);
          }}
        />
      ),
    },
    {
      key: "2",
      label: $t("Inhoud"),
      children: (
        <ClusterChecklist
          categories={selectedCluster?.category || []}
          saveCategories={saveCategories}
        />
      ),
    },
  ];

  return (
    <Modal
      title={cluster?.isNew ? $t("Voeg cluster toe") : $t("Wijzig cluster")}
      width="95%"
      open={openModal}
      onCancel={() => {
        if (!loading) {
          closeModal();
        }
      }}
      footer={[
        <VerwijderButtonConfirm
          key="delete"
          onConfirm={deleteCluster}
          disabled={cluster?.isNew}
          style={{ marginRight: 24 }}
          loading={pendingDelete}
        />,
        <AnnuleerButton
          key="annuleren"
          disabled={loading}
          onClick={closeModal}
        />,
        <SaveButton
          key="opslaan"
          disabled={loading}
          loading={loading}
          onClick={saveForm}
        />,
      ]}
    >
      <Tabs defaultActiveKey="1" items={tabItems} />
    </Modal>
  );
};
