
export const BaseGroups = ['zweefvlieger', 'gastvlieger', 'niet_vliegend'];

export const CheckIfGroupIsBase = (group) => {
    return BaseGroups.includes(group);
}

export const CheckIfUserInBaseGroup = (user) => {
    if (!user) return false
    if (user.has_temporary_password) return true
    return user.group_keys?.some(group => BaseGroups.includes(group));
}

export const SortGroups = (groups) => {
    return groups.sort((a, b) => {
        return `${!CheckIfGroupIsBase(a.key)} ${a.name}`.localeCompare(`${!CheckIfGroupIsBase(b.key)} ${b.name}`);
    });
}