import React from 'react';
import { version } from "../package.json";
import { Loader } from "./components/loader/loader.jsx";
import "./components/pwa/register";
import { config } from "./config.js";
import { i18n } from "./i18n.js";
import { api } from "./lib/api.js";
import { identifyUser } from "./lib/sentry.js";
import { Router } from "./router.jsx";
import { persistor, store } from "./store.jsx";
import i18next from 'i18next';

export const Root = () => {
  const [rehydrated, setRehydrated] = React.useState(false);
  const [languageReady, setLanguageReady] = React.useState(false);

  React.useEffect(() => {
    persistor.subscribe(() => {
      if (!config.tenant) {
        setRehydrated(true);
        return;
      }

      // populate api for future calls
      const state = store.getState();
      api.store = store;
      api.backend_url = config.backend_url;
      const access_token = api.getToken();

      // if logged in, identify user in sentry
      if (access_token && config.sentry_key) {
        identifyUser(config.sentry_key, state?.persist?.profile?.email);
      }

      console.log(`Welkom bij ${config.tenant} - v${version}`);

      // check initial network status, and update if required
      if (state.flights.network_online !== navigator.onLine) {
        store.dispatch({ type: navigator.onLine ? "ONLINE" : "OFFLINE" });
      }
      setRehydrated(true);
    });

    i18n.init().then(() => {
      setLanguageReady(true);
    });

    // Load/ apply clubs custom translation
    const {translation} = store.getState().persist.club
    if (translation) {
      Object.keys(translation).forEach(language => {
        i18next.addResources(language, 'translations', translation[language]);
      })
    }
  }, []);

  return rehydrated && languageReady ? (
    <Router />
  ) : (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loader style={{ margin: "auto" }} />
    </div>
  );
};