import { Tag } from "antd";
import React from "react";
import { $t } from "~/i18n.js";

export const DifCheckTag = ({ action, count = 0 }) => {
  // Make sure that also for no action, an updated bevoegdheid updates the tag
  action =
    count > 0 && action !== "add" && action !== "delete" ? "update" : action;

  if (action === "add") return <Tag color="green">{$t("Nieuw")}</Tag>;
  if (action === "update")
    return (
      <Tag color="blue">
        {count ? count : ""} {$t("Aangepast")}
      </Tag>
    );
  if (action === "delete") return <Tag color="red">{$t("Verwijderd")}</Tag>;

  return null;
};
