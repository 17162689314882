import {
  ClockCircleOutlined,
  CloseOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Button, Input, Modal } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { AnnuleerButton } from "~/components/buttons/AnnuleerButton.jsx";
import { SaveButton } from "~/components/buttons/SaveButton.jsx";
import { $t } from "~/i18n.js";
import { OGNtimeIcon, OGNwarningText } from "./Ogn.jsx";

export const TijdKnopAndEdit = ({
  buttonAction,
  fieldName,
  buttonIcon,
  readOnly,
  currentTime,
  notActive,
  width,
  ogn_indicator,
  saveTime,
  size = "medium",
}) => {
  const [showModal, setShowModal] = useState(false);
  const [loadingAction, setLoadingAction] = useState("");

  const [newTime, setNewTime] = useState(currentTime);
  useEffect(() => setNewTime(currentTime), [currentTime]);

  const now = dayjs().format("HH:mm");

  const triggerSaveTime = async (action, newTime) => {
    setLoadingAction(action);
    if (newTime !== currentTime)
      await saveTime(newTime === "" ? null : newTime);
    resetAndClose();
  };

  const resetAndClose = () => {
    setLoadingAction("");
    setNewTime(null);
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <Modal
          width={340}
          title={$t("Wijzig ${fieldName}", { fieldName })}
          open={showModal}
          onCancel={resetAndClose}
          footer={[
            <SaveButton
              loading={loadingAction === "opslaan"}
              style={{ width: 120 }}
              key="slaan"
              onClick={() => triggerSaveTime("opslaan", newTime)}
            />,
            <AnnuleerButton key="annuleren" onClick={resetAndClose} />,
          ]}
        >
          <div style={{ padding: 12 }}>
            <p style={{ marginBottom: 24 }}>
              <OGNwarningText ogn_indicator={ogn_indicator} />
            </p>

            <div className="row">
              <Button
                loading={loadingAction === "wis"}
                style={{ width: 120 }}
                onClick={() => setNewTime(null)}
                icon={<CloseOutlined />}
              >
                {$t("Wis tijd")}
              </Button>

              <Button
                loading={loadingAction === "nu"}
                style={{ width: 120, marginLeft: 6 }}
                onClick={() => setNewTime(now)}
                icon={<ClockCircleOutlined />}
              >
                {$t("Nu")} ({now})
              </Button>
            </div>

            <p style={{ marginTop: 12 }}>{$t("Wijzig tijd naar")}: </p>

            <div className="row">
              <Input
                type="time"
                allowClear={false}
                style={{
                  width: 120,
                  padding: "2px 4px",
                  marginRight: 6,
                  display: "flex",
                }}
                placeholder="hh:mm"
                value={newTime}
                onChange={(element) => {
                  setNewTime(element.target.value);
                }}
                onPressEnter={() => triggerSaveTime("opslaan", newTime)}
              />
            </div>
          </div>
        </Modal>
      )}

      <div className="row" style={width && { width }}>
        {!currentTime ? (
          <>
            <Button
              size={size}
              type="primary"
              disabled={readOnly || notActive}
              style={width && { width }}
              loading={loadingAction === "landing"}
              onClick={() =>
                triggerSaveTime("landing", dayjs().format("HH:mm"))
              }
              icon={buttonIcon}
            >
              {buttonAction}
            </Button>
          </>
        ) : (
          <>
            <Button
              size={size}
              type="default"
              disabled={readOnly}
              style={width && { width }}
              onClick={() => setShowModal(true)}
              icon={
                <OGNtimeIcon
                  ogn_indicator={ogn_indicator}
                  defaultIcon={buttonIcon}
                />
              }
            >
              {currentTime} <EditOutlined />
            </Button>
          </>
        )}
      </div>
    </>
  );
};
