import React, { useState } from "react";
import { $t } from "~/i18n.js";

import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  Radio,
  Select,
  Upload
} from "antd";
import { AnnuleerButton } from "~/components/buttons/AnnuleerButton.jsx";
import { SaveButton } from "~/components/buttons/SaveButton.jsx";
import { HelpBubble } from "~/components/help/help.bubble.jsx";
import { normFileDuringFormUpload } from "~/lib/helpers.js";

export const DocumentModal = ({
  closeModal,
  document,
  saveDocument,
  chapter,
  allChapters,
}) => {
  const [form] = Form.useForm();
  const [fileOrURL, setFileOrURL] = useState("file");

  React.useEffect(() => {
    if (document) {
      form.setFieldsValue(document);
      setFileOrURL(document.url ? "url" : "file");
    }
  }, [document, form]);

  const [saving, setSaving] = useState(null);

  const deleteDocument = async () => {
    setSaving("delete");
    const result = await saveDocument({ action: "delete", id: document.id });
    result && closeModal();
    setSaving(null);
  };

  const triggerSave = async () => {
    try {
      setSaving("save");
      const values = await form.validateFields();
      const data = {
        name: values.name,
        order: parseInt(values.order),
        chapter_id: values.chapter_id,
        url: values.url || "",
      };
      if (document && document.id) {
        data.id = document.id;
      }
      if (values.files) {
        data.file = values.files[0].originFileObj;
      }

      const result = await saveDocument(data);
      if (result) {
        closeModal();
        form.resetFields();
      }
      setSaving(null);
    } catch (info) {
      console.log("Validate Failed:", info);
      setSaving(null);
    }
  };

  const footer = [
    <AnnuleerButton key="annuleren" onClick={closeModal} />,
    <SaveButton key="ok" onClick={triggerSave} loading={saving === "save"} />,
  ];

  return (
    <Modal
      title={
        chapter && chapter.id
          ? $t("Pas document aan")
          : $t("Voeg nieuw document toe")
      }
      open={!!document}
      onCancel={closeModal}
      okText={$t("Opslaan")}
      cancelText={$t("Annuleren")}
      footer={
        chapter && chapter.id
          ? [
              <Popconfirm
                placement="bottom"
                title={$t(
                  "Zeker weten dat je dit documenten wilt verwijderen?"
                )}
                key="delete"
                onConfirm={deleteDocument}
                okText={$t("Ja")}
                cancelText={$t("Nee")}
              >
                <Button
                  danger
                  style={{ marginRight: 32 }}
                  loading={saving === "delete"}
                >
                  {$t("verwijder")}
                </Button>
              </Popconfirm>,
            ].concat(footer)
          : footer
      }
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        name="hoofstuk"
        preserve={false}
        requiredMark={false}
        initialValues={{ ...document, chapter_id: chapter.id }}
      >
        <Form.Item
          label={$t("Naam")}
          name="name"
          rules={[
            {
              required: true,
              max: 50,
              message: $t("Maximaal ${c} characters", { c: 50 }),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label={$t("Positie")} name="order">
          <Input type="number" prefix="#" />
        </Form.Item>

        <Form.Item label={$t("Hoofdstuk")} name="chapter_id">
          <Select mode="single">
            {allChapters.map((chapter, key) => (
              <Select.Option value={chapter.id} key={key}>
                {chapter.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <div className="row">
          <div style={{ paddingRight: 7, textAlign: "right", width: 160 }}>
            {$t("Bestand of website")}:
          </div>
          <div>
            <Radio.Group
              className="spacer"
              size="small"
              value={fileOrURL}
              onChange={(event) => setFileOrURL(event.target.value)}
            >
              <Radio.Button value="file">{$t("Bestand")}</Radio.Button>
              <Radio.Button value="url">Website</Radio.Button>
            </Radio.Group>
          </div>
        </div>

        {fileOrURL === "file" ? (
          <Form.Item
            name="files"
            label={
              <p>
                {$t("Bestand")}
                <HelpBubble
                  content={$t(
                    "Advies is om een PDF of plaatje te uploaden. Microsoft office bestanden, zoals Word of Excel, openen vaak moeilijker in de browser."
                  )}
                />
              </p>
            }
            valuePropName="fileList"
            getValueFromEvent={normFileDuringFormUpload}
            rules={[
              { required: false, message: $t("Selecteer bestand of annuleer") },
            ]}
          >
            <Upload
              name="file"
              listType="picture"
              multiple={false}
              beforeUpload={(file) => false}
              showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
            >
              <Button icon={<UploadOutlined />}>
                {$t("Selecteer 1 bestand")}
              </Button>
            </Upload>
          </Form.Item>
        ) : (
          <Form.Item
            label={$t("Website")}
            placeholder="https://"
            name="url"
            rules={[{ required: true }, { type: "url" }]}
          >
            <Input />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};
