import React, { useEffect, useState } from "react";
import { $t } from "~/i18n.js";

import { CheckCircleFilled, StopOutlined } from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  Collapse,
  Descriptions,
  Flex,
  Input,
  Tag,
} from "antd";
import { useSelector } from "react-redux";
import PincodeInput from "~/components/form/PincodeInput.jsx";
import { api } from "~/lib/api.js";
import { selectElementByID } from "~/lib/helpers.js";
import { _parseDate } from "../../../lib/localize.js";

export const SignFlightCollapse = ({
  flight = {},
  updateFlight = (e) => {},
}) => {
  return (
    <Collapse
      style={{ marginTop: 12 }}
      items={[
        {
          key: "1",
          label: (
            <Flex justify="space-between">
              <p>{$t("Teken vlucht af")}</p>
              {flight.signed && (
                <Tag color="green" icon={<CheckCircleFilled />}>
                  {" "}
                  {$t("Afgetekend")}
                </Tag>
              )}
            </Flex>
          ),
          children: <SignFlight flight={flight} updateFlight={updateFlight} />,
        },
      ]}
      defaultActiveKey={[]}
    />
  );
};

export const SignFlight = ({ flight = {}, updateFlight = (e) => {} }) => {
  const [revoking, setRevoking] = useState(false);
  const [loading, setLoading] = useState(false);
  const [remark, setRemark] = useState("");
  const [pincode, setPincode] = useState("");

  const [selectKey, setSelectKey] = useState(null);
  const [instructor, setInstructor] = useState(null);
  const setKey = (key) => {

    // value is 1-Boris Schellekens
    setSelectKey(key);

    // as such split, and set the instructor
    const split = key.split("::");
    setInstructor(
      split.length > 1
        ? selectElementByID(vliegers, "id", parseInt(split[0]))
        : null
    );
  };

  // Current user to sign
  const { profile } = useSelector((state) => state.persist);
  useEffect(() => {
    if (profile.is_instructeur) {
      setSelectKey(`${profile.id}::${profile.name}`);
      setInstructor(profile.is_instructeur ? profile : null);
    }
  }, [profile]);

  const [possibleInstructors, setPossibleInstructors] = useState([]);
  const { vliegers } = useSelector((state) => state.flights);
  const searchInstructor = (value = null) => {
    if (value == null) {
      return setPossibleInstructors(vliegers.filter((v) => v.is_instructeur));
    }

    let searchKey = value?.toLowerCase();
    setPossibleInstructors(
      vliegers.filter(
        (v) => v.is_instructeur && v.name.toLowerCase().includes(searchKey)
      )
    );
  };
  useEffect(() => searchInstructor(null), [vliegers]);

  const currentUserIsInstructor = profile?.is_instructeur;

  const revokeSign = async () => {
    setRevoking(true);
    const { data } = await api.delete("flights/revoke_sign.json", {
      uuid: flight.uuid,
    });
    setRevoking(false);
    data && updateFlight({ flight: data });
  };

  const sign = async () => {
    if (!instructor) return;
    setLoading(true);
    const { data } = await api.post2("flights/sign.json", {
      uuid: flight.uuid,
      id: instructor.id,
      remark,
      pincode,
    });
    setLoading(false);
    setPincode("");
    data && updateFlight({ flight: data });
  };

  return (
    <section style={{ padding: 10 }}>
      {flight.signed && (
        <>
          <Descriptions size="small" column={1} layout="horizontal">
            <Descriptions.Item label={$t("Datum")}>
              {_parseDate(flight.signed?.date)}
            </Descriptions.Item>
            {flight.signed?.remark && (
              <Descriptions.Item label={$t("Opmerking")}>
                {flight.signed?.remark}
              </Descriptions.Item>
            )}
            <Descriptions.Item label={$t("instructeur")}>
              {flight.signed?.name} {flight.signed?.brevet && `(${flight.signed?.brevet})`}
            </Descriptions.Item>
          </Descriptions>

          {flight.signed?.id == profile.id && (
            <Button
              size="small"
              loading={revoking}
              danger
              onClick={revokeSign}
              icon={<StopOutlined />}
            >
              {$t("Verwijder")}
            </Button>
          )}
        </>
      )}

      {!flight.signed && (
        <section>
          <Flex gap={6} vertical style={{ marginTop: 12 }}>
            <p className="gray small">
              {$t(
                "Als instructeur kan je een vlucht met je pincode aftekenen, bijvoorbeeld trainingsvluchten of solovluchten"
              )}
            </p>

            <p>{$t("Teken vlucht af als")}</p>

            <AutoComplete
              value={selectKey ? selectKey.split("::")[1] : ""}
              onChange={setKey}
              onSelect={setKey}
              onSearch={searchInstructor}
              options={possibleInstructors.reduce(
                (all, vlieger) =>
                  all.concat({
                    label: <p>{vlieger.name}</p>,
                    value: vlieger.id + "::" + vlieger.name,
                  }),
                []
              )}
              notFoundContent={<p className="center">{$t("Niet gevonden")}</p>}
            >
              <Input />
            </AutoComplete>

            <p>{$t("Opmerking")}</p>
            <Input.TextArea
              disabled={!instructor}
              value={remark}
              placeholder={profile.is_instructeur && $t("Eventuele opmerking")}
              onChange={(e) => setRemark(e.target.value)}
            />
            <p>{$t("Pincode")}:</p>
            <PincodeInput
              disabled={!instructor}
              value={pincode}
              onComplete={setPincode}
            />
          </Flex>

          <Flex gap={12} style={{ marginTop: 12 }}>
            <Button
              loading={loading}
              onClick={sign}
              disabled={!pincode}
              type="primary"
            >
              {$t("Teken af")}
            </Button>
          </Flex>
        </section>
      )}
    </section>
  );
};
