import {
  EyeInvisibleOutlined,
  EyeOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import { Button, Flex } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { $t } from "~/i18n.js";
import { api } from "../../lib/api";
import { updateFlight } from "../../redux/flights/flights.actions";
import { HelpBubble } from "../help/help.bubble";
import { IGCModal } from "./IGC.modal";

export const IGCButton = ({ flight, title, onUpdateFlight, skipInfo, size = 'small' }) => {
  const [showIGC, setShowIGC] = useState(false);
  const [visibleFlight, setVisibleFlight] = useState(false);
  const [loadingVisible, setLoadingVisible] = useState(false);
  useEffect(() => {
    setLoadingVisible(false);
    setVisibleFlight(flight);
  }, [flight]);

  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.persist);
  const turnOnIGCvisibility =
    !visibleFlight.igc_visible &&
    (profile.id === flight.gezagvoerder_id ||
      profile.id === flight.tweede_inzittende_id);
  const setVisible = async () => {
    setLoadingVisible(true);
    const flightData = await dispatch(
      updateFlight({ uuid: flight.uuid, set_igc_visible: true })
    );
    flightData && setVisibleFlight(flightData);
    setLoadingVisible(false);
    onUpdateFlight && onUpdateFlight({values: flight.datum.substr(0, 4)});
  };

  const getOverlappingFlights = async () => {
    const payload = {
      start_tijd: flight.start_tijd,
      landings_tijd: flight.landings_tijd,
      datum: flight.datum,
    };
    const data = await api.post("flights/overlapping_flights.json", payload);
    return data?.flights;
  };

  return (
    <div className="column" style={{ alignItems: "flex-start" }}>
      {showIGC && (
        <IGCModal
          getOverlappingFlights={getOverlappingFlights}
          flight={showIGC}
          visible={!!showIGC}
          onClose={() => setShowIGC(null)}
        />
      )}

      {visibleFlight.igc ? (
        <Flex gap={5}>
          <Button
            size={size}
            type={title ? "default" : "link"}
            shape={title ? "default" : "circle"}
            onClick={() => setShowIGC(visibleFlight)}
            icon={<GlobalOutlined style={{ color: "#398FE2" }} />}
          >
            {title}
          </Button>

        </Flex>
      ) : visibleFlight.igc_visible ? (
        "-"
      ) : (
        <p>
          <EyeInvisibleOutlined style={{ marginRight: 6 }} />
          {!skipInfo && (
            <HelpBubble
              title={$t("Kaart zichtbaarheid")}
              content={$t(
                "Kaart zichtbaarheid staat voor deze vlieger standard uit. Je kan in dit geval alleen vluchten die zijn opengezet bekijken."
              )}
            />
          )}
        </p>
      )}

      {turnOnIGCvisibility && (
        <p>
          {$t("Zet kaart zichtbaar")}:{' '}
          <Button
            size="small"
            style={{ marginLeft: 6 }}
            loading={loadingVisible}
            onClick={setVisible}
            icon={<EyeOutlined />}
          >
            {$t("zichtbaar")}
          </Button>
        </p>
      )}
    </div>
  );
};
