import React, { useEffect, useState } from "react";
import { $t } from "~/i18n.js";
import { Divider } from "antd";
import { BevoegdheidScore } from "./BevoegdheidScore.jsx";
import { _parseDate } from "~/lib/localize.js";
import { api } from "~/lib/api.js";

export const BevoegdheidHistory = ({
  behaalde_bevoegdheid_id,
  currentBevoegdheid,
}) => {
  if (!behaalde_bevoegdheid_id || !currentBevoegdheid.score) return null;

  const [records, setRecords] = useState(null);

  useEffect(() => {
    let isMounted = true;
    if (behaalde_bevoegdheid_id) {
      api
        .get(`bevoegdheid/${behaalde_bevoegdheid_id}/bevoegdheid_history.json`)
        .then((res) => {
          if (isMounted) setRecords(res);
        });
    }
    return () => {
      isMounted = false;
    };
  }, [behaalde_bevoegdheid_id]);

  if (!records) return null;
  return (
    <div style={{ marginTop: 48 }}>
      <Divider orientation="left" plain>
        {$t("Historie")}
      </Divider>

      <div className="flightChanges">
        <table style={{ width: "100%" }}>
          <tbody>
            {currentBevoegdheid && (
              <tr>
                <td>
                  {_parseDate(currentBevoegdheid.date_assigned, {
                    month: "2-digit",
                    day: "2-digit",
                  })}
                </td>
                <td>
                  <BevoegdheidScore score={currentBevoegdheid.score} />
                </td>
                <td className="truncate">
                  {currentBevoegdheid.instructeur_naam}
                </td>
                <td className="truncate">{currentBevoegdheid.remark} {currentBevoegdheid.date_geldig && <span className="gray">
                   {$t('tot')} {_parseDate(currentBevoegdheid.date_geldig, {
                    month: "2-digit",
                    day: "2-digit",
                    year: '2-digit'
                  })}</span>}
                </td>
                
              </tr>
            )}

            {records?.map((record, key) => {
              return (
                <tr key={key}>
                  <td>
                    {_parseDate(record.date_assigned, {
                      month: "2-digit",
                      day: "2-digit",
                    })}
                  </td>
                  <td>
                    <BevoegdheidScore score={record.score} />
                  </td>
                  <td className="truncate">{record.instructeur_naam}</td>
                  <td className="truncate">{record.remark} {record.date_geldig && <span className="gray">
                   {$t('tot')} {_parseDate(record.date_geldig, {
                    month: "2-digit",
                    day: "2-digit",
                    year: '2-digit'
                  })}</span>}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
