import { Form, Input, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CountryCodeSelect } from "~/components/form/CountryCodeSelect.jsx";
import { LengthValidator } from "~/components/form/formHelpers.js";
import { InputFileSingle } from "~/components/form/InputFileSingle.jsx";
import { CardBase } from "~/components/timeLine/cardBase.jsx";
import { $t } from "~/i18n.js";
import { selectElementByID, updateIfNeeded } from "~/lib/helpers.js";
import { getAllClubs } from "~/redux/persist/persist.actions.js";

const knownClub = (name) => {
  Modal.error({
    content: (
      <div>
        <p>
          <span className="bold">{name}</span> is al bekend
        </p>
        <p className="small" style={{ marginTop: 18 }}>
          Voortaan mag je naar https://
          <span className="bold">{name.toLowerCase()}</span>.zweef.app gaan om
          in te loggen.
        </p>
        <p className="small" style={{ marginTop: 18 }}>
          Druk op OK om door te gaan.
        </p>
      </div>
    ),
    onOk: () =>
      window.location.assign(`https://${name.trim().toLowerCase()}.zweef.app`),
  });
};

export const CardClub = ({ data, saveAndNext, prev }) => {
  const [form] = Form.useForm();
  const [afkorting, setAfkorting] = useState("");

  // extract all clubs, and make sure that the club is not already created
  const { all_clubs, lastUpdatedClubs } = useSelector((state) => state.persist);
  const dispatch = useDispatch();

  useEffect(() => {
    updateIfNeeded(lastUpdatedClubs, () => dispatch(getAllClubs()));
  }, [lastUpdatedClubs, dispatch, getAllClubs]);

  const handleSave = async () => {
    try {
      const values = await form.validateFields();

      if (all_clubs && all_clubs.length > 0 && values.name) {
        if (
          selectElementByID(
            all_clubs,
            "schema_name",
            values.name.trim().toLowerCase()
          )
        ) {
          knownClub(values.name);
        } else {
          saveAndNext(values);
        }
      }
    } catch (info) {
      console.log("Validation failed: ", info);
    }
  };

  return (
    <CardBase prev={prev} next={handleSave} minHeight={300}>
      <Form
        style={{ maxWidth: 400, margin: "0 auto" }}
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        name="club"
        preserve={false}
        requiredMark={false}
        initialValues={data}
      >
        <p style={{ marginBottom: 24 }}>
          {$t('Dit zijn de basisgegevens nodig om op te starten')}. {$t('De volledige configuratie kan je later doorvoeren')}.
        </p>

        <Form.Item
          label={$t("Club naam")}
          autoFocus
          name="full_name"
          rules={[{ required: true, message: $t("Voeg een naam toe") }]}
        >
          <Input placeholder={$t("volledig uitgeschreven")} />
        </Form.Item>

        <Form.Item label={$t("Afkorting")}>
          <div className="row" style={{ alignItems: "flex-start" }}>
            <Form.Item
              name="name"
              rules={[
                () => ({
                  validator(rule, value) {
                    if (!value || value.length < 2) {
                      return Promise.reject(
                        $t("Minimaal ${c} letters", { c: 2 })
                      );
                    } else if (value.length > 15) {
                      return Promise.reject(
                        $t("Maximaal ${c} letters", { c: 15 })
                      );
                    } else if (value === "www") {
                      return Promise.reject($t("Mag niet www zijn"));
                    }
                    // OK
                    setAfkorting(value);
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>
            {afkorting && (
              <div style={{ margin: "auto 12px", height: 32 }}>
                <p>
                  <span className="bold">{afkorting.toLowerCase()}</span>.
                  {window.location.hostname.replace("www.", "")}
                </p>
              </div>
            )}
          </div>
        </Form.Item>

        <Form.Item
          name="logo"
          label={$t("Logo")}
          extra={$t("Max ${size} bij ${size}px", { size: 200 })}
          rules={[{ required: true }]}
        >
          <InputFileSingle accept="image/png, image/jpeg" />
        </Form.Item>

        <Form.Item
          label={$t("ISO landcode")}
          name="country"
          rules={[LengthValidator(2, true)]}
        >
          <CountryCodeSelect />
        </Form.Item>

        <Form.Item label={$t("Timezone")} name="timezone">
          <Select>
            {[
              "Europe/Amsterdam",
              "Europe/London",
              "US/Central",
              "US/Eastern",
              "US/Pacific",
              "UTC",
            ].map((tz) => (
              <Select.Option key={tz} value={tz}>
                {tz}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </CardBase>
  );
};
