import { StopOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { $t } from "~/i18n.js";

export const VerwijderButton = (args) => {
  return (
    <Button danger icon={<StopOutlined />} {...args}>
      {$t("Verwijder")}
    </Button>
  );
};
