import React, { useState } from "react";


export const ReadMoreExpander = ({ text, maxLength = 80 }) => {
  if (!text) return "";
  const [showAll, setShowAll] = useState(false);

  return (
    <p
      className="clickable"
      onClick={(e) => {
        e.preventDefault();
        setShowAll(!showAll);
      }}
    >
      <span className={`read-more-content ${showAll ? "expanded" : ""}`}>
        {showAll ? text : text.substr(0, maxLength)} {!showAll && text.length > maxLength && <span className="bold">...</span>}
      </span> 
    </p>
  );
};