import { Form, Input, Select, Switch } from 'antd'
import { $t } from '~/i18n.js'

export function FlightLocationFields({ vliegend, atThuisveld, setAtThuisVeld, vliegveld }) {
  return (
    <>
      {vliegend && (
        <div>
          <Form.Item
            label={$t('Thuisveld (${vliegveld})', {
              vliegveld: vliegveld || ' - '
            })}
          >
            <Switch
              onChange={(checked) => setAtThuisVeld(checked)}
              checked={atThuisveld}
              checkedChildren={$t('Ja')}
              unCheckedChildren={$t('Nee')}
            />
          </Form.Item>

          {!atThuisveld && (
            <div>
              <Form.Item label={$t('Locatie')} name='locatie'>
                <Input placeholder={$t('voeg de ICAO code toe.')} />
              </Form.Item>
            </div>
          )}
        </div>
      )}

      {!vliegend && (
        <Form.Item label={$t('Locatie')} name='locatie'>
          <Input />
        </Form.Item>
      )}

      {vliegend && (
        <Form.Item label={$t('Start methode')} name='start_methode'>
          <Select>
            {['lier', 'sleep', 'zelf', 'tmg'].map((start, idx) => (
              <Select.Option key={idx} value={start}>
                {$t(start)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
    </>
  )
}
