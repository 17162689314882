import { Input, Select, Table } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { defaultTableConfig } from "~/components/layout/table";
import { $t } from '~/i18n.js'
import { toLocaleDate } from '../../../lib/localize'
import { RenderAvailibility, SocialSelect } from './socialSelect'

export const Diensten = ({ profile, data, group_name_lookup, setRooster, editRooster, saveAvailability }) => {
  // create columns, based on each roster

  // if data.date_to is in future, then add default filter to show only future dates
  const defaultFilteredValue = data?.date_to && new Date(data.date_to) > new Date() ? ['future'] : []

  const columns = [
    {
      title: $t('Datum'),
      dataIndex: 'datum',
      defaultFilteredValue: defaultFilteredValue,
      filters: [{ text: $t('Alle dagen'), value: 'all' }, { text: $t('Toekomst'), value: 'future' }],
      onFilter: (value, day) => value === 'future' ? new Date(day.datum) >= new Date() : true,
      render: (value, day) =>
        <Link to={`/aanmelden/${day.dag_id}`} className='dag'>
          <p style={{ width: 60 }}>{toLocaleDate(new Date(value), { weekday: 'short', month: '2-digit', day: '2-digit' })}</p>
        </Link>
    }
  ]

  let n_social_open = 0

  data && data.roosters && data.roosters.forEach((_rooster) => {
    if (saveAvailability) {
      // skip if the rooster is non-social and we're in open for registration phase
      if (data.status === 'open_registration' && _rooster.autofill !== 'social') return

      // skip if status is open but user has not a group to edit:
      if (!editRooster && profile.group_keys?.indexOf(_rooster.group_key) < 0) return
      n_social_open += 1
    }

    columns.push({
      title: <p>{_rooster.description} (
        {group_name_lookup[_rooster.group_key] || _rooster.group_key}
        {editRooster && ' / ' + $t({ blank: 'Leeg', autofill: 'Auto', social: 'Sociaal' }[_rooster.autofill])}
        )
             </p>,
      render: (_, day) => {
        if (day.dienst_lookup[_rooster.id]) {
          const dienst = day.dienst_lookup[_rooster.id]
          if (!editRooster && !saveAvailability) return <p className={!dienst.user_name ? 'bold alert' : ''}>{dienst.user_name ? dienst.user_name : 'OPEN'}</p>

          if (!editRooster && saveAvailability) {
            return (
              <SocialSelect
                data={dienst} saveAvailability={(availability) => {
                  // save availability for full date
                  const newData = { ...data, saveAvailability: data.saveAvailability + 1 }
                  dienst.availability = dienst.availability === availability ? 'empty' : availability
                  dienst.saveAvailability = true
                  setRooster(newData)
                }}
              />
            )
          }

          return (
            <Select
              showSearch
              value={dienst.user_id}
              style={{ width: '100%' }}
              filterOption={(input, option) => {
                return option && option.children && option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
              onChange={(newUserID) => {
                const newData = { ...data, canSave: data.canSave + 1 }

                // remove prev user and add count to new user
                if (dienst.user_id) data.user_count[dienst.user_id] += -1
                if (newUserID) {
                  if (!data.user_count[newUserID]) data.user_count[newUserID] = 0
                  data.user_count[newUserID] += 1
                }

                // add to shift
                dienst.user_id = newUserID
                dienst.must_save = true
                setRooster(newData)
              }}
            >
              {_rooster.users && _rooster.users.map((user, key) => {
                const count = data.user_count[user.id]
                const preference = dienst.preference_lookup[user.id]
                return (
                  <Select.Option className='row' style={{ flexWrap: 'wrap' }} key={key} value={user.id} name={user.name}>
                    {user.name}
                    {preference && <span style={{ marginLeft: 6 }}><RenderAvailibility availability={preference.availability} /></span>}
                    {count && <span style={{ marginLeft: 6 }} className='gray'>({count})</span>}
                    {preference?.remark && <span className='small' style={{ marginLeft: 6, fontStyle: 'italic' }}>{preference.remark}</span>}
                  </Select.Option>
                )
              }
              )}
            </Select>
          )
        }
      }

    })
  })

  // For social venture, you can setup a general preference and remark for that day
  if (n_social_open > 0 && saveAvailability && data.status === 'open_registration') {
    // let defaultRemark =

    columns.push({
      title: $t('Dag voorkeur'),
      render: (_, day) => {
        return (
          <div className='row' style={{ gap: 4 }}>
            {data.roosters?.length > 1 && <SocialSelect
              data={null} saveAvailability={(availability) => {
                // save availability for all social roosters on that day
                const newData = { ...data, saveAvailability: data.saveAvailability + 1 }
                newData.roosters.forEach(r => {
                  if (r.autofill === 'social') {
                    day.dienst_lookup[r.id].availability = availability
                    day.dienst_lookup[r.id].saveAvailability = true
                  }
                })
                setRooster(newData)
              }}
                                          />}
            <Input
              placeholder={$t('opmerking')}
              defaultValue={day.remark} onBlur={event => {
                const newData = { ...data, saveAvailability: data.saveAvailability + 1 }
                newData.roosters.forEach(r => {
                  if (r.autofill === 'social') {
                    day.dienst_lookup[r.id].remark = event.target.value.substring(0, 40)
                    day.dienst_lookup[r.id].saveAvailability = true
                  }
                })
                setRooster(newData)
              }}
            />
          </div>
        )
      }
    })
  }

  return (
    <Table
      dataSource={data.days ? data.days : []}
      rowKey='dag_id'
      style={{ width: '100%', overflowX: 'auto', marginTop: 24 }}
      pagination={defaultTableConfig}
      size='small'
      columns={columns}
    />
  )
}
