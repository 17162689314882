import { ConfigProvider, theme } from "antd";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { Root } from "./Root.jsx";

// Localize ant description, and update first day of week for datePicker
import de from "antd/es/locale/de_DE.js";
import en from "antd/es/locale/en_GB.js";
import fr from "antd/es/locale/fr_FR.js";
import nl from "antd/es/locale/nl_NL.js";
import "./components/pwa/register";

const getAntLocal = () => {
  const lng = localStorage.language;
  if (lng === "en") return en;
  if (lng === "de") return de;
  if (lng === "fr") return fr;
  // default nl or nl-be
  return nl;
};

// extend Dayjs with relativeTimePlugin
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime.js"; 
dayjs.extend(relativeTime); 

import day_en from 'dayjs/locale/en';
import day_de from 'dayjs/locale/de';
import day_fr from 'dayjs/locale/fr';
import day_nl from 'dayjs/locale/nl';

const setDayJSlocale = () => {
  const lng = localStorage.language;
  if (lng === "en") return dayjs.locale('en');
  if (lng === "de") return dayjs.locale('de');
  if (lng === "fr") return dayjs.locale('fr');
  return dayjs.locale('nl');
};

// Empty state props a
export function App({ store }) {
  const initialValue = localStorage.getItem("theme") || "light";

  useEffect(() => {
    setDayJSlocale();
  });

  return (
    <Provider store={store}>
      <ConfigProvider locale={getAntLocal()}>
        <Root />
      </ConfigProvider>
    </Provider>
  );
}
