import { PlusOutlined } from '@ant-design/icons'
import { Button, Divider, Form, Input, Select } from 'antd'
import { $t } from '~/i18n.js'

export function SelectTypeDag({ setItem, setName, name, items }) {
  return (
    <Form.Item
      label={$t('Soort dag')}
      name='type_dag'
      rules={[{ required: true, message: $t('Voeg een dag soort toe!') }]}
    >
      <Select
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: '4px 0' }} />
            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
              <Input
                style={{ flex: 'auto' }}
                value={name}
                onChange={(event) =>
                  setName(
                    event.target.value.replace(/^\w/, (c) => c.toUpperCase())
                  )}
              />
              <Button
                type='primary'
                style={{ marginLeft: 4 }}
                onClick={() => {
                  if (name.length > 0) {
                    setItem(items.concat(name))
                    setName('')
                  }
                }}
              >
                <PlusOutlined /> {$t('Andere')}
              </Button>
            </div>
          </div>
        )}
      >
        {items &&
          items.length > 0 &&
          items.map((type, key) => (
            <Select.Option key={key} value={type}>
              {type}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  )
}
