import { PlusOutlined, StopOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { $t } from "~/i18n.js";

import { AnnuleerButton } from "~/components/buttons/AnnuleerButton.jsx";
import { SaveButton } from "~/components/buttons/SaveButton.jsx";
import { api } from "~/lib/api.js";
import {
  normFileDuringFormUpload,
  ruleCheckImageMaxSize,
} from "~/lib/helpers.js";
import { saveMaterialTab } from "~/redux/materiaal/materiaal.actions.js";

const CustomDocumentTag = ({ setTag, tag, setTags, tags }) => (
  <Form.Item
    label={$t("Tag")}
    name="tag"
    rules={[{ required: true, message: $t("Voeg type document toe") }]}
  >
    <Select
      dropdownRender={(menu) => (
        <div>
          {menu}
          <Divider style={{ margin: "4px 0" }} />
          <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={tag}
              onChange={(event) =>
                setTag(
                  event.target.value.replace(/^\w/, (c) => c.toUpperCase())
                )
              }
            />
            <Button
              type="primary"
              style={{ marginLeft: 4 }}
              onClick={() => {
                if (tag.length > 0) {
                  setTags(tags.concat(tag));
                  setTag("");
                }
              }}
            >
              <PlusOutlined /> {$t("Andere")}
            </Button>
          </div>
        </div>
      )}
    >
      {tags &&
        tags.length > 0 &&
        tags.map((type, key) => (
          <Select.Option key={key} value={type}>
            {$t(type)}
          </Select.Option>
        ))}
    </Select>
  </Form.Item>
);

export const AddDocumentModal = ({
  document,
  currentMaterial,
  closeModal,
  part_id,
  workorder_id,
  activeTab,
  defaultTag,
  existingTags,
}) => {
  const [tag, setTag] = useState(defaultTag || "");
  const [tags, setTags] = useState([
    "algemeen",
    "boorddocument",
    "part",
    "workorder",
  ]);
  // $t('algemeen')
  // $t('boorddocument')
  // $t('part')
  // $t('workorder')

  useEffect(() => {
    existingTags &&
      setTags(
        existingTags
          .concat(["algemeen", "boorddocument", "part", "workorder"])
          .filter((value, index, array) => array.indexOf(value) === index)
      );
  }, [existingTags]);

  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
  }, [document]);

  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.materiaal?.currentMaterial);

  const [saving, setSaving] = useState(false);
  const saveForm = async () => {
    try {
      setSaving(true);
      const values = await form.validateFields();
      const payload = {
        tab: "save_document",
        id: document?.id,
        name: values.name,
        version: values.version,
        order: values.order,
        tag: values.tag,
        part_id,
        workorder_id,
        url_valid: true,
      };
      const newFiles = values.newFiles;
      const links = values.links;

      // save document
      const res = await dispatch(
        saveMaterialTab({
          activeTab: activeTab || "documents",
          materialID: currentMaterial.id,
          payload,
        })
      );

      if (res?.data) {
        // save all files one by one
        const document_id = res.data?.saved_document_id || document?.id;
        const addedFiles = [];

        newFiles &&
          (await Promise.all(
            newFiles?.map(async (file) => {
              const res = await api.post_form(
                "materiaal/save_document_file.json",
                { document_id, file: file.originFileObj }
              );
              addedFiles.push({ name: file.name, file: res?.file });
            })
          ));
        links &&
          (await Promise.all(
            links?.map(async (url) => {
              await api.post_form("materiaal/save_document_file.json", {
                document_id,
                url,
              });
              addedFiles.push({ url });
            })
          ));

        // get all data back
        const mat = await api.get(`materiaal/${id}/tab_documents.json`);
        mat &&
          dispatch({
            type: "MATERIAAL_SET_TAB",
            obj_id: id,
            material: mat.material,
            activeTab: "documents",
            tab_data: mat.data,
          });

        closeModal();
      }
      setSaving(false);
    } catch (info) {
      console.log("Validate Failed:", info);
      setSaving(false);
    }
  };

  const [deleting, setDeleting] = useState(false);
  const deleteDocument = async () => {
    setDeleting(true);
    const payload = { id: document.id, action: "delete" };
    const res = dispatch(
      saveMaterialTab({
        activeTab: "documents",
        materialID: currentMaterial.id,
        payload,
      })
    );
    if (res) closeModal();
    setDeleting(false);
  };

  return (
    <Modal
      title={document?.id ? $t("Wijzig document") : $t("Voeg document toe")}
      confirmLoading={saving}
      open={!!document}
      onCancel={closeModal}
      footer={[
        document.id && (
          <Popconfirm
            key="remove"
            placement="bottom"
            title={
              document.is_archived
                ? $t("Zeker weten dat je dit document wilt verwijderen?")
                : $t("Zeker weten dat je dit document wilt archiveren?")
            }
            onConfirm={deleteDocument}
            okText={$t("Ja")}
            cancelText={$t("Nee")}
          >
            <Button danger icon={<StopOutlined />} loading={deleting}>
              {document.is_archived ? $t("Verwijder") : $t("Archiveer")}
            </Button>
          </Popconfirm>
        ),

        <AnnuleerButton key="annuleren" onClick={closeModal} />,
        <SaveButton key="opslaan" onClick={saveForm} loading={saving} />,
      ]}
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        name="document"
        preserve={false}
        requiredMark={false}
        initialValues={{
          version: "",
          order: 0,
          newFiles: [],
          newLinks: [],
          tag: defaultTag || "",
          ...document,
        }}
      >
        <Form.Item
          label={$t("Naam")}
          name="name"
          rules={[
            {
              required: true,
              max: 150,
              message: $t("Maximaal ${c} characters", { c: 150 }),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={$t("Versie")}
          name="version"
          rules={[
            {
              required: false,
              max: 150,
              message: $t("Maximaal ${c} characters", { c: 150 }),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label={$t("Positie")} name="order">
          <Input type="number" prefix="#" />
        </Form.Item>

        <CustomDocumentTag
          setTag={setTag}
          tag={tag}
          setTags={setTags}
          tags={tags}
        />

        <Form.Item
          name="newFiles"
          label={$t("Voeg bestand(en) toe")}
          valuePropName="fileList"
          getValueFromEvent={normFileDuringFormUpload}
          rules={[ruleCheckImageMaxSize]}
        >
          <Upload
            name="file"
            listType="picture"
            multiple
            beforeUpload={(file) => false}
            showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
          >
            <Button icon={<UploadOutlined />}>
              {$t("Selecteer 1 of meerdere bestanden")}
            </Button>
          </Upload>
        </Form.Item>

        <div>
          <Form.Item
            name="links"
            label={$t("Voeg website(s) toe")}
            rules={[
              { required: false },
              () => ({
                validator(_, values) {
                  if (!values || !values?.length) return Promise.resolve();

                  // existing group or correct email should validate properly
                  const incorrectValues = [];
                  values.map((v) => {
                    if (!v.startsWith("http")) {
                      incorrectValues.push(v);
                    }
                  });
                  if (incorrectValues.length > 0) {
                    return Promise.reject(
                      `"${incorrectValues.join(", ")}" ${$t(
                        "is incorrect, voeg een geldige URL toe dat begint met http."
                      )} `
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Select mode="tags" size="small" />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};
