import { PlusOutlined } from '@ant-design/icons'
import { Button, Space, Tag, Tooltip } from 'antd'
import React from 'react'
import { i18n } from '~/i18n.js'
import { getUserAvatar, getUserAvatarError } from '~/components/profile/selectPilot.jsx'
import { ReadMore } from '~/components/readmore/readMore.jsx'
import { FlightTagColumn } from "~/components/flight/sections/FlightTags.jsx";
import {
  OGNlandingsTijd,
  OGNstartTijd,
} from "~/components/flight/sections/Ogn.jsx";
import { $t } from '~/i18n.js'
import { parseMinutes } from '~/lib/localize.js'

export const SortableFlightTable = () => ([
  {
    title: '#',
    dataIndex: 'volg_nummer',
    sorter: (a, b) => a.volg_nummer - b.volg_nummer
  },

  { title: $t('Kist'), render: (_, flight) => <p>{flight.callsign} {flight.registratie} <br />{flight.type}</p> },
  {
    title: $t('Gezagvoerder'),
    dataIndex: 'gezagvoerder_naam',
    sorter: (a, b) => ('' + a.gezagvoerder_naam).localeCompare(b.gezagvoerder_naam)
  },

  {
    title: $t('Pax/DBO'),
    dataIndex: 'tweede_inzittende_naam',
    sorter: (a, b) => ('' + a.tweede_inzittende_naam).localeCompare(b.tweede_inzittende_naam)
  },

  { title: $t('Meth.'), dataIndex: 'start_methode', render: r => $t(r) },

  {
    title: $t('Tijden'),
    width: 125,
    render: (_, flight) => <div className='row'>
      {flight.start_tijd && <OGNstartTijd ogn_indicator={flight.start_ogn} time={flight.start_tijd} />}
      {flight.landings_tijd && <OGNlandingsTijd ogn_indicator={flight.landings_ogn} time={flight.landings_tijd} />}
    </div>
  },

  {
    title: $t('Duur'),
    dataIndex: 'vluchtduur',
    sorter: (a, b) => a.vluchtduur - b.vluchtduur
  },

  { title: $t('Hoogte'), dataIndex: 'height', width: 55, 
    render: (value) => i18n.parseLocaleHeightString(value)
    },

  FlightTagColumn()

])

export const sortableVliegerTable = (vliegerData, data, addVliegerNotitie, setOpenPilot) => {
  return [
    {
      title: $t('Vlieger'),
      dataIndex: 'name',
      sorter: (a, b) => ('' + a.lowercase).localeCompare(b.lowercase),
      render: (_, vlieger) => (<div className='row clickable' onClick={() => setOpenPilot(vlieger)}>

        <img
          className='avatarLedenLijst' alt=''
          src={getUserAvatar(vlieger, true)}
          onError={getUserAvatarError}
        />

        <div className='column' style={{ marginLeft: 12, alignItems: 'flex-start' }}>
          <p className='bold'>{vlieger.name}</p>
          {data.instructeurs.includes(vlieger.name) && <Tag color='gold'>{$t('instructeur')}</Tag>}
        </div>

      </div>)
    },
    {
      title: $t('Starts'),
      render: (text, vlieger) => vliegerData[vlieger.id]?.starts
    },
    {
      title: $t('Vluchtduur'),
      render: (text, vlieger) => parseMinutes(vliegerData[vlieger.id]?.vluchtduur)
    },
    {
      title: $t('Tags'),
      filters: [
        { text: $t('dbo'), value: 'dbo' },
        { text: $t('training'), value: 'training' },
        { text: $t('examen'), value: 'examen' },
        { text: $t('overland'), value: 'overland' }],
      onFilter: (value, record) => {
        const v = vliegerData[record.id]
        if (value === 'training') return v.has_check
        if (value === 'examen') return v.has_examen
        if (value === 'overland') return v.has_overland
        return false
      },
      render: (text, vlieger) => {
        const v = vliegerData[vlieger.id]
        if (!v) return '-'
        return (
          <>
            {v.has_dbo && <Tooltip placement='top' title={$t('Heeft DBO meegevlogen met instructeur')}><Tag color='gold'>{$t('dbo')}</Tag></Tooltip>}
            {v.has_check && <Tooltip placement='top' title={$t('Jaarlijkse EASA trainingsvlucht met instructeur.')}><Tag>{$t('trainings vlucht')}</Tag></Tooltip>}
            {v.has_examen && <Tooltip placement='top' title={$t('Examen vlucht of EASA prof-check')}><Tag color='geekblue'>{$t('examen')}</Tag></Tooltip>}
            {v.has_overland && <Tooltip placement='top' title={$t('Overland vlucht')}><Tag color='green'>{v.afstand > 0 ? (v.afstand + 'km') : $t('Ovl')}</Tag></Tooltip>}
            {v.is_deleted && <Tag color='red'>{$t('verwijderd')}</Tag>}
          </>
        )
      }
    },
    {
      title: $t('Kisten'),
      render: (text, vlieger) => {
        const v = vliegerData[vlieger.id]
        return (
          <>
            {v?.kist_types.map((kist, key) => <Tag key={key}>{kist}</Tag>)}
          </>
        )
      }
    },
    {
      title: $t('Instructeurs'),
      render: (text, vlieger) => {
        const v = vliegerData[vlieger.id]
        return (
          <>
            {v && v.instructeurs && v.instructeurs.map((inst, key) => <Tag key={key}>{inst}</Tag>)}
          </>
        )
      }
    },
    {
      title: $t('Laatste notitie'),
      render: (text, vlieger, index) => {
        const v = vliegerData[vlieger.id]
        return (
          <>
            {v && v.notities && v.notities.length > 0 && (
              <Tooltip
                placement="top"
                title={
                  <p>
                    {v.notities[0].instructeur_naam} - {v.notities[0].datum}
                  </p>
                }
              >
                <ReadMore text={v.notities[0].bericht} maxLength={40} />
              </Tooltip>
            )}
          </>
        );
      }
    },
    {
      render: (text, vlieger, index) => <Space>
        <Button
          onClick={() => addVliegerNotitie(vlieger)}
          type='primary' icon={<PlusOutlined />} size='small' shape='circle'
        />
      </Space>
    }
  ]
}

const Vlieger = () => ({
  title: $t('Vlieger'),
  dataIndex: 'name',
  sorter: (a, b) => ('' + a.vlieger.name).localeCompare(b.vlieger.name),
  render: (text, d, index) => <div className='row clickable'>

    <img
      className='avatarLedenLijst' alt=''
      src={getUserAvatar(d.vlieger, true)}
      onError={getUserAvatarError}
    />

    <div className='column' style={{ marginLeft: 12, alignItems: 'flex-start' }}>
      <p className='bold'>{d.vlieger.name}</p>
      {(d.as_instructeur) && <Tag color='gold'>{$t('instructeur')}</Tag>}
    </div>

  </div>
})

export const sortableAanmeldTableColumns = () => ([
  Vlieger(),
  {
    title: $t('Kisten'),
    render: (text, v, index) => {
      return (
        <>
          {v.aangemeld && v.overland && <Tag color='gold'>{$t('Ovl')}</Tag>}
          {v.aangemeld && v.kist.map((kist, key) => <Tag key={key}>{kist}</Tag>)}
        </>
      )
    }
  },

  {
    title: $t('Trainingsvlucht'),
    render: (text, d) => {
      if (d.aangemeld) {
        return (
          <>
            {
            d.checkstart
              ? <Tag>{$t('Trainingsvlucht')}</Tag>
              : <p>{d.vlieger.currency && d.vlieger.currency.last_check_start ? d.vlieger.currency.last_check_start.split(', ')[0] : ''}</p>
          }
          </>
        )
      }
    }
  },

  { title: $t('Van'), dataIndex: 'van', render: (text, d) => d.aangemeld && text },
  { title: $t('Tot'), dataIndex: 'tot', render: (text, d) => d.aangemeld && text },
  { title: $t('Opmerking'), dataIndex: 'opmerking', render: (text, d) => d.aangemeld ? <ReadMore text={text} /> : <Tag color='volcano'>Afgemeld</Tag> }

])

export const sortableAanmeldTableNietVliegend = () => ([
  Vlieger(),

  { title: $t('Van'), dataIndex: 'van', render: (text, d) => d.aangemeld && text },
  { title: $t('Tot'), dataIndex: 'tot', render: (text, d) => d.aangemeld && text },
  { title: $t('Opmerking'), dataIndex: 'opmerking', render: (text, d) => d.aangemeld ? <ReadMore text={text} /> : <Tag color='volcano'>Afgemeld</Tag> }

])
