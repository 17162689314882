import {
  PlusOutlined
} from "@ant-design/icons";
import { Button, Flex, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min.js";
import { RefreshBaseData } from "~/components/buttons/RefreshBaseData.jsx";
import { BreadcrumbHeader } from "~/components/layout/breadcrumbHeader.jsx";
import { defaultTableConfig } from "~/components/layout/table.jsx";
import { $t } from "~/i18n.js";
import { api } from "~/lib/api.js";
import { selectElementByID } from "~/lib/helpers.js";
import { GroupForm } from "./components/GroupForm.jsx";
import { CheckIfGroupIsBase, SortGroups } from "./components/baseGroups.js";
import { Tag } from "antd";

export function AdminGroups({ history }) {
  const [groups, setGroups] = useState([]);
  const updateGroup = (newGroup) => {
    let newgroups = groups.map((u) => {
      if (u.id == newGroup.id)
        return {
          ...newGroup,
          number_permissions: newGroup.permissions.length,
          is_base: CheckIfGroupIsBase(newGroup.key),
        };
      return u;
    });
    setGroups(newgroups);
  };

  const columns = [
    {
      title: $t("Naam"),
      dataIndex: "name",
      sorter: (a, b) => ("" + a.name).localeCompare(b.name),
    },
    {
      title: $t("Type"),
      render: (g) => {
        if (g.is_base) return <Tag color="green">{$t("Kern")}</Tag>;
        if (g.is_default) return <Tag>{$t("Standaard")}</Tag>;
        return <Tag color="geekblue">{$t("Club")}</Tag>;
      },
    },
    {
      title: $t("Aantal permissies"),
      dataIndex: "number_permissions",
      sorter: (a, b) => a.number_permissions - b.number_permissions,
    },

    {
      title: $t("Aantal gebruikers"),
      dataIndex: "n_members",
      sorter: (a, b) => a.n_members - b.n_members,
    },
  ];

  useEffect(() => {
    refresh();
  }, []);

  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    const data = await api.get("admin/groups.json");
    if (data) {
      let groups = data.map((d) => ({
        ...d,
        number_permissions: d.permissions.length,
        is_base: CheckIfGroupIsBase(d.key),
      }));
      setGroups(groups);
    }
  };

  const refresh = async () => {
    setLoading(true);
    await fetchData();
    setLoading(false);
  };

  const renderGroupForm = ({ history, match }) => {
    let selectedGroup = { id: null, is_default: false, isNew: true };
    if (match.params.groupId !== "new") {
      selectedGroup = selectElementByID(
        groups,
        "id",
        Number(match.params.groupId)
      );
    }
    return (
      <GroupForm
        history={history}
        group={selectedGroup}
        onSave={refresh}
        updateGroup={updateGroup}
      />
    );
  };

  return (
    <Flex gap="middle" vertical>
      <Switch>
        {groups.length && (
          <Route path="/admin/groups/:groupId" component={renderGroupForm} />
        )}
      </Switch>

      <Flex align="center" gap="middle" justify="space-between">
        <BreadcrumbHeader breadcrumbs={[$t("Groepen")]} />

        <Flex gap={12}>
          <RefreshBaseData />
          <Button
            size="small"
            onClick={() => history.push("/admin/groups/new")}
            icon={<PlusOutlined />}
          >
            {$t("Voeg toe")}
          </Button>
        </Flex>
      </Flex>
      <div className="container">
        <Table
          loading={loading}
          style={{
            marginTop: 12,
            overflowX: "auto",
            maxWidth: 600,
            cursor: "pointer",
          }}
          pagination={{ ...defaultTableConfig, defaultPageSize: 100 }}
          size="small"
          rowKey="id"
          dataSource={SortGroups(groups)}
          columns={columns}
          onRow={(group) => {
            return {
              onClick: () => history.push(`/admin/groups/${group.id}`),
            };
          }}
        />
      </div>
    </Flex>
  );
}
