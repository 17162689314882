import { Col, Row } from "antd";
import React from "react";

export const ReadOnlyFormTag = ({ name, value }) => {
  return (
    <Row gutter={24}>
      <Col
        className="gutter-row"
        span={8}
        style={{ textAlign: "right", padding: "0 4px 12px" }}
      >
        <p>{name}: </p>
      </Col>
      <Col className="gutter-row" span={16}>
        <span className="gray">{value}</span>
      </Col>
    </Row>
  );
};
