import { QuestionOutlined, StopOutlined } from "@ant-design/icons";
import { Button, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnnuleerButton } from "~/components/buttons/AnnuleerButton.jsx";
import { SaveButton } from "~/components/buttons/SaveButton.jsx";
import { $t } from "~/i18n.js";
import { selectElementByID, updateIfNeeded } from "~/lib/helpers.js";
import { getBaseData } from "~/redux/flights/flights.actions.js";

export const MemberModal = ({
  currentProfile,
  member,
  saveMember,
  closeModal,
  isClub,
  loading,
}) => {
  const dispatch = useDispatch();
  const { vliegers, lastUpdatedBaseData } = useSelector(
    (state) => state.flights
  );

  const [showMember, setShowMember] = useState(null);
  useEffect(() => {
    member &&
      setShowMember({
        ...member,
        user: selectElementByID(vliegers, "id", member?.user?.id),
      });
  }, [member]);

  // for modal list select users, requires vliegers from base data
  useEffect(() => {
    member &&
      updateIfNeeded(lastUpdatedBaseData, () => dispatch(getBaseData()));
  }, [member, lastUpdatedBaseData, dispatch]);

  const saveValueToMember = (data) => {
    setShowMember({ ...showMember, ...data });
  };

  const triggerSave = (action) => {
    action = action || showMember.action;
    saveMember({ member: { ...showMember, action } });
  };

  if (!showMember || !member) return null;

  const isTechnicus = showMember.user?.group_keys?.some((g) =>
    g.startsWith("technicus")
  );
  const isARC = showMember.user?.group_keys?.some((g) => g === "technicus_arc");

  return (
    <Modal
      loading={loading}
      title={
        showMember.action === "update"
          ? $t("Wijzig teamlid")
          : $t("Voeg teamlid toe")
      }
      open={!!member}
      okText={$t("Opslaan")}
      cancelText={$t("Annuleren")}
      onCancel={closeModal}
      footer={[
        <Button
          key="delete"
          disabled={!member?.user?.id || loading}
          danger
          icon={<StopOutlined />}
          style={{ marginRight: 24 }}
          onClick={() => triggerSave("delete")}
        >
          {$t("Verwijder")}
        </Button>,
        <AnnuleerButton key="annuleren" onClick={() => setShowMember(null)} />,
        <SaveButton
          key="opslaan"
          onClick={() => triggerSave()}
          disabled={!showMember.user}
          loading={loading}
        />,
      ]}
    >
      <p className="spacer">{$t("Gebruiker")}:</p>
      {showMember.action === "nieuw" ? (
        <Select
          showSearch
          style={{ width: 200 }}
          value={showMember.user?.id}
          filterOption={(input, option) => {
            return (
              option &&
              option.children &&
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }}
          onChange={(newUserID) =>
            saveValueToMember({
              role: "member",
              user: selectElementByID(vliegers, "id", newUserID),
            })
          }
        >
          {vliegers?.map(
            (user, key) =>
              user.id > 0 &&
              user.id !== currentProfile.id && (
                <Select.Option
                  className="row"
                  style={{ flexWrap: "wrap" }}
                  key={key}
                  value={user.id}
                  name={user.name}
                >
                  {user.name}
                </Select.Option>
              )
          )}
        </Select>
      ) : (
        <p>{showMember.user?.name}</p>
      )}

      <p className="spacer" style={{ marginTop: 24 }}>
        {$t("Rol")}:
      </p>
      <Select
        value={showMember.role}
        style={{ width: 200, marginBottom: 12 }}
        onChange={(role) => saveValueToMember({ role })}
      >
        <Select.Option value="admin">{$t("Admin")}</Select.Option>
        <Select.Option value="manager">{$t("Projectmanager")}</Select.Option>
        <Select.Option value="technician" disabled={!isTechnicus}>
          {$t("Technicus")}
        </Select.Option>
        <Select.Option value="arc_technician" disabled={!isARC}>
          ARC {$t("Technicus")}
        </Select.Option>
        <Select.Option value="member">{$t("Projectlid")}</Select.Option>
      </Select>

      <p className="spacer">
        <QuestionOutlined />
        {showMember.role === "admin" && (
          <span>{$t("Mag alles wijzigen in het materiaal.")}</span>
        )}
        {showMember.role === "manager" && (
          <span>
            {$t(
              "Beheert maintenance, workorders en uren, aanspreekpunt voor meldingen."
            )}
          </span>
        )}
        {showMember.role === "technician" && (
          <span>
            {$t(
              "Verantwoordelijk voor technische zaken, aanspreekpunt voor meldingen."
            )}
          </span>
        )}
        {showMember.role === "arc_technician" && (
          <span>{$t("Verantwoordelijk voor Airworthiness Review.")}</span>
        )}
        {showMember.role === "member" && (
          <span>
            {$t(
              "Kan informatie inzien, documenten toevoegen en meewerken aan workorders."
            )}
          </span>
        )}
      </p>

      {isClub && (
        <p className="small" style={{ marginTop: 12 }}>
          {$t(
            "Let op, dit is clubmateriaal: alle leden kunnen dit inzien. Wil je ze laten meewerken, voeg ze dan toe als Projectlid."
          )}
        </p>
      )}
    </Modal>
  );
};
