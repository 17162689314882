import { Tag } from 'antd'
import React from 'react'
import { $t } from '~/i18n.js'

const roleColumn = (a) => {
  if (a.vlieger && a.vlieger.group_keys?.includes('gastvlieger')) return <Tag>{$t('gast')}</Tag>
  if (a.vlieger && a.vlieger.tag === 'dbo') return <Tag color='gold'>{$t('dbo')}</Tag>
  if (a.vlieger && a.vlieger.tag === 'solist') return <Tag color='gold'>{$t('solo')}</Tag>
  if (a.as_instructeur) return <Tag color='gold'>{$t('Ins')}</Tag>

  return <p style={{ paddingLeft: 4 }} />
}

const getGroupAbbreviation = (group) => {
  // abbreviates group. Lierist > lie, Lier_instructeur > l_ins
  if (group.indexOf('_') > 0) {
    const split = group.split('_')
    return group.slice(0, 1) + '_' + split[1].slice(0, 3)
  } else {
    return group.slice(0, 3)
  }
}

const valueOrDefault = (check, showTag) => {
  if (check) return showTag
  return <p style={{ paddingLeft: 4 }} />
}

export const PrintRow = (groups_aanmeld, a, key, types, briefing_tijd, squeeze, skipLabels) => {
  // iterate over all kisten, check abbreviation and reduce doubles.
  let requested_types = a.kist.reduce((all, kist) => {
    const a = types[kist] || kist
    !all.includes(a) && all.push(a)
    return all
  }, []).sort()

  if (requested_types.length === 0) requested_types = ['']

  return (
    <div key={key} className={squeeze ? 'record squeeze' : 'record'}>

      <div className='vlieger'>

        <div className='row' style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <p className='name bold'>
            <span className='aanwezig'>&nbsp;</span>
            {a.checkstart && <Tag style={{ fontWeight: 300 }}>{$t('tr')}</Tag>}
            {a.overland && <Tag style={{ fontWeight: 300 }}>{$t('Ovl')}</Tag>}
            {a.vlieger.name}
          </p>

          <div className='row'>

            {skipLabels && <div className='tags'><Tag className='transparent gray' style={{ marginRight: 12 }}>{$t('privé')}</Tag></div>}

            <span className='start' />
            <span className='start' />
            <span className='start' />

            {!skipLabels && <>
              <span className='start' />
              <span className='start' />
              <span className='start' />
              <span className='start' />
              <span className='start' />
            </>}

          </div>

        </div>

        {!skipLabels && <div className='tags'>

          <div style={{ width: 30 }}>{roleColumn(a)}</div>

          {groups_aanmeld && groups_aanmeld.length > 1 && groups_aanmeld.split(',').map(
            (g, idx) => <div key={idx} style={{ width: 25 }}>
              {valueOrDefault(a.vlieger.group_keys?.includes(g), <Tag>{getGroupAbbreviation(g)}</Tag>)}
            </div>
          )}

          <div className='column'>
            <p>{requested_types.slice(0, 3).map((kist, key) => <Tag className='transparent gray' key={key}>{kist}</Tag>)}

              {a.van && <Tag className='transparent'>+{a.van}</Tag>}
              {a.tot && <Tag className='transparent'>-{a.tot}</Tag>}
            </p>

            {a.opmerking && <p className='transparent supersmall'>{a.opmerking.substr(0, 50)}</p>}

          </div>

        </div>}

      </div>

    </div>
  )
}
