import React, { useState } from 'react'
import { $t } from '~/i18n.js'

import { Button, Checkbox, Divider, Input, message } from 'antd'
import { DataCard } from "~/components/dataCards/dataCard.jsx";
import { isValidIBANNumber } from "~/lib/iban.js";
import { Tag } from "antd";

export const CardLidmaatschap = ({
  profile,
  club,
  saveData,
  type,
  hideLidmaatschap,
  isGastvlieger,
}) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setError] = useState(null);
  const [newIBAN, setIBAN] = useState("");
  const [agree, setAgree] = useState(false);

  const saveIBAN = async () => {
    // check valid
    if (!isValidIBANNumber(newIBAN)) {
      setError($t("Voer een geldig IBAN (bankrekeningnummer) in."));
      return null;
    } else if (!agree) {
      setError($t("Ga akkoord met de automatiche incasso."));
      return null;
    } else {
      setError(null);
    }

    // and save
    setLoading(true);
    await saveData({ iban: newIBAN, remain: true });
    setLoading(false);
    setIBAN("");
    setAgree(null);
  };

  return (
    <DataCard
      readOnly={true}
      title={$t("Lidmaatschap")}
      type={type}
      onSave={() => {
        if (club.has_bank && club.enforce_bank && !profile.iban) {
          message.error(
            $t("Het is verplicht om je bankrekeningnummer toe te voegen")
          );
          return;
        }
        saveData();
      }}
    >
      {!hideLidmaatschap && (
        <>
          <p>
            {$t("Lidnummer")}: {profile.lid_nummer}
          </p>
          <p>
            {$t("Type")}: {profile.lidmaatschap}{" "}
            {isGastvlieger && <Tag>{$t("gastvlieger")}</Tag>}
          </p>

          <p className="small" style={{ marginTop: 12 }}>
            {$t("Wijzigt dit of wil je je uitschrijven, mail het bestuur.")}
          </p>

          {club.has_bank && (
            <>
              <Divider />
              <p>{$t("Bankrekeningnummer")}:</p>
            </>
          )}
        </>
      )}

      {club.has_bank && (
        <>
          <p style={{ marginTop: 6, marginBottom: 12 }}>
            {profile.iban ? (
              <>
                {$t("Je huidige bankrekening is")}{" "}
                <span className="bold">{profile.iban}</span>.{" "}
                {$t(
                  "Als je het wilt wijzigen vul dan je nieuwe IBAN in en voer door."
                )}
              </>
            ) : (
              $t(
                "Voeg je bankrekeningnummer toe en machtig de club om met een automatische incasso te werken."
              )
            )}
          </p>

          <div className="row" style={{ marginBottom: 12 }}>
            <div>
              <Input
                style={{ width: 210, marginRight: 24 }}
                onChange={(event) => setIBAN(event.target.value)}
                placeholder={
                  profile.iban ? $t("Wijzig IBAN") : $t("Voeg IBAN toe")
                }
                type="text"
                value={newIBAN}
              />

              {newIBAN && (
                <Checkbox
                  style={{ marginTop: 4 }}
                  onChange={(e) => setAgree(e.target.checked)}
                >
                  {$t(
                    "Ik ga akkoord dat deze gegevens gedeeld worden met de ${clubfullname}, en machtig de club om met een automatische incasso te werken.",
                    { clubfullname: club.full_name }
                  )}
                </Checkbox>
              )}

              {errorMessage && <p className="small alert">{errorMessage}</p>}
            </div>

            <Button loading={loading} onClick={saveIBAN}>
              {profile.iban === "" ? $t("Opslaan") : $t("Wijzig")}
            </Button>
          </div>

          {club.enforce_bank && !profile.iban && (
            <p className="alert" style={{ marginBottom: 12 }}>
              <span className="bold">{$t("Let op")}:</span>{" "}
              {$t("Het is verplicht om je bankrekeningnummer toe te voegen")}
            </p>
          )}
        </>
      )}
    </DataCard>
  );
};
