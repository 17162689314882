import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CopyOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import React from "react";

export const getNextOrderInList = (list) => {
  if (list.length === 0) return 0
  let max = list?.reduce( (prev, current) => {
    if (+current?.order > +prev?.order) return current
    return prev
  })
  return (max.order || 0) + 1
}

export const OrderDeleteButtons = ({
  task_index = 0,
  task = {},
  tasks = [],
  saveTasks = (c) => {},
  copyTask = null
}) => {

  const deleteTask = () => {
    const new_tasks = tasks.map((_t) => {
      if (_t.id == task.id) return { ...task, action: task.action === 'delete' ? (task.action === 'add' ? 'add' : 'update') : "delete" };
      return _t;
    });
    saveTasks(new_tasks);
  };

  const moveTasks = (lookup) => {
    const new_tasks = tasks.map((_t) => {
      if (_t.id in lookup) {
        _t.action = _t.action === 'add' ? 'add' : "update";
        _t.order = lookup[_t.id];
      }
      return _t;
    });
    saveTasks(new_tasks);
  };

  const move_up = () => {
    // swap with previous task in array
    if (task_index > 0) {
      const prev_task = tasks[task_index - 1];
      const lookup = {};
      lookup[task.id] = prev_task.order;
      lookup[prev_task.id] = task.order;
      if (lookup[task.id] === lookup[prev_task.id]) lookup[prev_task.id] += 1;
      moveTasks(lookup);
    }
  };

  const move_down = () => {
    // swap with next in array
    if (task_index < tasks.length) {
      const next_task = tasks[task_index + 1];
      const lookup = {};
      lookup[task.id] = next_task.order;
      lookup[next_task.id] = task.order;
      if (lookup[task.id] === lookup[next_task.id]) lookup[task.id] += 1;
      moveTasks(lookup);
    }
  };

  return (
    <div className="row" style={{ gap: 6 }}>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          move_up();
        }}
        disabled={task_index === 0}
        key="up"
        shape="circle"
        size="small"
        icon={<ArrowUpOutlined />}
      />

      <Button
        onClick={(e) => {
          e.stopPropagation();
          move_down();
        }}
        disabled={task_index >= tasks.length - 1}
        key="down"
        shape="circle"
        size="small"
        icon={<ArrowDownOutlined />}
      />

      {copyTask && (
        <Button
        onClick={(e) => {
          e.stopPropagation();
          copyTask(task);
        }}
        key="copy"
        shape="circle"
        size="small"
        icon={<CopyOutlined />}
      />
      )}

      <Button
        onClick={(e) => {
          e.stopPropagation();
          deleteTask();
        }}
        key="delete"
        danger
        shape="circle"
        size="small"
        icon={<StopOutlined />}
      />
    </div>
  );
};
