import {
  Button,
  Checkbox,
  Divider,
  Input,
  Modal,
  Popconfirm,
  Switch,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { $t } from "~/i18n.js";

import { StopOutlined } from "@ant-design/icons";
import { Flex, Radio } from "antd";
import { i18n } from "~/i18n.js";
import { selectElementByID } from "../../lib/helpers.js";
import { IGCButton } from "../IGC/IGC.button.jsx";
import { AnnuleerButton } from "../buttons/AnnuleerButton.jsx";
import { SaveButton } from "../buttons/SaveButton.jsx";
import { HelpBubble } from "../help/help.bubble.jsx";
import { SelectPilot } from "../profile/selectPilot.jsx";
import { CategorySelect } from "./sections/categorySelect.jsx";
import { SectionKist } from "./sections/sectionKist.jsx";
import { SectionTijd } from "./sections/sectionTijd.jsx";

export const FlightSleepModal = ({
  visible,
  onClose,
  readOnly,
  sleepFlight,
  allFlights,
  updateFlight,
  kisten,
  sleep_kisten,
  vliegers,
  aanmeldingen,
  currentUser,
  sleep_cat,
}) => {
  if (!sleepFlight) return null;

  const [visibleSleepFlight, setVisibleSleepFlight] = useState({});
  const [visibleFlight, setVisibleFlight] = useState({});

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (sleepFlight) {
      setVisibleSleepFlight(sleepFlight);
      setVisibleFlight(
        sleepFlight.sleep_uuid &&
          selectElementByID(allFlights, "uuid", sleepFlight.sleep_uuid)
      );
      setLoading(false);
    }
  }, [sleepFlight]);

  // check if selectedKist already exists, required for kist and tweede_inzittende registration
  const selectedSleepKist = selectElementByID(
    sleep_kisten,
    "registratie",
    visibleSleepFlight && visibleSleepFlight.registratie
  );

  let possible_start_methods = ["tmg", "tmg-a"];
  if (selectedSleepKist?.start_methode === "sep") {
    possible_start_methods = ["sep", "sep-a"];
  }
  if (!possible_start_methods.includes(visibleSleepFlight.start_methode)) {
    possible_start_methods.push(visibleSleepFlight.start_methode);
  }

  const selectedZweefKist = selectElementByID(
    kisten,
    "registratie",
    visibleFlight && visibleFlight.registratie
  );
  const betalendLid = selectElementByID(
    vliegers,
    "id",
    visibleFlight && visibleFlight.betalend_lid_id
  );

  const sleepVlieger = selectElementByID(
    vliegers,
    "id",
    visibleSleepFlight && visibleSleepFlight.gezagvoerder_id
  );
  const sleepVliegerTweedeInzittende = selectElementByID(
    vliegers,
    "id",
    visibleSleepFlight && visibleSleepFlight.tweede_inzittende_id
  );
  const isAerotow =
    visibleSleepFlight && visibleSleepFlight.start_methode?.endsWith("-a");

  const saveSleep = (newData) => {
    // note for the modal, we always save the complete flight element
    setVisibleSleepFlight({ ...visibleSleepFlight, ...newData });
  };
  const saveFlight = (newData) => {
    // note for the modal, we always save the complete flight element
    if (visibleFlight) {
      setVisibleFlight({ ...visibleFlight, ...newData });
    }
  };

  const [savingMethod, setSavingMethod] = useState(false);
  const saveNewmethod = (newValue) => {
    setSavingMethod(true);
    let base_method = newValue.split("-")[0];
    if (newValue.endsWith("-a")) {
      saveSleep({ start_methode: `${base_method}-a` });
      saveFlight({ start_methode: "sleep", action: "recover" });
    } else {
      saveSleep({ start_methode: base_method });
      saveFlight({ start_methode: "lier", action: "delete" });
    }
    setSavingMethod(false);
  };

  const saveAndClose = async () => {
    setLoading(true);
    visibleFlight && (await updateFlight(visibleFlight));
    const data = await updateFlight(visibleSleepFlight);
    setLoading(false);
    if (data) onClose();
  };

  const triggerDelete = async () => {
    setLoading(true);
    visibleFlight &&
      (await updateFlight({ uuid: visibleFlight.uuid, action: "delete" }));
    await updateFlight({ uuid: visibleSleepFlight.uuid, action: "delete" });
    setLoading(false);
    onClose();
  };
  const [forceEdit, setForceEdit] = useState(false);
  readOnly =
    !forceEdit &&
    (readOnly ||
      (visibleSleepFlight &&
        (visibleSleepFlight.is_deleted || visibleSleepFlight.is_locked)));

  return (
    <Modal
      width={600}
      title={`${$t("Sleep")} ${visibleSleepFlight.start_tijd || ""}`}
      open={visible}
      onCancel={onClose}
      confirmLoading={loading}
      footer={[
        !visibleFlight || !visibleFlight.registratie ? (
          <Popconfirm
            placement="bottom"
            title={$t("Zeker weten dat je de vlucht wilt verwijderen")}
            onConfirm={triggerDelete}
            key="delete"
            okText={$t("Ja")}
            cancelText={$t("Nee")}
          >
            <Button
              icon={<StopOutlined />}
              danger
              loading={loading}
              disabled={readOnly}
            >
              {$t("Verwijder")}
            </Button>
          </Popconfirm>
        ) : null,

        <AnnuleerButton
          key="annuleren"
          style={{ marginLeft: 8 }}
          onClick={onClose}
        />,

        <SaveButton
          style={{ marginLeft: 8 }}
          key="opslaan"
          loading={loading}
          onClick={saveAndClose}
        />,
      ]}
    >
      <div>
        <Flex gap={12}>
          <div>
            <p>{$t("Sleepkist")}: </p>
            <SectionKist
              selectedKist={selectedSleepKist}
              kisten={sleep_kisten}
              flight={visibleSleepFlight}
              saveFlight={saveSleep}
              readOnly={readOnly}
            />
          </div>

          {isAerotow && (
            <div>
              <p>{$t("Zweefkist")}:</p>
              {visibleFlight && (
                <SectionKist
                  selectedKist={selectedZweefKist}
                  kisten={kisten}
                  flight={visibleFlight}
                  saveFlight={saveFlight}
                  readOnly={readOnly}
                />
              )}
            </div>
          )}
        </Flex>

        <Flex gap={12} style={{ marginTop: 18 }} align="center">
          <p>{$t("Startmethode")}:</p>

          <Radio.Group
            size="medium"
            buttonStyle="solid"
            disabled={savingMethod}
            value={visibleSleepFlight.start_methode}
            onChange={(e) => {
              saveNewmethod(e.target.value);
            }}
          >
            {possible_start_methods.map((method, idx) => (
              <Radio.Button value={method} key={idx}>
                {$t(method)}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Flex>

        <Divider orientation="left" plain>
          {$t("Vliegers")}
        </Divider>

        <div
          className="row"
          style={{ alignItems: "flex-start", flexWrap: "wrap", gap: 12 }}
        >
          <p
            style={{
              marginTop: 5,
            }}
          >
            {$t("sleepvlieger")}:
          </p>
          <SelectPilot
            hideAvatar
            readOnly={readOnly}
            field_id="gezagvoerder_id"
            field_name="gezagvoerder_naam"
            title={$t("sleepvlieger")}
            pilot={sleepVlieger}
            otherPilot={null}
            // vliegers gezagvoerder only non-pax
            vliegers={aanmeldingen.filter(
              (aanmelding) => aanmelding.id > 0 && aanmelding.as_sleepvlieger
            )}
            alle_vliegers={vliegers}
            flight={visibleSleepFlight}
            saveFlight={saveSleep}
            openPilot={null}
            setBetalendLid={false}
          />
          {!sleepVlieger && (
            <Button
              disabled={readOnly}
              onClick={() =>
                saveSleep({
                  gezagvoerder: currentUser,
                  gezagvoerder_id: currentUser.id,
                  gezagvoerder_naam: currentUser.name,
                })
              }
            >
              {$t("Voeg mij toe")}
            </Button>
          )}
        </div>

        {selectedSleepKist?.pilots > 1 && (
          <div
            className="row"
            style={{ marginTop: 6, flexWrap: "wrap", gap: 12 }}
          >
            <p
              style={{
                marginTop: 5,
              }}
            >
              {$t("Sleepvliegtuig")} 2<sup>e</sup>:
            </p>
            <SelectPilot
              hideAvatar
              readOnly={readOnly}
              field_id="tweede_inzittende_id"
              field_name="tweede_inzittende_naam"
              title={$t("tweede inzitttende")}
              pilot={sleepVliegerTweedeInzittende}
              otherPilot={sleepVlieger}
              // vliegers gezagvoerder only non-pax
              vliegers={aanmeldingen.filter(
                (aanmelding) => aanmelding.id > 0 && aanmelding.as_sleepvlieger
              )}
              alle_vliegers={vliegers}
              flight={visibleSleepFlight}
              saveFlight={saveSleep}
              openPilot={null}
              setBetalendLid={false}
            />
            <Checkbox
              className="small"
              checked={visibleSleepFlight?.is_fis}
              disabled={!sleepVlieger?.is_instructeur}
              onChange={(click) => saveSleep({ is_fis: click.target.checked })}
            >
              FI(s){" "}
              <HelpBubble content="Trainingsvlucht om met een sleepvliegtuig te mogen slepen, alleen mogelijk indien sleepvlieger instructeur is." />
            </Checkbox>
          </div>
        )}

        {isAerotow && (
          <div
            className="row"
            style={{ alignItems: "flex-start", marginTop: 6 }}
          >
            <p style={{ margin: "5px 0" }}>
              {$t("Zweefvlieger (PIC)")}:{" "}
              <span style={{ marginLeft: 5 }}>
                {(visibleFlight && visibleFlight.gezagvoerder_naam) ||
                  $t("Onbreekt")}
              </span>
            </p>
          </div>
        )}

        {isAerotow && (
          <div
            className="row"
            style={{ alignItems: "flex-start", marginTop: 6 }}
          >
            <p
              style={{
                marginTop: 5,
              }}
            >
              {$t("Betalend lid")}:
            </p>
            <SelectPilot
              hideAvatar
              readOnly={readOnly}
              field_id="betalend_lid_id"
              field_name="betalend_lid_naam"
              title={$t("Betalend lid")}
              pilot={betalendLid}
              // vliegers gezagvoerder only non-pax
              vliegers={aanmeldingen.filter((a) => a.id > 0)}
              alle_vliegers={vliegers.filter((v) => v.id > 0)}
              flight={visibleFlight}
              saveFlight={(data) => {
                // not saving sleep, this remains empty
                const newData = { betalend_lid_id: data.betalend_lid_id };
                if (visibleFlight && !visibleFlight.gezagvoerder_id && data) {
                  newData.gezagvoerder_id = data.betalend_lid_id;
                  newData.gezagvoerder_naam = data.betalend_lid_naam;
                }
                saveFlight(newData);
              }}
              openPilot={null}
              setBetalendLid
            />
          </div>
        )}

        <Divider orientation="left" plain>
          {$t("Tijden Sleepkist")}:
        </Divider>

        <SectionTijd
          readOnly={readOnly}
          flight={visibleSleepFlight}
          saveFlight={(d) => {
            // update possible flightDuur
            if ("vluchtduur" in d) {
              d.vluchtduur =
                d.start_tijd && d.landings_tijd
                  ? parseInt(
                      (dayjs(d.landings_tijd, "HH:mm") -
                        dayjs(d.start_tijd, "HH:mm")) /
                        60000
                    )
                  : 0;
            }
            saveSleep(d);
          }}
        />

        <Divider orientation="left" plain>
          {$t("Details")}:
        </Divider>

        <div className="row" style={{ width: 220 }}>
          <p style={{ width: 170 }}>
            {$t("Hoogte")} {i18n.unit === "m" ? "(m)" : "(ft)"}:
          </p>
          <Input
            type="number"
            value={Math.round(
              i18n.convertHeightToLocale(visibleSleepFlight.height)
            )}
            onChange={(element) =>
              saveSleep({
                height: i18n.convertLocaleHeightToMetric(element.target.value),
              })
            }
            allowClear={false}
            disabled={readOnly}
          />
        </div>

        <div style={{ width: 200 }}>
          <CategorySelect
            readOnly={readOnly}
            currentFlight={visibleSleepFlight.uuid}
            currentValue={visibleSleepFlight.category}
            categories={sleep_cat}
            onSelect={(value) => saveSleep({ category: value })}
          />
        </div>

        <div style={{ width: 200, marginBottom: 6 }}>
          <p>{$t("Vertrek")}:</p>
          <Input
            value={visibleSleepFlight.vertrek_vliegveld}
            onChange={(element) => {
              saveSleep({ vertrek_vliegveld: element.target.value });
              saveFlight({ vertrek_vliegveld: element.target.value });
            }}
            prefix={$t("van")}
            disabled={readOnly}
          />
        </div>

        <div style={{ width: 200, marginBottom: 6 }}>
          <p>{$t("Aankomst")}:</p>
          <Input
            value={visibleSleepFlight.aankomst_vliegveld}
            onChange={(element) =>
              saveSleep({ aankomst_vliegveld: element.target.value })
            }
            prefix={$t("naar")}
            disabled={readOnly}
          />
        </div>

        <div className="row" style={{ width: 200, marginBottom: 6 }}>
          <p style={{ marginRight: 12 }}>IGC:</p>
          {sleepFlight.igc ? (
            <IGCButton flight={sleepFlight} title={$t("Open")} />
          ) : (
            "-"
          )}
        </div>

        <div style={{ maxWidth: 350, marginBottom: 6 }}>
          <p>{$t("Beschrijving")}:</p>

          <Input.TextArea
            rows={2}
            disabled={readOnly}
            value={visibleSleepFlight.beschrijving}
            onChange={(element) => {
              saveSleep({ beschrijving: element.target.value });
              saveFlight({ beschrijving: element.target.value });
            }}
          />
        </div>

        {currentUser?.wijzig_vluchten && sleepFlight.is_locked && (
          <div className="row" style={{ marginTop: 12 }}>
            <span>{$t("Wijzig vlucht als admin")}:</span>
            <Switch
              style={{ marginLeft: 6 }}
              checked={forceEdit}
              onChange={setForceEdit}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};
