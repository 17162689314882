import { StopOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Input,
  Modal,
  Popconfirm,
  Radio,
  Switch,
} from "antd";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { $t } from "~/i18n.js";
import { selectElementByID, updateIfNeeded } from "../../lib/helpers.js";
import { flightsActions } from "../../redux/actions.js";
import { AnnuleerButton } from "../buttons/AnnuleerButton.jsx";
import { SaveButton } from "../buttons/SaveButton.jsx";
import { HelpBubble } from "../help/help.bubble.jsx";
import { SelectPilot } from "../profile/selectPilot.jsx";
import { CategorySelect } from "./sections/categorySelect.jsx";
import { SectionKist } from "./sections/sectionKist.jsx";
import { SectionStartmethode } from "./sections/sectionStartmethode.jsx";
import { SectionTijd } from "./sections/sectionTijd.jsx";
import { SignFlightCollapse } from "./sections/signFlight.jsx";

export const FlightPrivateModalComponent = ({
  pilot,
  flight,
  onClose,
  onUpdateFlight,
  flights,
  getBaseData,
  updateFlight,
}) => {
  if (!flight) return null;

  const { club } = useSelector((state) => state.persist);
  const [visibleFlight, setVisibleFlight] = useState({});
  const [loading, setLoading] = useState(false);
  const [isClubLid, setisClubLid] = useState(false);
  const otherPilot = isClubLid
    ? selectElementByID(
        flights.vliegers,
        "id",
        visibleFlight.gezagvoerder_id === pilot.id
          ? visibleFlight.tweede_inzittende_id
          : visibleFlight.gezagvoerder_id
      )
    : null;
  useEffect(() => {
    if (flight) {
      setVisibleFlight({
        ...flight,
        rol: flight.gezagvoerder_id === pilot.id ? "gezagvoerder" : "tweede",
      });
      setLoading(false);
      // other pilot is clubvlieger if id exists
      setisClubLid(
        flight.gezagvoerder_id === pilot.id
          ? !!flight.tweede_inzittende_id
          : flight.tweede_inzittende_id === pilot.id && !!flight.gezagvoerder_id
      );
    }
  }, [flight, pilot]);

  useEffect(() => {
    updateIfNeeded(flights.lastUpdatedBaseData, getBaseData, null, 3600000); // 1 hour
  }, [flights.lastUpdatedBaseData, getBaseData]);

  // check if selectedKist already exists, required for kist and tweede_inzittende registration
  const selectedKist = selectElementByID(
    flights.kisten,
    "registratie",
    visibleFlight.registratie
  );

  const saveFlight = (newData) =>
    setVisibleFlight({ ...visibleFlight, ...newData });

  const saveAndClose = async (newFlight) => {
    setLoading(true);
    const newData = {
      ...(newFlight || visibleFlight),
      is_prive: true,
      datum: visibleFlight.datum.format("YYYY-MM-DD"),
      recalculate_currency: true,
      // add instructeur vinkje if checkstart/profcheck
      is_fis:
        visibleFlight.is_training ||
        visibleFlight.is_profcheck ||
        visibleFlight.is_fis,
    };

    // add blocktime if required for TMG, default is 10 minutes after vluchtduur
    if (newData.start_methode === "tmg" || newData.start_methode == "tmg-a") {
      if (!newData.blocktime)
        newData.blocktime = (newData.vluchtduur || 0) + 10;
    }

    await updateFlight(newData);
    setLoading(false);
    onUpdateFlight({});
    onClose();
  };

  const [deleting, setDeleting] = useState(false);
  const triggerDelete = async () => {
    if (!deleting) {
      if (!visibleFlight.id) {
        onClose();
      } else {
        setDeleting(true);
        await saveAndClose({ ...visibleFlight, action: "delete" });
        setDeleting(false);
      }
    }
  };

  return (
    <Modal
      className="scrollable"
      width={600}
      title={
        visibleFlight.id
          ? $t("Wijzig vlucht buiten clubverband")
          : $t("Voeg vlucht buiten clubverband toe")
      }
      open={flight}
      onCancel={onClose}
      footer={[
        <Popconfirm
          key="remove"
          placement="bottom"
          title={$t("Zeker weten dat je de vlucht wilt verwijderen")}
          onConfirm={triggerDelete}
          okText={$t("Ja")}
          cancelText={$t("Nee")}
        >
          <Button
            danger
            icon={<StopOutlined />}
            style={{ marginRight: 24 }}
            loading={deleting}
          >
            {$t("Verwijder")}
          </Button>
        </Popconfirm>,
        <AnnuleerButton key="annuleren" onClick={onClose} />,
        <SaveButton
          key="opslaan"
          onClick={() => saveAndClose()}
          loading={loading}
        />,
      ]}
    >
      <DatePicker
        format={localStorage.localeFormat}
        style={{ marginTop: 12, width: 200 }}
        value={visibleFlight.datum}
        onChange={(value) => saveFlight({ datum: value })}
        onSelect={(value) => saveFlight({ datum: value })}
        allowClear={false}
        placeholder={$t("selecteer datum")}
        disabledDate={(date) => date > new Date()}
      />

      <SectionStartmethode
        readOnly={false}
        flight={visibleFlight}
        saveFlight={async (data) => saveFlight(data)}
        size="small"
        extra_methods={
          pilot.group_keys?.indexOf("sleepvlieger") > 0
            ? ["tmg-a", "sep-a"]
            : []
        }
      />

      <div className="bold" style={{ margin: "18px 0 6px" }}>
        {$t("Starts en vluchtduur")}:
      </div>

      <SectionTijd flight={visibleFlight} saveFlight={saveFlight} />

      {(club.flight_cat || club.tmg_cat) && (
        <>
          <div className="bold" style={{ margin: "18px 0 6px" }}>
            {$t("Type start")}:
          </div>
          <div style={{ width: 200 }}>
            <CategorySelect
              currentValue={visibleFlight.category}
              currentFlight={visibleFlight.uuid}
              categories={
                visibleFlight?.start_methode === "tmg" ||
                visibleFlight?.start_methode === "sep"
                  ? club.tmg_cat
                  : club.flight_cat
              }
              onSelect={(value) => saveFlight({ category: value })}
            />
          </div>
        </>
      )}

      <div className="row" style={{ height: 33 }}>
        <Checkbox
          className="small"
          checked={visibleFlight.is_overland}
          disabled={visibleFlight.vluchtduur < 60}
          onChange={(click) =>
            saveFlight({ is_overland: click.target.checked, afstand: 0 })
          }
        >
          {$t("Overland")}
        </Checkbox>

        {visibleFlight.is_overland && (
          <Input
            type="number"
            suffix="km"
            style={{ width: 119 }}
            value={visibleFlight.afstand}
            onChange={(elm) => saveFlight({ afstand: elm.target.value })}
          />
        )}
      </div>

      <div className="bold" style={{ margin: "18px 0 6px" }}>
        {$t("Kist")}:
      </div>

      <SectionKist
        selectedKist={selectedKist}
        kisten={flights.kisten}
        flight={visibleFlight}
        saveFlight={async (d) => saveFlight(d)}
        readOnly={false}
      />

      <div className="bold" style={{ margin: "18px 0 6px" }}>
        {$t("Vliegers")}:
      </div>

      <p>{$t("Ik ben")}:</p>
      <Radio.Group
        size="small"
        style={{ marginRight: 12 }}
        value={visibleFlight.rol}
        onChange={(rol) => {
          setisClubLid(false);
          if (rol.target.value === "gezagvoerder") {
            saveFlight({
              rol: "gezagvoerder",
              gezagvoerder_id: pilot.id,
              gezagvoerder_naam: pilot.name,
              tweede_inzittende_id: "DELETE",
              tweede_inzittende_naam: "",
              is_training: false,
              is_profcheck: false,
            });
          } else {
            saveFlight({
              rol: "tweede",
              gezagvoerder_id: "DELETE",
              gezagvoerder_naam: "",
              tweede_inzittende_id: pilot.id,
              tweede_inzittende_naam: pilot.name,
              is_training: false,
              is_profcheck: false,
              is_fis: false,
            });
          }
        }}
      >
        <Radio.Button value="gezagvoerder">{$t("Gezagvoerder")}</Radio.Button>
        <Radio.Button value="tweede">{$t("2de-inzittende")}</Radio.Button>
      </Radio.Group>

      <p style={{ marginTop: 4 }}>
        {visibleFlight.rol === "tweede"
          ? $t("Gezagvoerder")
          : $t("Tweede-inzittende")}{" "}
        is:{" "}
      </p>
      <p className="small" style={{ margin: "2px 0" }}>
        {$t("clublid")}:{" "}
        <Switch
          size="small"
          checked={isClubLid}
          onChange={(checked) => setisClubLid(checked)}
        />
      </p>

      {isClubLid ? (
        <SelectPilot
          readOnly={false}
          field_id={
            visibleFlight.rol === "tweede"
              ? "gezagvoerder_id"
              : "tweede_inzittende_id"
          }
          field_name={
            visibleFlight.rol === "tweede"
              ? "gezagvoerder_naam"
              : "tweede_inzittende_naam"
          }
          title={
            visibleFlight.rol === "tweede"
              ? $t("Naam gezagvoerder")
              : $t("Naam tweede-inzittende")
          }
          pilot={otherPilot}
          vliegers={flights.vliegers}
          flight={visibleFlight}
          saveFlight={saveFlight}
          openPilot={null}
        />
      ) : (
        <Input
          value={
            visibleFlight.rol === "tweede"
              ? visibleFlight.gezagvoerder_naam
              : visibleFlight.tweede_inzittende_naam
          }
          style={{ width: 245 }}
          placeholder={
            visibleFlight.rol === "tweede"
              ? $t("Naam gezagvoerder")
              : $t("Naam tweede-inzittende")
          }
          onChange={(elm) =>
            saveFlight(
              visibleFlight.rol === "tweede"
                ? {
                    gezagvoerder_naam: elm.target.value,
                    gezagvoerder_id: "DELETE",
                  }
                : {
                    tweede_inzittende_naam: elm.target.value,
                    tweede_inzittende_id: "DELETE",
                  }
            )
          }
        />
      )}

      <div className="row" style={{ marginTop: 6 }}>
        <Checkbox
          className="small"
          checked={visibleFlight.is_training}
          disabled={
            (visibleFlight.starts > 1 &&
              visibleFlight?.start_methode !== "tmg") ||
            visibleFlight.tweede_inzittende_id !== pilot.id
          }
          onChange={(click) =>
            saveFlight({ is_training: click.target.checked })
          }
        >
          {$t("Trainingsvlucht")}{" "}
          <HelpBubble
            content={$t(
              "Een EASA trainingsvlucht voer je uit met een instructeur waarbij jezelf tweede inzittende bent."
            )}
          />
        </Checkbox>

        <Checkbox
          className="small"
          checked={visibleFlight.is_profcheck}
          disabled={
            (visibleFlight.starts > 1 &&
              visibleFlight?.start_methode !== "tmg") ||
            visibleFlight.tweede_inzittende_id !== pilot.id
          }
          onChange={(click) =>
            saveFlight({ is_profcheck: click.target.checked })
          }
        >
          {$t("Proficiency check")}{" "}
          <HelpBubble
            content={$t(
              "Een EASA Proficiency Check voer je uit met een instructeur waarbij jezelf tweede inzittende bent."
            )}
          />
        </Checkbox>

        {pilot.is_instructeur && (
          <Checkbox
            className="small"
            checked={visibleFlight.is_fis}
            disabled={
              !visibleFlight.gezagvoerder_id || visibleFlight.rol === "tweede"
            }
            onChange={(click) => saveFlight({ is_fis: click.target.checked })}
          >
            FI(s){" "}
            <HelpBubble
              content={$t(
                "Upload hier 1 of meerdere instructievluchten gedaan op andere velden."
              )}
            />
          </Checkbox>
        )}
      </div>

      <div className="bold" style={{ margin: "18px 0 6px" }}>
        {$t("Vliegvelden")}:
      </div>

      <Input
        type="text"
        style={{ width: 245 }}
        placeholder={$t("Van")}
        value={visibleFlight.vertrek_vliegveld}
        onChange={(elm) => saveFlight({ vertrek_vliegveld: elm.target.value })}
      />
      <Input
        type="text"
        style={{ marginTop: 12, width: 245 }}
        placeholder={$t("Naar")}
        value={visibleFlight.aankomst_vliegveld}
        onChange={(elm) => saveFlight({ aankomst_vliegveld: elm.target.value })}
      />

      {visibleFlight.id && (
        <SignFlightCollapse
          flight={visibleFlight}
          updateFlight={({ flight }) => {
            saveFlight({ signed: flight.signed });
            onUpdateFlight({});
            onClose();
          }}
        />
      )}

      <div className="bold" style={{ margin: "18px 0 6px" }}>
        {$t("Eigen notitie")}:
      </div>

      <Input.TextArea
        rows={3}
        placeholder="Eigen notitie"
        value={visibleFlight.notitie}
        onChange={(elm) =>
          saveFlight({ notitie: elm.target.value.substr(0, 500) })
        }
      />

      {visibleFlight.uuid && (
        <p className="small gray" style={{ marginTop: 12 }}>
          ID: {visibleFlight.uuid}
        </p>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  flights: state.flights,
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateFlight: (d) => dispatch(flightsActions.updateFlight(d)),
    getBaseData: (d) => dispatch(flightsActions.getBaseData(d)),
  };
};

export const FlightPrivateModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(FlightPrivateModalComponent);
