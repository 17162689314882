import { SaveOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { $t } from "~/i18n.js";

export const SaveButton = (args) => {
  return (
    <Button type="primary" icon={<SaveOutlined />} {...args}>
      {$t("Opslaan")}
    </Button>
  );
};
