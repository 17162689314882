import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { $t } from "~/i18n.js";

import { Form, Input, Select, Switch } from "antd";

import { CardBase } from "~/components/timeLine/cardBase.jsx";
import { SelectTypeDag } from "../../addDay/index.js";

export const CardData = ({ data, saveAndNext, prev }) => {
  const [form] = Form.useForm();
  const [name, setName] = useState("");
  const [is_vliegend, setVliegend] = useState(true);
  const [items, setItem] = useState([]);
  const { club } = useSelector((state) => state.persist);
  useEffect(
    () => club && club.dag_types && setItem(club.dag_types?.split(",")),
    [club, setItem]
  );
  useEffect(() => {
    setVliegend(data.is_vliegend);
    form.resetFields();
  }, [data]);

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      saveAndNext({ ...values, is_vliegend });
    } catch (info) {
      console.log("validation failed: ", info);
    }
  };

  return (
    <CardBase prev={prev} next={handleSave}>
      <Form
        style={{ maxWidth: 400, margin: "0 auto" }}
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        name="addDay"
        preserve={false}
        requiredMark={false}
        initialValues={{
          start_methode: "lier",
          type_dag:
            club && club.dag_types.length > 0
              ? club.dag_types?.split(",")[0].trim()
              : "",
          vertrek_vliegveld: club.vliegveld_icao
            ? club.vliegveld_icao
            : club.vliegveld,
          description: "",
          days_between: 10,
          briefing_tijd: "09:00",
          ...data,
        }}
      >
        <Form.Item
          label={$t("Rooster periode")}
          autoFocus
          name="description"
          rules={[
            { required: true, message: $t("Voeg een beschrijving toe!") },
          ]}
        >
          <Input placeholder={$t("beschrijving")} />
        </Form.Item>

        <SelectTypeDag
          setItem={setItem}
          setName={setName}
          name={name}
          items={items}
        />

        <Form.Item label={$t("Vliegend")} name="is_vliegend">
          <Switch
            onChange={(checked) => setVliegend(checked)}
            checked={is_vliegend}
            checkedChildren={$t("Ja")}
            unCheckedChildren={$t("Nee")}
          />
        </Form.Item>

        <Form.Item
          label={$t("Locatie")}
          name="vertrek_vliegveld"
          rules={[{ required: true, message: $t("Voeg een locatie toe!") }]}
        >
          <Input />
        </Form.Item>

        <p>
          {$t(
            'Note: Dagen worden nieuw aangemaakt op basis van de "Soort dag" en "Locatie". Als deze hetzelfde zijn met bestaande dagen worden de dagen samengevoegd.'
          )}
        </p>

        {is_vliegend && (
          <Form.Item label={$t("Start methode")} name="start_methode">
            <Select>
              {["lier", "sleep", "zelf", "tmg"].map((start, idx) => (
                <Select.Option key={idx} value={start}>
                  {$t(start)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          label={$t("Briefing tijd")}
          name="briefing_tijd"
          rules={[{ required: true, message: $t("Voeg tijd toe!") }]}
        >
          <Input type="time" placeholder="hh:mm" />
        </Form.Item>

        <Form.Item
          label={$t("Rusttijd")}
          name="days_between"
          rules={[{ required: true, message: $t("Voeg cijfer toe!") }]}
        >
          <Input type="number" addonAfter={$t("dagen")} />
        </Form.Item>

        <p className="small">
          <span className="bold">{$t("Rusttijd")}: </span>{" "}
          {$t(
            "Minimaal dagen tussen diensten zorgt ervoor dat leden niet (automatisch) achterelkaar ingeroosterd worden"
          )}
          .
        </p>
      </Form>
    </CardBase>
  );
};
