import React from "react";
import { getUserAvatar, getUserAvatarError } from "~/components/profile/selectPilot.jsx";

export const PilotAvatar = ({ vlieger }) => (
    <div>
        <img
            className="avatarLedenLijst"
            alt=""
            src={getUserAvatar(vlieger, true)}
            onError={getUserAvatarError}
        />
    </div>
);
