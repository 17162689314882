import { SyncOutlined } from "@ant-design/icons";
import { Button, Popover } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { $t } from "~/i18n.js";
import { getBaseData } from "~/redux/flights/flights.actions.js";
import { getVliegerData, profileAction } from "~/redux/persist/persist.actions.js";

export const RefreshBaseData = (args) => {
  const dispatch = useDispatch();

  const isPending = useSelector((state) => state?.flights?.isPending);

  async function syncData() {
    // hard sync of all data
    await dispatch(getBaseData());
    await dispatch(profileAction());
    await dispatch(getVliegerData({year: new Date().getFullYear()}));
  }

  return (
    <Popover content={$t("Ververs achtergrond app data")} placement="top">
      <Button
        loading={isPending}
        size="small"
        onClick={syncData}
        icon={<SyncOutlined />}
        {...args}
      >
        {$t("Sync")}
      </Button>
    </Popover>
  );
};
