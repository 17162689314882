import { Tag } from "antd";
import React from "react";
import { $t } from "~/i18n.js";
import { _parseDate } from "~/lib/localize.js";

export const ParseTrainingFlightDate = ({
  last_check_start,
  hideOnbekendTag = false,
}) => {
  
  if (!last_check_start & !hideOnbekendTag)
    return <Tag color="red">{$t("onbekend")}</Tag>;
  if (!last_check_start) return null;

  return (
    <>
      {last_check_start.split(",").map((d, i) => {
        // Check if date is longer than 365 days, and return Tag
        const setWarning = new Date() - new Date(d) > (31556952000 * 1.75);
        return (
          <Tag
            key={i}
            color={setWarning ? "red" : "white"}
            style={setWarning ? {} : { color: "black" }}
          >
            {_parseDate(d)}
          </Tag>
        );
      })}
    </>
  );
};
