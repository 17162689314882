import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { MainLayout } from "~/components/layout/layout.jsx";
import { UserModal } from "~/components/profile/userModal.jsx";
import { updateIfNeeded } from "~/lib/helpers.js";
import { aanmeldingenActions, daysActions, flightsActions } from "~/redux/actions.js";
import { AddDayModal } from "../addDay/addDayModal.jsx";
import { AanmeldForm } from "./components/AanmeldForm.jsx";
import { AanmeldHeader } from "./components/AanmeldHeader.jsx";
import { DayInfo } from "./components/DayInfo.jsx";
import { AanmeldLijstContent } from "./components/aanmeldLijstContent.jsx";

export const AanmeldLijstComponent = ({
  history,
  match,
  kist_aanmelding,
  club,
  profile,
  aanmeldingen,
  flights,
  days,
  saveDay,
  getBaseData,
  getAanmeldingen,
  saveAanmelding,
  getAllDays,
  openDay,
}) => {
  useEffect(() => {
    updateIfNeeded(flights.lastUpdatedBaseData, getBaseData);
  }, [flights.lastUpdatedBaseData, getBaseData]);

  useEffect(() => {
    updateIfNeeded(
      days.lastUpdated,
      getAllDays,
      () => days.lastUpdated && openDay(match.params.id)
    );
  }, [days.lastUpdated, getAllDays, match.params.id, openDay]);

  useEffect(() => {
    const fetchData = async () => {
      const resp = await getAanmeldingen({
        dag_id: match.params.id,
        normalizeAsVliegers: false,
      });
      if (!resp) history.push("/register");
    };
    fetchData();
  }, [match.params.id, getAanmeldingen, history]);

  const [loading, setLoading] = useState(false);
  const [openedPilot, setOpenPilot] = useState(null);
  const [openModal, setmodalOpen] = useState();
  const [activeAanmelding, setActiveAanmelding] = useState(-1);

  const editAanmelding = (aanmelding) => {
    setActiveAanmelding(aanmelding);
    setmodalOpen("aanmeld");
  };

  const handleSaveDay = (data) => {
    setmodalOpen(null);
    saveDay(data);
  };

  const afmelden = async (lid, aangemeld = false, remark = "") => {
    setLoading(true);
    await saveAanmelding({
      action: aangemeld ? "meld_aan" : "meld_af",
      dag_id: days.activeDay.dag_id,
      remark: remark,
      lid,
    });
    setLoading(false);
  };

  const currentAanmelding = aanmeldingen.aanmeldingen?.find(
    (aanmelding) => aanmelding.vlieger?.id === profile.id
  );

  const types = flights.club_kisten
    .reduce((all, kist) => {
      const value = kist.type_group || kist.type;
      value && !all.includes(value) && all.push(value);
      return all;
    }, [])
    .sort();

  return (
    <MainLayout
      history={history}
      isLoading={days.isPending || aanmeldingen.isPending}
    >
      <AddDayModal
        profile={profile}
        link_to={null}
        visible={openModal === "day"}
        onOk={handleSaveDay}
        onCancel={() => setmodalOpen(null)}
        confirmLoading={days.isPending}
        activeDay={days.activeDay}
        club={club}
        history={history}
      />

      {activeAanmelding !== -1 && (
        <AanmeldForm
          visible={openModal === "aanmeld"}
          activeAanmelding={activeAanmelding}
          closeModal={() => {
            setmodalOpen(null);
            setActiveAanmelding(-1);
          }}
          saveAanmelding={saveAanmelding}
          profile={profile}
          activeDay={days.activeDay}
          types={types}
          vliegers={flights.vliegers}
          kist_aanmelding={profile.vliegt && kist_aanmelding}
        />
      )}

      <UserModal
        visible={openedPilot && openedPilot.id}
        onClose={() => setOpenPilot(null)}
        pilot={openedPilot}
        currentUser={profile}
      />

      <AanmeldHeader
        days={days}
        profile={profile}
        currentAanmelding={currentAanmelding}
        loading={loading}
        club={club}
        match={match}
        editAanmelding={editAanmelding}
        afmelden={afmelden}
        setmodalOpen={setmodalOpen}
      />

      <DayInfo activeDay={days.activeDay} />

      <AanmeldLijstContent
        days={days}
        aanmeldingen={aanmeldingen}
        club={club}
        flights={flights}
        profile={profile}
        currentAanmelding={currentAanmelding}
        setOpenPilot={setOpenPilot}
        editAanmelding={editAanmelding}
        afmelden={afmelden}
      />
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  aanmeldingen: state.aanmeldingen,
  days: state.days,
  flights: state.flights,
  club: state.persist.club,
  profile: state.persist.profile,
  kist_aanmelding: state.persist.kist_aanmelding,
  lastUpdated: state.materiaal.lastUpdated,
});

const mapDispatchToProps = (dispatch) => ({
  saveDay: (d) => dispatch(daysActions.saveDay(d)),
  getBaseData: (d) => dispatch(flightsActions.getBaseData(d)),
  openDay: (d) => dispatch(daysActions.openDay(d)),
  getAanmeldingen: (d) => dispatch(aanmeldingenActions.getAanmeldingen(d)),
  saveAanmelding: (d) => dispatch(aanmeldingenActions.saveAanmelding(d)),
  getAllDays: (d) => dispatch(daysActions.getAllDays(d)),
});

export const AanmeldLijstPagina = connect(
  mapStateToProps,
  mapDispatchToProps
)(AanmeldLijstComponent);
