import React from "react";
import { Popconfirm } from "antd";
import { $t } from "~/i18n.js";
import { VerwijderButton } from "./VerwijderButton.jsx";

export const VerwijderButtonConfirm = ({ onConfirm, ...args }) => {
  return (
    <Popconfirm
      key="remove"
      placement="bottom"
      title={$t("Zeker weten?")}
      onConfirm={onConfirm}
      okText={$t("Ja")}
      cancelText={$t("Nee")}
    >
      <VerwijderButton {...args} />
    </Popconfirm>
  );
};
