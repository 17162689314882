import { message } from 'antd'
import { version } from '../../package.json'
import { $t } from '~/i18n.js'
import { resetAllStates } from '../redux/reducer.js'
import { getLocalStorage, setLocalStorage } from './storage.js'

// note: second string with $t is to automagically be picked up by translate package
const backend_error_codes = {
  invalid_flights: 'Er zijn 1 of meerdere vluchten die een vlieger missen of nog geland moeten worden voordat de dag kan worden afgesloten', // $t("Er zijn 1 of meerdere vluchten die een vlieger missen of nog geland moeten worden voordat de dag kan worden afgesloten")
  dag_bestaat: 'Er bestaat al een dag met deze datum, je kan niet twee keer dezelfde aanmaken', // $t("Er bestaat al een dag met deze datum, je kan niet twee keer dezelfde aanmaken")
  rooster_bestaat: 'Er bestaat al een rooster met deze beschrijving, je kan niet twee keer dezelfde aanmaken', // $t("Er bestaat al een rooster met deze beschrijving, je kan niet twee keer dezelfde aanmaken")
  bestaat_al: 'Er bestaat al een met deze naam je kan niet twee keer dezelfde aanmaken', // $t("Er bestaat al een met deze naam je kan niet twee keer dezelfde aanmaken")
  not_authorized: 'Authentication credentials were not provided.', // $t("Authentication credentials were not provided.")
  not_allowed_now: 'Je bent niet bevoegd om dit te doen.', // $t("Je bent niet bevoegd om dit te doen.")
  not_welcome: 'Je bent niet bevoegd om dit te doen.', // $t("Je bent niet bevoegd om dit te doen.")
  not_found: 'Object niet gevonden.', // $t("Object niet gevonden.")
  wrong_email: 'Onbekend emailadres.', // $t("Onbekend emailadres.")
  email_exists: 'Emailadres bestaat al.', // $t("Emailadres bestaat al.")
  iban: 'Bankrekeningnummer is niet geldig, probeer opnieuw.', // $t("Bankrekeningnummer is niet geldig, probeer opnieuw.")
  avatar: 'Profiel foto is niet geldig, probeer opnieuw.', // $t("Profiel foto is niet geldig, probeer opnieuw.")
  day_past: 'Je kan je niet aanmelden voor dagen in het verleden', // $t("Je kan je niet aanmelden voor dagen in het verleden")
  throttled_privacy_mode: 'Je mag je privacy modus slechts 1 keer per maand aanzetten.', // $t("Je mag je privacy modus slechts 1 keer per maand aanzetten.")

  request_throttled: 'Probeer opnieuw in 10 minutes.', // $t("Probeer opnieuw in 10 minutes.")
  missing_data: 'Je bent foutieve data aan het opsturen data.', // $t("Je bent foutieve data aan het opsturen data.")
  url_invalid: 'De URL is foutief.', // $t("De URL is foutief.")
  wrong_user: 'De email en wachtwoord combinatie bestaat niet. Probeer opnieuw.', // $t("De email en wachtwoord combinatie bestaat niet. Probeer opnieuw.")
  scope_invalid: 'Invalid scope for action', // $t("Invalid scope for action")

  user_name_password_missing: 'Please re-enter your email and password.', // $t("Please re-enter your email and password.")
  expired_link: 'Deze link is niet meer actief. Probeer het opnieuw.', // $t("Deze link is niet meer actief. Probeer het opnieuw.")
  contact_us: 'Er is iets mis gegaan. Probeer opnieuw of neem contact op met de ICT commissie.', // $t("Er is iets mis gegaan. Probeer opnieuw of neem contact op met de ICT commissie.")

  no_seats: 'Helaas kunnen we in het testaccount geen nieuwe gebruikers activeren, je zit al aan het maximum. Neem contact op om je prijsplan te wijzigen.', // $t('Helaas kunnen we in het testaccount geen nieuwe gebruikers activeren, je zit al aan het maximum. Neem contact op om je prijsplan te wijzigen.')
}

class Api {
  backend_url = ''
  access_token = null
  store = null

  constructor() {
    // setup after persisting in store

  }

  async get(endpoint = '', custom_endpoint = null) {
    return fetch(custom_endpoint || `${this.backend_url}/internal_api/${endpoint}`, {
      method: 'GET',
      headers: this.buildHeaders()
    })
      .then((response) => this.handleResponse(endpoint, response))
      .catch((error) => this.handleError(error))
  }

  async open_file(endpoint, filename, custom_endpoint = null) {
    return fetch(custom_endpoint || `${this.backend_url}/internal_api/${endpoint}`, {
      method: 'GET',
      headers: this.buildHeaders(false)
    })
      .then(response => response && response.status === 200 && response.blob())
      .then(blob => {
        if (blob) {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = filename
          document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click()
          a.remove() // afterwards we remove the element again
          message.success($t('Bestand \"${filename}\" gedownload', { filename }))
        }
      })
      .catch((error) => this.handleError(error))
  }

  openFileGET(endpoint) {
    let queryToken = endpoint.includes('?') ? "&" : '?'
    let url = `${this.backend_url}/internal_api/${endpoint}${queryToken}token=${this.getToken()}`
    window.open(url, '_blank');
  }

  /**
   * 
   * @param {*} endpoint 
   * @param {*} payload 
   * @param {*} messageOnNetworkIssues 
   * @returns data 
   */
  async post(endpoint = '', payload = {}, messageOnNetworkIssues = true) {
    return fetch(`${this.backend_url}/internal_api/${endpoint}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: this.buildHeaders()
    })
      .then((response) => this.handleResponse(endpoint, response))
      .catch((error) => this.handleError(error, messageOnNetworkIssues))
  }

  /**
   * Internal function for convenience function post, path and delete
   * @param {*} method 
   * @param {*} endpoint 
   * @param {*} payload 
   * @param {*} messageOnNetworkIssues 
   * @returns 
   */
  async _call_method(method = 'POST', endpoint = '', payload = {}, messageOnNetworkIssues = true) {
    const response = await fetch(`${this.backend_url}/internal_api/${endpoint}`, {
      method: method,
      body: JSON.stringify(payload),
      headers: this.buildHeaders()
    }).catch((error) => {
      this.handleError(error, messageOnNetworkIssues)
      return {data: null, response: {ok: false}}
    })

    const data = await this.handleResponse(endpoint, response)

    return { data, response }
  }

  /**
   * 
   * @param {*} endpoint 
   * @param {*} payload 
   * @param {*} messageOnNetworkIssues 
   */
  async post2(endpoint = '', payload = {}, messageOnNetworkIssues = true) {
    return this._call_method('POST', endpoint, payload, messageOnNetworkIssues)
  }

  /**
     * 
     * @param {*} endpoint 
     * @param {*} payload 
     * @param {*} messageOnNetworkIssues 
     */
  async patch(endpoint = '', payload = {}, messageOnNetworkIssues = true) {
    return this._call_method('PATCH', endpoint, payload, messageOnNetworkIssues)
  }

  async put(endpoint = '', payload = {}, messageOnNetworkIssues = true) {
    return this._call_method('PUT', endpoint, payload, messageOnNetworkIssues)
  }

  /**
     * 
     * @param {*} endpoint 
     * @param {*} payload 
     * @param {*} messageOnNetworkIssues 
     */
  async delete(endpoint = '', payload = {}, messageOnNetworkIssues = true) {
    console.log('delete: ', payload)
    return this._call_method('DELETE', endpoint, payload, messageOnNetworkIssues)
  }

  async post_form(endpoint = '', payload = {}, custom_endpoint = null) {
    // api function to upload files
    const formData = new FormData()
    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key])
    })
    return fetch(custom_endpoint || `${this.backend_url}/internal_api/${endpoint}`, {
      method: 'POST',
      body: formData,
      headers: this.buildHeaders(false)
    })
      .then((response) => this.handleResponse(endpoint, response))
      .catch((error) => this.handleError(error))
  }

  /**
   * Set token, upon refresh and after login
   */
  setToken(token) {
    this.access_token = token
    setLocalStorage('access_token', token)
  }

  /**
   * Get the token either from class or from local storage
   */
  getToken() {
    return this.access_token || getLocalStorage('access_token')
  }

  buildHeaders(isJSON = true) {
    const headers = {
      Version: version
    }

    if (isJSON) headers['Content-Type'] = 'application/json'

    const token = this.getToken()
    if (token) headers.Authorization = `Bearer ${token}`

    return headers
  }

  handleResponse(endpoint, response) {
    
    // no response in case of network error
    if (!response) return null

    if (response.status >= 200 && response.status <= 299) {
      return response.json()
    } else if (response.status === 400 || response.status === 415 || response.status === 405) {
      response.json().then(
        (data) => {
          console.log(`HTTP ${response.status} - ${data}`)

          if (data.error && backend_error_codes[data.error]) {
            this.handleError({ title: $t('Niet toegestaan'), detail: `${$t(backend_error_codes[data.error])} ${data.message || ""}` })
          } else {
            const message = JSON.stringify(data)
            this.handleError({ title: $t('Niet toegestaan'), detail: message })
          }
        })
    } else if (response.status === 404) {
      console.log('HTTP 404 not found')
      this.handleError({ title: $t('Niet gevonden'), detail: 'Er is iets mis gegaan. Probeer opnieuw of neem contact op met de ICT commissie.' })
    } else if (response.status === 406) {
      // 406 error: This indicates frontend that is should not add the failed flight call to the queue because something on the serverside is going wrong
      // normally with errors we return null from the api, this time we thus should not
      console.error('HTTP 406 - server error in saving flights')
      this.handleError({ title: $t('(Tijdelijke) server error bij opslaan van vluchten') })
      return response.json()
    } else if (response.status === 401 || response.status === 403 || response.status === 418) {
      this.store.dispatch(resetAllStates)
      console.error(`HTTP ${response.status} - UNAUTHENTICATED`)
      this.handleError({ title: $t('Authentication error'), detail: $t('Sessie is verlopen. Log in.') })
    } else if (response.status === 500) {
      console.error('HTTP 500')
      this.handleError({ title: $t('(Tijdelijke) server error - Excuses voor het ongemak') })
    } else if (response.status === 503) {
      console.error('HTTP 503 - Maintenance')
      window.location.assign('/onderhoud')
    }
  }

  handleError(error, messageOnNetworkIssues = true) {
    if (error instanceof TypeError) {
      console.error('>> HTTP error', error)
      if (error.message.includes('Failed to fetch')) {
        messageOnNetworkIssues && message.error($t('Geen internetverbinding met server: Maak verbinding en probeer opnieuw.'), 3)
      } else {
        message.error($t('Onverwachte foutmelding: Er is iets mis gegaan. Probeer opnieuw of neem contact op met de ICT commissie.'))
      }
    } else {
      console.error('>> HTTP error', error)
      if (error.messages && error.messages.length > 0) {
        error.messages.forEach((m) => message.error(`${error.detail} ${m}`))
      } else {
        let text = (error?.title || error?.message) ? (error.title || error.message) : $t('Overige foutmelding')
        if (error.detail) text += '. ' + error.detail
        message.error(text)
      }
    }
  }
}

export const api = new Api()
